.admin-portfolio {
    overflow-x: hidden;
}

.portfolio-items {
    display: flex;
}

.portfolio-imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
}

.portfolio-imgs img {
    width: 300px;
    height: 200px;
    margin: 10px;
    box-shadow: 0 4px 6px rgba(14, 14, 14, 0.1);
    border-radius: 10px;
}

.Portfolio-1 p {
    text-align: center;
    padding-top: 10px;
    font-weight: bold;
}


@media only screen and (max-width: 321px) {
.portfolio-imgs img {
    width: 90%;
    height: 180px;
    margin: 10px;
    box-shadow: 0 4px 6px rgba(14, 14, 14, 0.1);
    border-radius: 10px;
}
}