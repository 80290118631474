.screen1-mobile {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 10%;
    background-color:#eeeeee;
}
 
 
  .screen1-mobile h1 {
    font-size: 50px;
    margin: 0 auto;
    padding-top: 5%;
  }
  .screen1-mobile p {
    padding-top: 50px;
    font-size: 24px;
    margin: 0 auto;
    width: 85%;
  }
 
  .screen1-mobile-right {
    display: flex;
    justify-content: center;
  }
 
  .screen1-mobile-right .inf-video-2 {
    margin-top: 50px;
    width: 70%;
  }
 
 
  .screen1-mobile-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
 
 
.landingpage{
    display: flex;
    flex-direction: column;
}
 
 
.top-bar{
    position: fixed;
    width: 100%;
    z-index: 4;
}
 
.CTlogo {
    width: 150px;
    height: 100px;
    padding-top: 0px;
    margin-top: -30px;
    margin-bottom: -20%;
 
}
 
.nav-heads{
    font-weight: bold;
    color: white;
}
 
.navbar .nav-link {
    color: white;
}
 
.navbar-nav .nav-link.active {
    color: white;
  }
 
  .navbar-dropdown-item {
    width: 100%;
    transition: width 0.5s ease;
}
 
.navbar-dropdown-item:hover {
    background-color: rgb(174, 173, 173);
    width: auto;
}
 
.ai-bar{
    border: none;
    /* background-color: #8960f6; */
    background-color: black;
    gap: 10px;
    border-radius: 20px;
}
/* .custom-dropdown {
    background-color: black;
    border-radius: 30px;
    padding: 5px;
   
 } */
 
/* screen-1 */
 
.screen1 {
    width: 100%;
   /* background-color: #f5eea3; */
   background-color:#eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5% 4%;
}
 
.screen1 .inf-video {
    width: 600px;
    height: 350px;
 
}
 
 
.screen1 .hustle{
    font-size: 80px;
    margin: 10px;
    width: 60%;
}
 
 
 
.screen1-left p{
    font-size: 22px;
    padding-top: 15px;
    width: 90%;
}
 
.screen1-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 25px;
}
 
.film:hover{
    background-color: black;
    color: white;
}
 
 
/* screen-2 */
 
.screen-2 {
 
    padding: 40px 15px;
   
    background-color: #7a7b7b;
}
.people {
    color: white;
}
.screen-2 h1 {
    font-size: 50px;
}
 
.screen2-cards-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    background-color: #7a7b7b;  
}
 
 
.tabs2 .nav-pills
.nav-link
{  
background-color
: transparent;  
border-radius: 18px;
border: 0px;
margin-right: 0px;
margin-bottom: 10px;
background:none !important;
}
 
 
.screen-2 .button {
    width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    border: 0;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    border-radius: 10rem;
    transition: all 0.02s;
    font-weight: bold;
    color: rgb(0, 0, 0);
    z-index: 0;
    box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
    margin: 0 0px;
  }
 
  .screen-2 .button:hover {
    background: rgb(78, 79, 79);
    color: rgb(154, 152, 157);
  }
 
  .screen-2 .button:active {
    transform: scale(0.97);
  }
 
  .screen-2 .hoverEffect {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
 
  .screen-2 .hoverEffect div {
    background: rgb(23, 23, 23);
    background: linear-gradient(90deg, rgb(185, 183, 184) 0%, rgb(244, 244, 245) 49%, rgba(0,212,255,1) 100%);
    border-radius: 40rem;
    width: 10rem;
    height: 10rem;
    transition: 0.4s;
    filter: blur(20px);
    animation: effect infinite 3s linear;
    opacity: 0.5;
  }
 
  .screen-2 .button:hover .hoverEffect div {
    width: 8rem;
    height: 8rem;
  }
 
  @keyframes effect {
 
    0% {
      transform: rotate(0deg);
    }
 
    100% {
      transform: rotate(360deg);
    }
  }
 
 
 
  .screen2-new-card {
    text-align: center;
   
 background-color: white;
    margin: 10px;
    color: #080808;
   
    border-radius: 18px;
 
    border: 3px solid #ffffff;
   
}
 
 
 
.screen2-new-card img{
    width: 250px;
    height: 250px;
   border-radius: 18px 18px 0px 0px;
}
.screen2-new-card div{
    height: 100px;
    max-width: 230px;
}
.screen2-new-card h4 {
    font-size: 22px;
    color: #000000;
    padding-top: 10px;
    text-shadow: 2px 0px 1px rgba(7, 7, 7, 0.5);
}
 
 
.screen2-new-card p{
    font-size: 16px;
    color: rgba(5, 0, 0, 5.899);
   
    font-weight: 400;
    padding-bottom: 10px;
   
}
 
/* screen-3 */
.screen3{
   
    background-color:#eeeeee;
    padding: 35px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
   
}
 
.screen3 h1{
    font-size: 50px;
    color: rgba(12, 12, 12, 0.77);
}
 
.screen3 p{
    font-size: 25px;
    color: rgba(27, 27, 27, 0.77);
    font-weight: 500;
}
/*
.card-3-1 {
    width: 300px;
    border: 1px solid #161515;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, #f5f5f5, #f5f5f5), url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KPHN2ZyBoZWlnaHQ9IjEwMCUiIHZlcnNpb249IjEuMSIgd2lkdGg9IjEwMCUiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogIDxjaXJjbGUgY3g9IjM1JSIgY3k9IjUwJSIgcj0iMjUlIiBzdHlsZT0iZmlsbDpyZWQ7IiAvPgogIDxjaXJjbGUgY3g9IjM1JSIgY3k9IjkwJSIgcj0iMjUlIiBzdHlsZT0iZmlsbDpibHVlOyIgLz4KPC9zdmc+Cg==');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 30px;
  }
 
  .card-3-1 h3 {
    margin: 0 0 8px;
    color: #333;
  }
 
  .card-3-1 p {
    margin: 0;
    color: #666;
  }
  .wave {
    fill: #000;
  } */
.screen3 input{
    width: 700px;
    padding: 10px;
    padding-left: 30px;
    border: none;
    border-radius: 30px 0px 0px 30px;
}
 
.search-btn{
    background-color: #116df9;
    color: white;
    padding: 10px 10px;
    border-radius: 0px 30px 30px 0px;
}
 
.search-btn span{
    font-weight: 600;
}
.screen3-boxes{
    display: flex;
   
}
 
.screen3-box{
    color: rgb(18, 18, 18);
    padding: 16px;
    border: 3px solid rgb(83, 82, 82);
    border-radius: 20px;
 
 
    margin: 20px;
    width: 400px;
}
.screen3-box p {
    font-size: 17px;
    /* width: 400; */
    color: rgba(6, 6, 6, 0.77);
    float: left;  
}
 
.screen3-box h6{
    font-size: 22px;  
}
 
/* .hi img{
    border-radius: 50%;
    margin-right: 20px;
}
.hi{
    width:500px;
    background-color: #303337;
    border-radius: 59px;
    display: flex;
    align-items: center;
    display: none;
   
}
 
.hi div p{
    font-size: medium;
}
.hi button{
    color: black;
    background-color: white;
    border: none;
    padding: 5px;
    margin-left: 40px;
} */
 
 
/* screen4 */
 
.screen4 {
    padding: 40px 20px;
    background-color: #4a5762;
   
    min-height: 100vh; /* This ensures the section takes up at least the full height of the viewport */
}
 
.screen4top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
}
 
.screen4-left {
    max-width: 50vw;
    text-align: center; /* Center text content */
}
 
.screen4-left h1 {
    font-size: 50px;
    color: white;
}
.screen4-left h1 span {
    color: #99bff9;
 }
.screen4-left p {
    padding-top: 7px;
    font-size: 25px;
    color: #f5f0f0;
}
 
.screen4-left a {
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-style: italic;
}
 
.screen4-right img {
    width: 500px;
    height: 500px;
}
 
 
 
/* #resumeFooter {
    height: 170px;
} */
 
#resumePic {
width: 450px;
height: 600px;
 border-radius: 20px;
}
 
.screen4-right {
    margin-left: 50px;
    margin-right: 0px;
}
.screen4-right-girlResume {
    display: flex;
    width: 700px;
    background-color: rgb(121, 118, 118);
}
 
#girlResume {
    width: 400px;
}
.resumeMater {
    margin: 20px;
    list-style-type: none; /* Remove bullets */
}
.resumeMater span {
    font-size: 30px;
    color: #7f4b4b;
    font-weight: 700;
    text-align: center; /* Center text horizontally */
    display: block; /* Make the span a block-level element */
    margin: 0 auto; /* Center the block-level element horizontally */
}
.resumeMater p {
    font-size: 18px;
    padding-left: 40px;
    margin-top: 8px;
}
.resumeMater h6 {
    font-size: 14px;
    /* padding-left: 40px; */
    margin-top: 8px;
    width: 250px;
    text-indent: 40px;
}
 
.resumeMater li {
    margin-left: 0px;
    width: 300px;
    font-weight: 400;
    text-decoration: none;
}
 
.screen4bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    /* gap: 190px; */
}
.bottom4-left img{
    width: 400px;
    height: 300px;
    border-radius: 20px;
    border: 7px solid rgb(102, 123, 138);
}
.screen4bottom .accord-content{
    color: black;
    font-size: 20px;
}
 
.screen4bottom .accord{
    /* background-color: #d48456; */
    width: 600px;
    min-width: auto;
    max-width: 600px;
    box-shadow: none;
}
 
.accord-img{
   display: none;
   
}
 
.bottom4-right .accordion-header.active h4 {
    color: orange; /* Change the text color if needed */
  }
 
 
/* screen6 */
.screen6{
    padding: 40px 20px;
    background-color:#ffffff;
}
 
.screen6 h1{
    font-size: 50px;
    color: #000;
}
 
.screen6-boxes{
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 40px;
    overflow-x: scroll;
    justify-content: flex-start;
    &::-webkit-scrollbar {
        display: none;
      }
}
 
.screen6-box{
    width: 360px;
    min-width: 350px;
    border-radius: 35px;
    background-color: #dbdada;  
    border: none;
    padding: 20px;
    border: 3px solid #323232;
}
 
.screen6-box p{
    font-size: 17px;
    font-weight: 400;
    color: rgb(2, 0, 0);
    min-height: 110px;
    max-height: 110px;
    overflow: hidden;
}
 
.screen6-img{
    border-radius: 50%;
}
 
 
/* screen7 */
.screen7{
    background-color: #f2f0ec;
}
 
/* imp */
.screen7bottom {
    display: flex;    
}
 
.ravi {
    height: 435px;
    display: flex;
   
}
.notification-1  {
    display: flex;
    flex-direction: column; /* Ensure flex items stack vertically */
    justify-content: center;
    align-items: center;
    background-color:#d3d3d3;
    height: 430px;
    width: 60%;
    color: rgb(7, 7, 7);
   
}
.ravi .notification-1 h1 {
    font-size: 34px;
    text-align: center;
}
 
.ravi .notification-1 p {
    font-size: 18px;
    text-align: center;
    margin-top: 3%;
    width: 90%;
    font-weight: 400;
}
 
.ravi p {
   
    font-size: 30px;
    font-weight: 700;
    text-align: center;
   
}
 
.ravi h1 {
    text-align: center;
}
 
.coin-profit {
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    width: 600px;
    height: 430px;
   
    margin-right: 10px;
}
 
.coin {
    font-size: 200px;
    width: 0.1em;
    height: 1em;
    background: linear-gradient(#faa504, #141001);
    margin: auto;
    position: relative;
 
    left: 0;
    right: 0;
    bottom: 0;
    animation: rotate_4001510 10s infinite linear;
    transform-style: preserve-3d;
}
 
.coin .side, .coin:before, .coin:after {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    overflow: hidden;
    border-radius: 50%;
    right: -0.4em;
    text-align: center;
    line-height: 1;
    transform: rotateY(-90deg);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
 
.coin .tails, .coin:after {
    left: -0.4em;
    transform: rotateY(90deg);
}
 
.coin:before, .coin:after {
    background: linear-gradient(#faa504, #141001);
    backface-visibility: hidden;
    transform: rotateY(90deg);
}
 
.coin:after {
    transform: rotateY(-90deg);
}
 
@keyframes rotate_4001510 {
    100% {
      transform: rotateY(360deg);
    }
}
 
.svg_back {
    transform: scaleX(-1);
}
 
#dollar-1 {
    margin-top: -30px;
    margin-left: -30px;
    width: 260px;
    height: 260px;
    /* background-color: #faa504; */
}
 
/* #dollar-2 {
    margin-top: -60px;
    margin-left: -18%;
    width: 270px;
    height: 250px;
    background-color: #faa504;
} */
 
 
/* screen9 */
.screen9{
    display: flex;
    flex-direction: row;
    padding: 45px 20px;
    justify-content: space-around;
    align-items: center;
 
background-color:#eeeeee;
}
 
.screen9-left{
    padding: 20px;
}
 
.screen9-left h1{
    font-size: 45px;    
}
 
.screen9-left p{
    font-size: 20px;
    color: #303337;    
}
 
.screen9-left span{
    font-size: 20px;
    font-weight: 600;  
   
    color: rgb(64, 84, 131);
}
 
.accord2 {
    min-width: 600px;
    width: 600px;
    max-width: 600px;
    z-index: -1;
 
}
 
.accord2 .accordion-header{
    z-index: -2;
}
@media  (max-width: 1280px) {
    .screen1 .hustle{
        font-size: 70px;
        margin: 10px;
        width: 80%;
    }
 }
 @media (max-width: 900px) {
   
    .screen1 {
        width: 100%;
        background-color: #eeeeee;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5% 0%;
    }
   
    .screen1-mobile {
        display: block;
    }
    .screen1 .hustle{
        font-size: 80px;
        margin: 10px;
        width: 100%;
    }
    .screen1-right {
        display: flex;
        justify-content: center;
      }
     
      .screen1-right .inf-video {
        margin-top: 50px;
        width: 70%;
        height: auto;
        padding-top: 0px;
      }
 
    .screen1 .hustle {
        font-size: 30px;
        margin: 0 auto;
        padding-top: 5%;
        max-width: 80%;
        text-align: center;
    }
 
    .screen1-left {
        display: flex;
        flex-direction: column;
    }
 
    .screen1-left p {
        display: none;  
    }
 
    .screen1-buttons {
        display: none;
    }
 
    .screen1-mobile p {
        padding-top: 10px;
        font-size: 24px;
        margin: 0 auto;
        width: 88%;
      }
.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
     
.screen-2 .button {
    width: 130px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    border: 0;
    align-items: center;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    border-radius: 10rem;
    transition: all 0.02s;
    font-weight: bold;
    color: rgb(5, 0, 0);
    z-index: 0;
    box-shadow: 0 0px 7px -5px rgba(0, 0, 0, 0.5);
    margin: 5px;
}
 
    .screen3 {
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .screen3 p {
        text-align: center;
        width: 80%;
    }
 
   
}
 
 
 
 
@media (min-width: 768px) and (max-width: 1028px) {
    .oscroll{
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
          }
    }
    .screen1 .hustle{
        font-size: 80px;
        margin: 0 auto;
        width: 100%;
    }
    .screen2-cards-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
       
    }
 
    .screen3-boxes{
        display: flex;
        flex-direction: column;
    }
 
    .screen3 input{
        width: 500px;
    }
 
    .bottom4-left{
        display: none;
        width: 0vw;
        max-width: 0vw;
    }
 
    .bottom4-right{
        max-width: 100vw;
    }
    .screen4 {
        height: auto;
    }
    .screen4top{
        display: flex;
        flex-direction: column-reverse;
       
    }
    .screen4-left{
        max-width: 100vw;
        text-align: center;
    }
    .screen4-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 50px;
    }
 
    .accord-img{
        width: 400px;
        height: 400px;
        display: block;
    }
 
    .screen6-boxes{
        gap: 20px;
       
    }
   
    .screen6-box{
        width: 320px;
   
    }
    .topmate-big{
        display: none;
    }
    .topmate-medium{
        display: block;
    }
 
    .screen7top{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
   
    .screen7-top-left{
        width: 300px;
    }
   
    .screen7-top-left h1{
        font-size: 30px;
    }
   
    .screen7-top-left p{
        font-size: 15px;
        color: #303337;
    }
    .search-box input[type="text"] {
        width: 300px;
    }
     
    .search-box label[for="text"] {
        font-size: 28px;
    }
     
    .search-box {
        width: 340px;
    }
     .screen9-left h1{
        font-size: 35px;    
    }
   
    .screen9-left p{
        font-size: 15px;
        color: #303337;    
    }
   
    .screen9-left span{
        font-size: 20px;
        font-weight: 600;  
        color: rgb(64, 84, 131);
    }
    .accord2{
        min-width:450px;
        width: 450px;
        max-width: 450px;
    }
}
 
 
 
@media screen and (max-width: 767px) {
    .caro-img{
        height: 300px;
        width: 250px;
    }
    .oscroll{
        overflow-y: scroll;
    }
   
    .screen2-cards-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
 
    .screen3-boxes{
        display: flex;
        flex-direction: column;
    }
 
    .screen3-box{
     
        padding: 14px;
     
        border-radius: 20px;
        margin: 15px;
        width: 300px;
    }
 
    .screen3 input{
        width: auto;
    }
    .hi{
        width: 350px;
    }
    .hi img{
        width: 70px;
    }
 
    .screen3 h1{
        font-size: 20px;
    }
   
    .screen3 p{
        font-size: 15px;
    }
 
    .hi button{
        display: none;
        color: black;
        background-color: white;
        border: none;
        padding: 5px;
        margin-left: 20px;
    }
 
    .screen4top{
        display: flex;
        flex-direction: column-reverse;
       
    }
   
    .screen4-left{
        max-width: 100vw;
    }
   
    .screen4-left h1{
        font-size: 27px;
    }
   
    .screen4-left p{
        font-size: 18px;
        color: #1f1f1f;
    }
 
    .screen4-right img{
        width: 320px;
        height: 320px;
    }
 
    .bottom4-left{
        display: none;
    }
    .bottom4-right{
        max-width: 100vw;
    }
    .screen4bottom .accord{
        width: 320px;
        min-width: 320px;
        max-width: 320px;
        z-index: -1;
    }
   
    .accord-img{
        width: 300px;
        height: 300px;
        display: block;
    }
 
    .screen6-boxes{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: scroll;
        gap: 40px;
        justify-content: flex-start;
        padding: 10px;
       
    }
 
    .screen6-box{
        min-width: 300px;
        height: auto;
        border-radius: 35px;
       
        border: none;
        padding: 25px;
    }
   
    .screen6-box p{
        font-size: 18px;
        font-weight: 400;
        color: black;
        min-height: 110px;
        max-height: 110px;
        overflow: hidden;
    }
   
    .screen6-img{
        border-radius: 50%;
    }
 
    .screen6 h1{
        font-size: 30px;
    }
 
    .topmate-big{
        display: none;
    }
    .topmate-medium{
        display: block;
    }
 
    .screen7top{
        display: flex;
        flex-direction: column;
       align-items: center;
    }
   
    .screen7-top-left{
        width: auto;
    }
   
    .screen7-top-left h1{
        font-size: 25px;
    }
   
    .screen7-top-left p{
        font-size: 15px;
        color: #303337;
    }
    .search-box input[type="text"] {
        width: 280px;
    }
     
    .search-box label[for="text"] {
        font-size: 26px;
    }
     
    .search-box {
        width: 320px;
    }
    .screen9{
        display: flex;
        flex-direction: column;
        padding: 45px 20px;
       
    }
   
    .screen9-left h1{
        font-size: px30;    
    }
   
    .screen9-left p{
        font-size: 15px;
        color: #303337;    
    }
   
    .screen9-left span{
        font-size: 20px;
        font-weight: 600;  
        color: rgb(64, 84, 131);
    }
    .accord2{
        min-width: 320px;
        width: 320px;
        max-width: 320px;
    }
 
.screen7bottom {
    display: flex;
    flex-direction: column-reverse;  
 
}
 
.ravi {
    height: auto;
    flex-direction: column-reverse;
    gap: 20px;
   
}
.notification-1  {
   
    height: auto;
    width: 100%;
   
   
}
.ravi .notification-1 h1 {
    font-size: 34px;
    text-align: center;
}
 
.ravi .notification-1 p {
    font-size: 18px;
    text-align: center;
    margin-top: 3%;
    width: 95%;
    font-weight: 400;
    font-family: sans-serif;
}
 
.ravi p {
   
    font-size: 30px;
    font-weight: 700;
    text-align: center;
   
}
 
.ravi h1 {
    text-align: center;
}
 
.coin-profit {
   
    width: 100%;
   
}
 
 
}
@media screen and (max-width:540px){
    .landingpage{
        overflow-x: hidden;
    }
    .screen1-mobile h1 {
        font-size: 40px;
        margin: 0 auto;
        padding-top: 15%;
        width: 100%;
      }
 
      .screen1-mobile p {
        padding-top: 30px;
        font-size: 20px;
       
      }
     
      .screen1-mobile-right .inf-video {
        margin-top: 30px;
       
      }
      .screen-2 h1 {
        font-size: 35px;
    }
     
    .caro-img{
        height: 250px;
        width: 220px;
    }
    .caro-box{
        display: flex;
        flex-direction: row;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-radius: 12px;
        box-shadow: 10px black;
        z-index: -1;
        width: 200px;
    }
    .caro-box p{
        font-size: 14px;
    }
 
    .screen3-boxes{
        display: flex;
        flex-direction: column;
    }
    .screen3 h1{
        font-size: 30px;
        color: #000;
        margin: 0 auto;
        text-align: center;
    }
   
    .screen3 p{
        font-size: 20px;
        color: rgba(9, 9, 9, 0.77);
        width: 90%;
    }
   
    .search-btn span{
        display: none;
    }
 
   
    .search-btn{
       
        padding: 5px 10px;
       
    }
   
/* .screen3-boxes {
    margin: 0 auto;
} */
    .screen3 .screen3-box h6{
        font-size: 24px;
        color: #000;
        width: 100%;
        font-weight: 600;
        text-align: center;
 
    }
 
    .screen3 input{
        width: 90%;
        font-size: 20px;  
    }
   
 
 
    .screen4top{
        display: flex;
        flex-direction: column-reverse;
    }
   
    .screen4-left{
        max-width: 100vw;
    }
   
    .screen4-left h1{
        /* font-size: 20px; */
        padding-top: 25px;
    }
   
    .screen4-left p{
        font-size: 18px;
        color: #f5ecec;
        padding-top: 15px;
    }
 
    /* .screen4-right img{
        width: 240px;
        height: auto;
    } */
    #resumePic {
        width: auto;
        height: 450px;
        margin: 0 auto;
        margin-left: -50px;
    }
    .bottom4-left{
        display: none;
    }
    .bottom4-right{
        max-width: 100vw;
    }
   
    .screen4bottom .accord{
        width: auto;
        min-width: 240px;
        max-width: 200px;
        z-index: 1;
    }
    .accord-img{
        width: 200px;
        height: 220px;
        display: block;
    }
 
 
    .screen7top{
        display: flex;
        flex-direction: column;
       align-items: center;
    }
   
    .screen7-top-left{
        width: auto;
    }
   
    .screen7-top-left h1{
        font-size: 20px;
    }
   
    .screen7-top-left p{
        font-size: 15px;
        color: #303337;
    }
    .search-box input[type="text"] {
        width: 240px;
    }
     
    .search-box label[for="text"] {
        font-size: 22px;
    }
     
    .search-box {
        width: 220px;
    }
    .ravi p {
        font-size: 26px;
        font-weight: 400;
        text-align: center;
    }
    .ravi .notification-1 h1 {
        font-size: 30px;
        text-align: center;
        padding-top: 30px;
    }
    .ravi .notification-1 p {
        font-size: 20px;
        text-align: center;
        padding-top: 10px;
    }
    .screen9{
        display: flex;
        flex-direction: column;
        padding: 30px 15px;
       
    }
   
    .screen9-left h1{
        font-size: 30px;    
    }
   
    .screen9-left p{
        font-size: 20px;
        color: #303337;    
    }
   
    .screen9-left span{
        font-size: 16px;
        font-weight: 600;  
        /* color: rgb(24, 173, 24);  */
    }
   
    .accord2{
        /* min-width: 230px; */
        width: 230px;
        max-width: 230px;
    }
}


.search-cards{
    display: flex;
    flex-direction: row;
    
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
      }
}

.search-card{
    margin: 20px;
    width: auto;
    height: auto;
    background-color: rgb(7, 7, 73);
    border-radius: 30px;
    border: 2px solid rgb(22, 22, 135);
}

.search-card img{
    height: 250px;
    width: 250px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

}

.search-card p{
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: whitesmoke;
    font-family: Georgia, 'Times New Roman', Times, serif;
}