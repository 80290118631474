/* adminbookingcss */
 
html, body {
  margin: 0;
  padding: 0;
 
}
/* .Booking-page {
  color: rgb(30, 29, 29);
  padding-top: 30px;
    display: flex;
   
    flex-direction: column;
    overflow-x: hidden;
}
.Booking-page h4 {
  text-align: center;
  margin: 0 auto;
 } */
 
.Booking-page {
  color: rgb(30, 29, 29);
 
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
 
.Booking-page h4{
  width: 100%;
  background: linear-gradient(135deg, #4a90e2, #c4bfbf);
  background-size: 400% 400%;
  animation: gradientBG 8s ease infinite;
  height: 60px;
  text-align: center;
  margin: 0 auto;
}
 
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
 
.Booking-page h4 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin: 0 auto;
}
 
 
 
.Booking-page .table-wrapper {
  width: 100%;
  overflow-x: hidden;
}
 
.Booking-page .table-container {
  margin-top: 30px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: auto;
  width: auto;  
  position: relative; /* Ensure scrollbar doesn't overlap content */
}
 
.Booking-page .table {
  width: 150%;
  border-collapse: collapse;
}
 
.Booking-page .table th,
.table td {
  padding: 10px 15px;
  border: 1px solid #706f6f;
  text-align: left;
  white-space: nowrap;
 
}
.table {
  width: 100%;
  border-collapse: collapse;
}
 
 
.Booking-page .table th {
  background-color: #393838;
  color: aliceblue;
}
 
.Booking-page .action-icon {
  margin-right: 5px;
}
 
.Booking-page .profile-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
/* .A1{
  margin-bottom: 1rem;
  width: 20vw;
  margin-left: -1200px;
} */
 
/* Scrollbar styles */
.Booking-page .table-container::-webkit-scrollbar {
  height: 12px;
  width: 12px; /* Adjust as needed */
}
 
.Booking-page .table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
 
.Booking-page .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
 
 
th:nth-child(2),
td:nth-child(2){
  position: sticky;
  /* background-color: white; */
  z-index: 1;
}
 
th:nth-child(2),
td:nth-child(2) {
  left: 0;
}
 
.service-filter {
 
  padding: 50px;
}
 
.service-button {
  border: 2px solid #aaa;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}
 
.service-button:hover {
  background-color: #eee;
}
 
 
/* Media query for tablets (tab view) */
/* Media query for tablets (tab view) */
@media only screen and (max-width: 1024px) {
  .Booking-page  .table {
    width: 100%;
  }
  .Booking-page  {
    /* margin-left: -400px; */
    color: white;
    padding-top: 30px;
  }
  .Booking-page .table-wrapper {
    width: 100%;
    overflow-x: auto; /* Add horizontal scrolling */
    overflow-y: auto; /* Add vertical scrolling */
  }
 
}
 
/* Media query for mobile */
@media only screen and (max-width: 580px) {
  .Booking-page .table {
    width: 100%;
  }
  .Booking-page  {
    /* margin-left: -600px; */
    color: white;
    padding-top: 30px;
  }
  .Booking-page .table-wrapper {
    width: 100%;
    overflow-x: auto; /* Add horizontal scrolling */
    overflow-y: auto; /* Add vertical scrolling */
  }
 
}
 
.filter-field {
  margin-bottom: 1rem;
  width: 20vw;
  margin-left: 10p;
  margin-top: 3rem;
}
 
@media only screen and (max-width: 1024px) {
  .filter-field {
    width: 50%;
   
  }
}
 
@media only screen and (max-width: 580px) {
  .filter-field {
    width: 50%;
  }
}
.tabs {
  display: flex;
}
 
.tab {
  padding: 10px 20px;
 
  border-bottom: none; /* Removing border at the bottom to merge with the table */
  cursor: pointer;
  background-color: #f2f2f2;
  margin-top: 20px;
  border-radius: 30px;
 
}
 
.tab:last-child {
  border-bottom: 1px solid rgb(220, 216, 216); /* Adding border at the bottom of the last tab */
}
 
.tab.active {
  background-color: rgb(133, 188, 246);
  border-bottom: none;
}
.truncate {
  width: 200px; /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
 
.Coloring{
 
  margin-top: 20px;
  color: white;
 
}
.mybookings .nav-pills
.nav-link
{  
background-color: white;  
color: black;
border-radius: 18px;
border: 1px solid black;
margin-right: 10px;
margin-bottom: 10px;
}
 
 
.nav-pills
.nav-link.active
{  
background-color
: black;  
color
: white;
}
 