.notification-popup {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 300px;
    max-height: 400px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
  }
   
  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    background: #f1f1f1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: black;
  }
   
  .notification-header h4 {
    margin: 0;
  }
   
  .notification-header button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
  }
   
  .notification-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 350px;
    overflow-y: auto;
  }
   
  .notification-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    color: black;
  }
   
  .notification-item:last-child {
    border-bottom: none;
  }
   
  .notification-delete {
    background: none;
    border: none;
    color: #100f0f;
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
  }
   
  .notification-delete:hover {
    color: #cc0000;
  }
   
   
  .notification-list::-webkit-scrollbar {
    width: 8px;
  }
   
  .notification-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
   
  .notification-list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
   
  /* Responsive Design */
  @media (max-width: 768px) {
    .notification-popup {
      top: 20px;
      right: 20px;
      width: 260px;
    }
  }
   
  @media (max-width: 480px) {
    .notification-popup {
      top: 13%;
      right: 10px;
      width: 100%;
      max-width: 320px;
      margin: 0 10px;
    }
   
    .notification-header {
      padding: 8px;
    }
   
    .notification-item {
      padding: 8px;
    }
   
    .notification-delete {
      font-size: 14px;
    }
  }
   