
	.login-cont{
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	font-family: 'Jost', sans-serif;
	background: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
} 
.main-login{
	width: 350px;
	height: 600px;
	background: red;
	overflow: hidden;
	background: url("https://img.freepik.com/premium-vector/abstract-realistic-technology-particle-background_23-2148414765.jpg?w=740") no-repeat center/ cover;
	border-radius: 10px;
	box-shadow: 5px 20px 50px #000;
} 
#chk{
	display: none;
}
.signup{
	position: relative;
	width:100%;
	height: 100%;
}
.login-cont label{
	color: #fff;
	font-size: 2.3em;
	justify-content: center;
	display: flex;
	margin: 60px;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}
.login-cont input{
    width: 70%;
    height: 35px;
    background: #e0dede;
    justify-content: center;
    display: flex;
    margin: 20px auto;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}
.login-cont button{
    width: 70%;
    height: 40px;
    margin: 10px auto;
    justify-content: center;
    display: block;
    color: #fff;
    background: #573b8a;
    font-size: 1em;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    transition: .2s ease-in;
    cursor: pointer;
}
 
.login-cont button:hover{
	background: #6d44b8;
}
.login{
	height: 550px;
	background: #eee;
	border-radius: 60% / 10%;
	transform: translateY(-180px);
	transition: .8s ease-in-out;
}
.login label{
	color: #573b8a;
	transform: scale(.6);
}

#chk:checked ~ .login{
	transform: translateY(-600px);
}
#chk:checked ~ .login label{
	transform: scale(1);	
}
#chk:checked ~ .signup label{
	transform: scale(.6);
}
.loginstatus{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.loginstatus{
	font-size: 20px;
	font-weight: bold;
	font-family: 'Courier New', Courier, monospace;
	color: rgb(71, 71, 71);
	text-align: center;
}
.dfgh{
	color: red;
	height: 100vh;
	width: 100vw;
}

.forgetpass input {
	width: 250px;
}

.forgetboxouter{
	background-color: white;
	border-radius: 10px;
	height: 40vh;
	width: 40vw;
}