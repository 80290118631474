.home {
    /* padding: 15px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
   /* height: 100vh; */
}
.home-cont{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.home .left-cont{
    text-align: left;
   
}
 
.home .top-left{
    display: flex;
    flex-direction: row;
   
}
 
.home .user-name{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-top: 20px;
    padding-left: 20px;
}
 
 
.home .accord {
    background-color: #7d7d80;
    width: auto;
    box-shadow: 0 0 5px rgb(39, 38, 38);
    max-width: auto;
    padding: 15px;
    border-radius: 20px;
    margin: 80px 20px;
    transition: transform 0.5s ease;
}
.home .accord :hover {
    background-color: rgb(186, 182, 182);
    transform: translateY(0px);
}
.home .accord h3 {
    padding-bottom: 10px;
 }
 .home .accord-header {
    font-weight: bold;
}
 
.home .accord-btn {
    background-color: rgb(40, 38, 38);
    border-radius: 13px;
    color: white;
    padding: 10px;
}
.home .accord-btn:hover {
    background-color: rgb(40, 38, 38);
    color: rgb(255, 255, 255);
}
 
 
.accordiann {
   width: 800px;
}
 
 
.svg-container{
    width: 700px;
    margin: 20px 20px;
}
 
.svg-cont{
    display: flex;
    flex-direction: row;
    background-color: #e2dfd2;
    border-radius: 10px;
    padding: 10px;
    align-items: center;  
}
 
.new{
    color: white;
    background-color: rgb(35, 116, 35);
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
}
 
.eye-cont{
    display: flex;
    flex-direction: row;
    background-color: #9dc4f4;
    border-radius: 10px;
    padding: 10px;
    align-items: center;  
 
}
 
.booking-image{
    width: 500px;
    height: 350px;
}
 
.inspiried{
    background-color: #f8f7f2;
    padding: 25px;
    margin: 20px 0px;
    border-radius: 15px;
    width: auto;
    box-shadow: 0 0 10px rgb(39, 38, 38);
}
 
.top-user{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:20px;
    margin: 10px 0px;
    padding: 10px 10px;
}
.top-user:hover{
    background-color: #ece5d9;
    cursor: pointer;
    border-radius: 12px;
}
 
.user-image{
    border-radius: 50%;
    width: 60px;
}
 
.feedback{
    display: flex;
    flex-direction: row;
    margin: 20px 20px 20px 0px;
}
 
.help-cont{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    max-width: 410px;
    margin-right: 20px;
}
 
.top-cont{
    /* display: flex;
    justify-content: center; */
    align-items: center;
    margin: 0 auto;
   
    /* flex-direction: column; */
    /* margin-left: 250px; */
    /* height: 100vh; */
}
.KK{
    text-align: left;
    margin-top: 50px;
    padding: 20px;
    background-color: rgb(35, 35, 35);
    color: aliceblue;
    font-size: 24px;
    padding-left: 20px;
    width: 35%;
    border-radius: 0px 80px 80px 0px;
}
 
 
 
@media only screen and (max-width: 768px) {
 
    .home-cont{
        display: flex;
        flex-direction: column;
    }
    .accordian{
        width: auto;
    }
 
    .svg-container{
        width: auto;
    }
    .booking-image{
        width: auto;
        height: auto;
    }
    .feedback{
        display: flex;
        flex-direction: column;
    }
    /* .top-cont{
        height: 100vh;
    } */
    .accordiann {
        width: 100%;
     }
     .KK{
        text-align: left;
        /* margin-top: -300px; */
    }
    .accord {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
   
.feedback{
   width: 100%;
}
.help-cont
{
    width: 100%;
}
   
 
}
@media (max-width:550px) {
    .help-cont {
      margin: 5px;
     
    }
   
    .help-cont img {
      width: 100%;
    }
   
   
    .help-cont h5 {
      font-size: 1rem;
    }
   
    .help-cont p {
      font-size: 0.9rem;
    }
    .KK {
        padding-left: 10px;
        width: 80%;
    }
    .home .accord {
        width: 100%;
        margin: 50px 0px;
        padding: 10px;
    }
    .home .accordiann {
        width:340px;
     }
  }
  @media (max-width: 400px) {
    .KK {
        padding-left: 10px;
        width: 90%;
    }
    .home .accord {
        width: auto;
        margin: 70px 0px;
        padding: 10px;
    }
    .home .accordiann {
        width: auto;
     }
     
     
     .svg-container{
         width: 100px;
         margin: 20px 20px;
     }
     
  }
  @media (min-width: 600px) and (max-width: 998px) {
    .home-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .home .accordiann {
        width: 600px;
     }
    .top-cont {
        height: auto;
    }
    .help-cont
    {
        width: 100%;
    }
    .help-cont img {
        width: 100%;
      }
 
    .KK {
        text-align: left;
        margin-top: 0;
        padding: 5px;
        font-size: 15px;
        padding-left: 6px;
    }
 
   
}