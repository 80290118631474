.bold-text {
  font-weight: bold;
}
 
.form-control-box-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
 
.card-header {
display: flex;
align-items: center;
color: black;
padding: 10px;
 
}
 
.header-content {
display: flex;
align-items: center;
}
 
.icon {
margin-right: 8px;
}
 
.card-body9 {
border-radius: 20px;
color: black;
text-align: center;
}
.card-body9 h4 {
 
  margin-bottom: 20px;
}
 
.card-title {
text-transform: uppercase;
margin-bottom: 16px;
}
 
.card-footer {
text-align: center;
border: none;
background-color: transparent;
}
 
.edit-btn,
.delete-btn {
 
margin: 10px;
width: 90px;
padding: 8px 16px;
text-wrap: nowrap;
}
 
.edit-btn {
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
margin-top: 15px;
}
 
.delete-btn {
background-color: #dc3545;
color: white;
border: none;
border-radius: 4px;
margin-top: 15px;
}
 
.service-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
   justify-content: space-evenly;
}
 
.service-card {
  /* flex: 0 0 calc(33.33% - (2 * 20px / 3));  */
  box-sizing: border-box;
  padding: 10px;
 
 
  margin-top: 30px;
  /* margin: 0; */
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 width: 400px;
}
 
.card-mater {
  display: flex;
  text-align: center;
  padding-left: 60px;
  line-height: 1.3;
}
.price-label p {
  width: 170px;
  text-align: left;
  font-weight: 600;
}
 
 
@media (min-width: 769px) and (max-width: 1024px) {
 
}
 
.btnDiv {
display: flex;  
justify-content: flex-end;  
}
 
 
@media (max-width: 767px) {
.btnDiv {
  flex-direction: column;
  align-items: center;
  width: 100%;  
  margin-left: 10px;
}
.service-card {
  /* flex: 0 0 calc(33.33% - (2 * 20px / 3));  */
  box-sizing: border-box;
  padding: 10px;
 
 
  margin-top: 30px;
  /* margin: 0; */
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
 width: 350px;
}
.card-mater {
  display: flex;
  text-align: center;
  padding-left: 0px;
  line-height: 1.3;
}
.price-label p{
  width: auto;
  text-align: left;
  font-weight: 600;
}
 
}
