* {
    margin: 0;
   
}
 
 
.bac {
    /* position:fixed; */
    height: 250vh;
    width: 100%;
    background-color: #fffA99;
}
 
.whole {
    border: 1px solid black;
    background-color: #fffA99;
}
 
.bo {
    /* border: 1px solid black; */
    width: 75vh;
 
    background-color: white;
    ;
    margin-left: 70vh;
    margin-top: 10vh;
    border-radius: 20px;
    ;
    height: 189vh;
    padding: 20px;
}
 
.log {
    width: 33.3vw;
    margin-top: 1px;
    height: 80px;
    border-radius: 20px;
    ;
    border: 1px solid rgb(227, 221, 221);
}
 
.nto {
    font-size: 25px;
    font-weight: 700;
    margin-left: 10px;
    margin-top: 10px;
}
 
.vi {
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
    color: grey;
    margin-top: 10px;
}
 
.nm {
    border: 1px solid black;
    margin-left: 20vh;
    width: 80px;
    height: 40px;
    margin-top: 20px;
    border-radius: 20px;
    font-size: 20px;
 
}
 
.n {
    border: 1px solid #000000;
    margin-left: 55vh;
    width: 70px;
    height: 40px;
    border-radius: 20px;
    font-size: 20px;
 
}
 
.nn-hide {
    border: 1.5px solid grey;
    /* margin-left: 55vh; */
    width: 80px;
    background-color: rgb(199, 188, 188);
    height: 45px;
    /* margin-top: -280px; */
    border-radius: 20px;
    font-size: 18px;  
    padding-top: 4px;
    cursor: pointer;
    text-align: center;
    float: right;
    margin-top: -10px;
    margin-right: 20px;
}
 
.trik {
    font-size: 15px;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 700;
 
}
 
.rik {
    position: relative;
    left: 10px;
}
 
.tor {
    display: flex;
 
}
 
.st {
    border: 1px solid rgb(222, 233, 203);
    margin-top: 30px;
    height: 32px;
    border-radius: 20px;
    background-color: rgb(240, 254, 216);
    padding-left: 54px;
    width: 66vh;
    color: rgb(9, 107, 9);
    font-weight: 500;
    padding-top: 5px;
    margin-left: 20px;
    font-size: 15px;
    display: flex;
 
}
 
 
 
.pen {
    margin-left: 20px;
    margin-top: 5px;
    /* margin-left: 300px;
    margin-top: -18px; */
    /* position: absolute; */
}
 
 
 
.nae {
    margin-top: 10px;
    padding-top: 20px;
    margin-left: 20px;
    font-size: 20px;
 
}
 
.na {
    display: block;
    margin-top: 20px;
    padding-top: 20px;
    margin-left: 20px;
    font-size: 18px;
 
}
 
.icon-color {
    width: 10px;
    color: red;
}
 
.your {
    color: grey;
    padding-left: 24px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 9px;
    margin-left: 20px;
    /* margin-top: 2ch; */
    width: 66vh;
    border-radius: 20px;
    height: 40px;
    border: 1px solid black;
}
 
.whol {
    display: flex;
    color: grey;
    padding-left: 24px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 7px;
    margin-left: 60vh;
    margin-top: -6ch;
    width: 10vh;
    border-radius: 20px;
    height: 35px;
    border: 1px solid black;
}
 
.yo {
    color: grey;
    padding-left: 24px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 7px;
    margin-left: 20px;
    margin-top: 2ch;
    width: 40vh;
    border-radius: 20px;
    height: 35px;
    border: 1px solid black;
}
 
.iv {
    margin-top: 40px;
    border: 1px solid rgb(219, 22, 22);
    width: 69vh;
    height: 100px;
    padding-top: 20px;
    background-color: rgb(243, 238, 238);
    ;
    border-radius: 2px;
    margin-left: 20px;
 
}
 
.ivv {
    margin-top: 40px;
    border: 1px solid rgb(201, 193, 193);
    width: 69vh;
    height: 150px;
    padding-top: 20px;
    background-color: rgb(243, 238, 238);
    ;
    border-radius: 2px;
    margin-left: 20px;
 
}
 
 
 
.ivvv {
    margin-top: 50px;
    border: 1px solid rgb(201, 193, 193);
    width: 69vh;
    height: auto;
    padding-top: 20px;
    background-color: rgb(243, 238, 238);
    ;
    border-radius: 2px;
    margin-left: 20px;
 
}
 
 
.ivi {
    margin-top: 25px;
 
}
 
.noo {
 
    font-weight: 650;
    margin-left: 20px;
    font-size: 22px;
}
 
.nv {
    display: flex;
    font-weight: 650;
    margin-left: 20px;
    font-size: 18px;
}
 
.nooi {
    margin-top: 20px;
    font-weight: 650;
    margin-left: 20px;
    font-size: 22px;
}
 
.nooii {
    margin-top: 40px;
    font-weight: 650;
    margin-left: 20px;
    font-size: px;
}
.nooii-1 {
    margin-top: 10px;
    font-weight: 650;
    margin-left: 20px;
    font-size: px;
 
}
 
.nooo {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 500;
    color: grey;
}
 
.noooi {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    color: grey;
   
    line-height: 0px;
}
 
.noooi-1 {
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    color: grey;
    line-height: 0px;
    /* padding-top: 10px; */
}
.rem {
    border: 1px solid rgb(6, 147, 6);
    ;
    width: 80px;
    height: 34px;
    font-weight: 500;
    padding-top: 7px;
    background-color: rgb(181, 220, 181);
    padding-left: 20px;
    border-radius: 10px;
    margin-left: 22vw;
    margin-top: -50px;
}
 
.ro {
    border: 1px solid rgb(6, 147, 6);
    ;
    width: 80px;
    height: 34px;
    font-weight: 500;
    padding-top: 7px;
    background-color: rgb(21, 22, 21);
    padding-left: 20px;
    border-radius: 10px;
    color: white;
 
    margin-left: 25vw;
    margin-top: -50px;
}
 
.ro:hover {
    background-color: rgb(85, 80, 80);
}
 
.roi {
    border: 1px solid rgb(6, 147, 6);
    width: 70px;
    height: 24px;
    font-weight: 500;
    padding-top: 2px;
    background-color: rgb(21, 22, 21);
    padding-left: 20px;
    border-radius: 10px;
    color: white;
    float: right;
    margin-right: 3vw;
    margin-top: -30px;
}
 
.roi:hover {
    background-color: rgb(85, 80, 80);
}
 
.roi-1 {
    border: 1px solid rgb(6, 147, 6);
    width: 70px;
    height: 24px;
    font-weight: 500;
    padding-top: 7px;
    background-color: rgb(21, 22, 21);
    padding-left: 20px;
    border-radius: 10px;
    color: white;
    float: right;
    margin-right: 3vw;
    margin-top: -50px;
    padding-top: 2px;
}
 
.roi-1:hover {
    background-color: rgb(85, 80, 80);
}
 
.last {
    border: 1px solid rgb(228, 225, 225);
    margin-top: 5vh;
    margin-left: 10px;
    width: 32vw;
    height: 80px;
    border-radius: 10px;
    display: flex;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.25);
}
 
 
.five {
    border: 1px solid black;
    height: 45px;
    font-size: 23px;
    font-weight: 500;
    width: 130px;
    border-radius: 23px;
    padding-top: 3px;
    /* padding-left: 20px;  */
    text-align: center;
    margin-top: 15px;
    margin-left: 30px;
}
 
.tik {
    font-size: 15px;
    text-decoration: line-through;
    padding: 3px;
}
 
.pay {
    font-size: 18px;
    font-weight: 500;
    margin-left: 13vh;
    margin-top: 15px;
    border: 1px solid black;
    width: 180px;
    border-radius: 5px;
    color: white;
    background-color: black;
    height: 45px;
    /* padding-left: 10px; */
    text-align: center;
    padding-top: 10px;
    cursor: pointer;
}
 
.pay:hover {
    background-color: rgb(121, 116, 116);
}
 
@media only screen and (max-width: 768px) {
    .bo {
        margin-left: 20px;
        width: 90vw;
        height:173vh;
        margin-top: 90px;
    }
 
    .log {
        width: 90vw;
    }
    .bac{
        height:350vw;
    }
 
    .nto {
        font-size: 25px;
    }
 
    .nm {
        margin-left: 10px;
        margin-top: 45px;
    }
 
    .st {
        width: 70vw;
        margin-left: 10px;
    }
    .feb{
        margin-left: -40px;
    }
    .pen{
        margin-left: 240px;
    }
    /* .icon{
        margin-left: -10px;
    } */
    .icon-color {
        margin-left: -10px;
    }
    .you{
        width: 70vw;
        margin-left: 30px;
    }
.whol{
    width: 20vw;
    margin-top: 20px;
    margin-left: 140px;
}
.iv{
    width: 80vw;
    height: 20vh;
}
.noo{
    font-size: 20px;
}
.nooo{
    font-size: 15px;
}
.rem{
    margin-top: 30px;
    margin-left: 20px;
}
.nn{
    margin-top: -150px;
    margin-left: 200px;
    border-radius: 10px;
}
.ivvv{
    width: 80vw;
}
.nooii{
    margin-top: 120px;
}
.noooi{
    margin-top: 10px;
}
.roi{
margin-left: 200px;
}
.nooii-1{
    margin-top: 40px;
    margin-left: 20px;
}
.noooi-1{
    margin-top: 10px;
    margin-left: 20px;
 
}
.roi-1{
    margin-left: 200px;
    margin-top: -10px;
}
.last{
    width: 85vw;
}
.five{
    width: 70px;
    border-radius: 10px;
    margin-left: 10px;
}
.pay{
    margin-left: 30px;
}
.ivi{
    margin-top:-90px;
}
.ivvv{
    height:300px;
}
 
}