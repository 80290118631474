.mybookings{
    overflow-x: hidden;
    padding: 15px;
    width: 100%;
}
 
.mybookings .tab-content{
    /* background-color: white; */
    width: auto;
}
 
.mybookings .nav-pills
.nav-link
{  
background-color: white;  
color: black;
border-radius: 18px;
border: 1px solid black;
margin-right: 10px;
margin-bottom: 10px;
}
 
 
.nav-pills
.nav-link.active
{  
background-color
: black;  
color
: white;
}
 
.new-tab-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
 
.new-tab-content img{
    width: 340px;
    height: 420px;
}
 
.book-btn{
    background-color: rgb(24, 22, 22);
    border-radius: 10px;
    color: white;
    padding: 10px;
}
 
.callview{
    padding: 10px;
    border: 1px solid #a46200;
    width: auto;
    max-width: 450px;
    gap: 20px;
    display: flex;
    border-radius: 5px;
    margin-top: 15px;
    justify-content: space-between;
}
 
 
.view-box{
    border: 1px solid #a46200;
    padding: 5px;
}
 
.calls-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}
 
 
 
 
 
 
.meeting-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: row;
    margin-top: 50px;
    justify-content: space-around;
}
.metting-comnplete {
    display: flex;
    flex-wrap: wrap;
    gap:40px;
    flex-direction: row;
    margin-top: 50px;
    justify-content: center;
}
.upcoming-card {
   background-color: rgb(245, 244, 244);
    background-size: cover;
    background-position: center;
    color: #161414;
    /* width: 30vw; */
    border-radius: 25px;
    border: 1px solid gray;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
   
}
.upcoming-card h6 {
    font-size: 17px;
    font-weight: 600;
    width: 130px;
}
.Card-title {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
}
.body1{
    padding-left: 30px;
    padding-top: 25px;
    display: flex;
}
.body2{
    padding-left: 30px;
    padding-top: 10px;
    display: flex;
}
.meeting-btn {
    /* margin-top: 10px; */
    height: 40px;
    text-wrap: nowrap;
}
.card-buttons {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    gap: 10px;
}
.meeting-delete {
    height: 40px;
    text-wrap: nowrap;
 
    /* margin-top: 10px; */
}
.completed-card{
    background-color: rgb(245, 244, 244);
    background-size: cover;
    background-position: center;
    color: #161414;
    /* width: 26vw; */
    border-radius: 25px 25px 0px 0px;
    border: 1px solid gray;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
   
}
.completed-card h6{
    width: 100px;
    font-size: 17px;
   font-weight: 600;
}
.completed-card .body-1{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    display: flex;
    flex-direction: row;
}
.completed-card .body-2{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    line-height: 32px;
    display: flex;
    flex-direction: row;
}
.metting-completed  {
    font-size: 20px;
    font-weight: 500;
    color: rgb(253, 253, 255);
    text-align: center;
    padding-top: 15px;
    background-color: #161414;
    /* width: 26vw; */
    /* margin-top: 30px; */
    /* margin-left: -17px; */
    /* margin-bottom: -16px; */
    border-radius: 0px 0px 25px 25px;
    height: 60px;
   
}
.shaking-hands {
    font-size: 24px;
    margin-right: 15px;
}
.mybooking-meeting {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    display: flex;
}
.calls-container {
    justify-content: center;
}
@media screen and (max-width: 415px){
    .card-buttons {
       
        flex-direction: column;
       
    }
    .body1{
        padding-left: 5px;
     
    }
    .body2{
        padding-left: 5px;
       
    }
    .upcoming-card h6 {
        font-size: 17px;
        font-weight: 600;
        width: 120px;
    }
    .completed-card h6{
        width: 120px;
        font-size: 17px;
       font-weight: 600;
    }
    .completed-card .body-1{
        padding-left: 0px;
        padding-right: 0px;
       
    }
    .completed-card .body-1 h6 {
        width: 90px;
    }
    .completed-card .body-2 h6 {
        padding-top: 5px;
    }
    .completed-card .body-2{
        padding-left: 0px;
        padding-right: 0px;
       
    }
   
   
 }
 @media screen and (max-width: 415px){
    .upcoming-card h6 {
        width: 80px;
    }
 }