.p-30 {
  margin-left: 0px;
  overflow-x: hidden;
 /* width: 100%; */
}
.pageContainer-4 {
  overflow-x: hidden;
}
::selection {
  background-color: rgb(222, 82, 60);
  color: rgb(255,255,242);
}
.flexJustify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* justify-content: center; */
  padding: 10px 20px; /* Adjust padding as needed */
}
 
.btnDiv .btnRight {
  color: white;
  background-color: black;
  padding: 5px; /* Adjust padding as needed */
  border-radius: 30px;
  width: 150px;
  height: 43px;
  text-align: center;
}
 
.btnDiv .btnRight:hover {
  cursor: pointer;
  background-color: rgb(26, 26, 26);
  transition: all 0.5s;
  transform: scale(1.05);
}
 
.nav-tabs .nav-link:hover{
  cursor: pointer;
  background-color: rgb(247,246,242);
  transition: all 0.5s;
  transform: scale(0.89);
}
 
/* .photo-set {
  display: flex;
} */
.photo-dic {
  display: flex;
 flex-direction: column;
 row-gap: 10px;
  margin-left: 25px;
  margin-top: 30px;
}
.photo-dic label {
  font-weight: 500;
}
.profile-photo {
  padding-left: 50px;
}
.photo-dic button {
 width: 40%;
 padding: 10px;
 margin-top: 5px;
 border-radius: 5px;
 border:1px solid black;
}
.profile-top {
  display: flex;
gap: 260px;
margin-top: 20px;
margin-bottom: 40px;
}
/* .photo-dic h6 {
  font-size: 18px;
  font-weight: 500;
}
.photo-dic p{
  font-size: 14px;
  font-weight: 400;
} */
 /* .photo {
  width: 800px;
  padding: auto;
  display: flex;
 }
 .photo .profilePicture {
  width: 200px;
  height: 200px;
 } */
 
/* .choose-label {
  margin-left: 120px;
  margin-top: 50px;
  font-weight: bold;
}
#upmark {
  margin-left:2px;
font-size: 50px !important;
margin-top: 0px;
height: 50px;
width: 50px;
border-radius: 15px !important;
background-color: #ffffff;
 
} */
.frm {
  width: 830px;
  padding: auto;
  margin-left: 20px;
  margin: 20px;
}
.frm label {
  font-weight: 500;
  margin-left: 20px;
}
.matebox {
  display: flex;
  margin-left: 20px;
}
.sidebox {
  width:auto;
  height: 50px;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  /* margin-left: 1px; */
  /* Font : Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
  background-color: rgb(244, 240, 240);
  border-radius: 10px 0 0 10px;
  text-align: center;
  border: 1px solid;
 
}
.top {
  font-size: 14px;
  padding: 10px;
  width: 400;
  line-height: 16.38px;
  color: rgba(0,0,0,0.88);
  padding-left: 2px;
  /* Font : Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif */
}
 
.fullName {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
 
  margin-top: 19px;
}
 
.matebox #username {
  width: 390px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid;
  box-shadow: none;
  margin-left: 0px;
  margin-top: 0px;
  padding-left: 10px;
}
 
.namebox {
  width: 900px;
  display: flex;
  margin-top: 11px;
  margin-left: 20px;
}
 
.edit {
  margin-top: 0px !important;
}
.firstName {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
  margin-left: 0px !important;
}
.lastName {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px !important;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
 
}
#firstNamebox {
  width: 272px;
  height: 40px;
  border-radius: 5px;
  margin-top: 9px;
  border: 1px solid;
  box-shadow: none;
  padding-left: 10px;
  /* margin-left: -1px; */
}
#lastNamebox {
  width: 272px;
  height: 40px;
  border-radius: 5px;
  margin-left: 10px;
   margin-top: 9px;
   box-shadow: none;
   border: 1px solid;
   padding-left: 10px;
}
/* .lastName {
  margin-left: 30px;
} */
.namebox #fullName {
  width: 550px;
  height: 40px;
  border-radius: 4px;
  margin-top: 11px;
  box-shadow: none;
   border: 1px solid;
   margin-left: 20px;
   padding-left: 10px;
}
#fullName {
  width: 550px;
  height: 40px;
  border-radius: 4px;
  margin-top: 11px;
  box-shadow: none;
   border: 1px solid;
   margin-left: 20px;
   padding-left: 10px;
}
 
.Category {
  padding-top: 30px;
}
#categoryDropdown {
  width: 200px;
  height: 50px;
  margin-left: 10px;
  border-radius: 10px;
}
.additional {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
  /* font-family: Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
  margin-top: 15px;
}
#additionalInfo {
  width: 550px;
  height: 40px;
  border-radius: 4px;
  margin-top: 11px;
  box-shadow: none;
   border: 1px solid;
   margin-left: 20px;
   padding-left: 10px;
}
.messagebox {
  margin-top: 10px;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
  margin-top: 19px;
  /* font-family: Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
}
 
 
 
#addmessage {
  width: 550px;
  height: 100px;
  border-radius: 4px;
  margin-top: 10px;
  /* color: rgba(0, 0, 0, 0.482); */
  margin-left: 20px;
  border: 1px solid;
  padding-left: 10px;
 
}
 
.frm hr {
  width: 550px;
  border: 1px solid rgba(0, 0, 0, 0.142);
  margin-top: 25px;
  margin-left: 20px;
 
}
 
 
.setting {
  padding-left: 14px;
  font-family: apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
 
 
.setting h3 {
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 39px;
  color: rgba(23, 8, 8, 0.88);
}
 
 
 
 
.setting p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.88);
  word-break: break-word;
  line-height: 1.17;
}
.setting .your-1 {
  margin-top: 5px;
  padding-top: 5px;
  margin-left: 30px;
 
}
.setting .address-1 {
  width: 610px;
  height: 40px;
  display: flex;
  margin-top: 29px;
  justify-content: space-between;
  margin-left: 20px;
 
}
/* .email #inputemailbox {
  padding-left: 10px;
  font-size: 18px;
} */
.setting hr {
  width: 600px;
  border: 0.5px solid rgba(0, 0, 0, 0.142);
  margin-top: 25px;
  position: relative;
  margin-left: 30px;
}
.setting span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.653);
  margin-top: 10px;
}
.setting .address-2 {
  width: 600px;
  height: 40px;
  display: flex;
  margin-left: 30px;
  /* margin-top: 10px; */
  justify-content: space-between;
}
/* .setting .address-3 {
  width:630px;
  height: 40px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}
.setting .address-4 {
  width: 630px;
  height: 80px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}
.setting .address-5 {
  width: 630px;
  height: 40px;
  display: flex;
  margin-top: 20px;
 
} */
.setting .email {
  padding-top: 1px;
  margin-left: 10px;
 
}
.setting #edit-cancel {
margin-left: -150px;
/* margin-top: 10px; */
}
.setting #inputbox {
border-radius: 4px;
width: 590px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top:10px;
font-size: 16px;
padding-left: 10px;
}
.address-2 .countries {
display: flex;
}
.address-2 span{
width:50.25px;
height: 40px;
font-size: 15px;
margin-top: 11px;
margin-left: 1px;
/* Font : Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
background-color: rgb(244, 240, 240);
border-radius: 10px 0 0 10px;
text-align: center;
border: 1px solid;
 
}
.address-2  #numberbox {
border-radius: 8px;
width: 590px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top: 10.5px;
margin-left: 0px;
box-shadow: none;
}
 
 
.address-2 #edit-cancel {
margin-left: -50px;
}
 
.setting #cancel-save {
margin-left: 0%;
width: 80px;
height: 40px;
border-radius: 10px;
background-color: black;
color: #ffffff;
margin-top: 15px;
font-size: 18px;
}
.setting #save-cancel {
  margin-left: 0%;
width: 80px;
height: 40px;
border-radius: 10px;
background-color: black;
color: #ffffff;
margin-top: 15px;
font-size: 18px;
}
.setting .edit-1 {
  padding-top: 10px;
 
}
.setting #mobilebox {
border-radius: 4px;
width: 630px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top:10px;
margin-left:0px;
box-shadow: none;
}
/* .setting #cancel-save-mobile {
margin-left: 0%;
width: 60px;
height: 40px;
border-radius: 8px;
background-color: black;
color: #ffffff;
} */
/* .address-3 #passwordbox {
border-radius: 4px;
width: 630px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top:10px;
margin-left:0px;
box-shadow: none;
}
.address-3 #cancel-save-pass {
margin-left: 0%;
width: 60px;
height: 40px;
border-radius: 8px;
background-color: black;
color: #ffffff;
}
.address-3 #edit-cancel-pass {
margin-left: -50px;
} */
.address-2 #cancel-save {
  margin-left: 0%;
  width: 60px;
  height: 40px;
  border-radius: 8px;
  background-color: black;
  color: #ffffff;
 
  }
 
 
.address-2 .mobile-1 {
  padding-top: 18px;
  font-weight: bold;
}
.address-2 .edit-2 {
padding-top: 10px;
}
/* .address-3 .password-1 {
  padding-top: 18px;
}
.address-3 .edit-3 {
  padding-top: 12px;
}
.not h5 {
  margin-top: 50px;
}
.address-4 .booking-1 {
  padding-top: 35px;
  margin-bottom: 70px;
}
.address-4 .edit-4 {
  padding-top: 30px;
}
.address-5 .need-1 {
  padding-top: 12px;
}
.address-5 .edit-5 {
  padding-top: 15px;
  margin-left:10px;
  font-size: 14px;
 
}
a {
color: black;
font-weight: 600;
}
 */
 
 
#logout {
  background-color: red;
  color: rgb(255, 255, 255);
  border: 1px solid whitesmoke;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90px;
  height: 40px;
  padding: 5px;
  font-size: 16px;
  margin-left: 30px;
  font-weight: 500;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  word-spacing: 5px;
}
 
 
@media screen and (max-width: 550px) {
  .p-30 {
    margin-left: 0 auto;
    /* overflow-x: hidden; */
   
  }
  .pageContainer-4 {
    overflow-x: hidden;
  }
  .profilecontent {
    margin: 0 auto;
    width: 100% !important;
  }
  .profile-main {
    width: 100% !important;
    /* background-color: aqua !important; */
  }
  /* .profile-photo {
    margin-left: 0px;
    padding-left: 0px;
    float: left;
 
  } */
.profile-top {
  display: flex;
  flex-direction: column;
  gap: 150px;
  margin-bottom: 0px;
  margin-left: 5%;
}
.photo-dic {
  display: flex;
 flex-direction: column;
 row-gap: 10px;
 margin: 0 auto;
 margin-top: 0px;
 padding-top: 0px;
 /* margin-top: 50px; */
}
.photo-dic label {
  font-weight: 500;
}
.photo-dic button {
 width: 40%;
 padding: 10px;
 margin-top: 5px;
 border-radius: 5px;
 border:1px solid black;
}
 
.frm {
  width: 100%;
  padding: auto;
  margin-left: -10px !important;
  margin: 20px;
}
.frm label {
  font-weight: 500;
  margin-left: 20px;
  font-weight: 600;
}
.matebox {
  display: flex;
  margin-left: 20px;
}
.sidebox {
  width:auto;
  height: 50px;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  padding-top: 11px;
  /* margin-left: 1px; */
  /* Font : Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
  background-color: rgb(244, 240, 240);
  border-radius: 10px 0 0 10px;
  text-align: center;
  border: 1px solid;
 
}
.top {
  font-size: 14px;
  padding: 10px;
  width: 400;
  line-height: 16.38px;
  color: rgba(0,0,0,0.88);
  padding-left: 2px;
  /* Font : Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif */
}
 
.fullName {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
 
  margin-top: 19px;
}
 
.matebox #username {
  width: 150px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid;
  box-shadow: none;
  margin-left: 0px;
  margin-top: 0px;
  padding-left: 10px;
}
 
.namebox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  margin-left: 0px;
}
 
.edit {
  margin-top: 0px !important;
}
.firstName {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
  margin-left: 20px !important;
}
.lastName {
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px !important;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
 
}
#firstNamebox {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  margin-top: 9px;
  border: 1px solid;
  box-shadow: none;
  margin-left: 20px;
  padding-left: 10px;
  /* margin-left: -1px; */
}
#lastNamebox {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  margin-left: 20px;
   margin-top: 9px;
   box-shadow: none;
   border: 1px solid;
   padding-left: 10px;
}
/* .lastName {
  margin-left: 30px;
} */
#fullName {
  width: 250px;
  height: 40px;
  border-radius: 4px;
  margin-top: 11px;
  box-shadow: none;
   border: 1px solid;
   margin-left: 20px;
   padding-left: 10px;
}
.Category {
  padding-top: 30px;
}
#categoryDropdown {
  width: 200px;
  height: 50px;
  margin-left: 50px;
  border-radius: 10px;
}
.additional {
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
  /* font-family: Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
  margin-top: 15px;
}
#additionalInfo {
  width: 250px;
  height: 40px;
  border-radius: 4px;
  margin-top: 11px;
  box-shadow: none;
   border: 1px solid;
   margin-left: 20px;
   padding-left: 10px;
}
.messagebox {
  margin-top: 10px;
  font-size: 14px;
  color:rgba(0, 0, 0, 0.88);
  line-height: 16.38px;
  margin-top: 19px;
  /* font-family: Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
}
 
 
 
#addmessage {
  width: 250px;
  height: 100px;
  border-radius: 4px;
  margin-top: 10px;
  /* color: rgba(0, 0, 0, 0.482); */
  margin-left: 20px;
  border: 1px solid;
  padding-left: 10px;
 
}
 
.frm hr {
  width: 550px;
  border: 1px solid rgba(0, 0, 0, 0.142);
  margin-top: 25px;
  margin-left: 20px;
 
}
 
.setting {
  padding-left: 0px;
  font-family: apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
 
 
.setting h3 {
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 39px;
  color: rgba(23, 8, 8, 0.88);
}
 
 
 
 
.setting p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.88);
  word-break: break-word;
  line-height: 1.17;
}
.setting .your-1 {
  margin-top: 5px;
  padding-top: 5px;
  margin-left: 30px;
 
}
.setting .address-1 {
  width: 250px;
  height: 40px;
  display: flex;
  margin-top: 29px;
  justify-content: space-between;
  margin-left: 0px;
 
}
/* .email #inputemailbox {
  padding-left: 10px;
  font-size: 18px;
} */
.setting hr {
  width: 250px;
  border: 0.5px solid rgba(0, 0, 0, 0.142);
  margin-top: 25px;
  position: relative;
  margin-left: 30px;
}
.setting span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.653);
  margin-top: 10px;
}
.setting .address-2 {
  width: 250px;
  height: 40px;
  display: flex;
  margin-left:8px;
  /* margin-top: 10px; */
  justify-content: space-between;
}
/* .setting .address-3 {
  width:630px;
  height: 40px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}
.setting .address-4 {
  width: 630px;
  height: 80px;
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}
.setting .address-5 {
  width: 630px;
  height: 40px;
  display: flex;
  margin-top: 20px;
 
} */
.setting .email {
  padding-top: 1px;
  margin-left: 10px;
 
}
.setting #edit-cancel {
margin-left: -150px;
/* margin-top: 10px; */
}
.setting #inputbox {
border-radius: 4px;
width: 230px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top:10px;
font-size: 16px;
padding-left: 10px;
}
.address-2 .countries {
display: flex;
}
.address-2 span{
width:50.25px;
height: 40px;
font-size: 15px;
margin-top: 11px;
margin-left: 1px;
/* Font : Gilroy, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif; */
background-color: rgb(244, 240, 240);
border-radius: 10px 0 0 10px;
text-align: center;
border: 1px solid;
 
}
.address-2  #numberbox {
border-radius: 8px;
width: 250px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top: 10.5px;
margin-left: 0px;
box-shadow: none;
}
 
 
.address-2 #edit-cancel {
margin-left: -50px;
}
 
.setting #cancel-save {
margin-left: 0%;
width: 80px;
height: 40px;
border-radius: 10px;
background-color: black;
color: #ffffff;
margin-top: 15px;
font-size: 18px;
}
.setting #save-cancel {
  margin-left: 0%;
width: 80px;
height: 40px;
border-radius: 10px;
background-color: black;
color: #ffffff;
margin-top: 15px;
font-size: 18px;
}
.setting .edit-1 {
  padding-top: 10px;
 
}
.setting #mobilebox {
border-radius: 4px;
width: 630px;
height: 40px;
border: 1px solid black;
padding: 5px;
margin-bottom: 10px;
margin-top:10px;
margin-left:0px;
box-shadow: none;
}
.address-2 #cancel-save {
  margin-left: 0%;
  width: 60px;
  height: 40px;
  border-radius: 8px;
  background-color: black;
  color: #ffffff;
 
  }
 
 
.address-2 .mobile-1 {
  padding-top: 18px;
  font-weight: bold;
}
.address-2 .edit-2 {
padding-top: 10px;
}
.save-2 {
  margin-left: 50px;
}
/* #save-3 {
  margin-left: 150px;
  margin-right: 0px;
  padding-left: 150px;
} */
.profile-photo {
  margin-left: -26.5px;
}
/* .profile-photo img {
  border-radius: 80px;
  height: 100px;
} */
}
@media screen and (max-width: 363px) {
  .p-30 {
    margin-left: -40px;
    /* overflow-x: hidden; */
   
  }
}