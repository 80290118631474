

/* Apply a reset to remove default margin and padding */
body, html {
    margin: 0;
    padding: 0;
    background-color: #f5f2f2;
  }
  
  /* Set FullCalendar container to take up the full viewport */
  .fc {
    width: 100%;
    height: 100%;
  }
  
  /* Optional: Add additional styles to make it look neat and polished */
  .fc-header-toolbar {
    background-color: #1a1919; /* Set a background color for the header toolbar */
    padding: 10px; /* Add padding to the header toolbar */
  }
  
  .fc-view {
    border: 1px solid #ddd; /* Add a border to the calendar view */
    border-radius: 8px; /* Optional: Add border radius for a rounded appearance */
    overflow: hidden; /* Hide any overflow content */
  }

  
  

  