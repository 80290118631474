.landingpage{
    display: flex;
    flex-direction: column;
   
}
 

.top-bar{
    position: fixed;
    width: 100%;
    background-color: black;
    z-index: 4;
}

.topmate-logo{
    width: 200px;
}

.nav-heads{
    font-weight: bold;
    color: white;
}

.navbar .nav-link {
    color: white;
}

.navbar-nav .nav-link.active {
    color: white; 
  }


.ai-bar{
    border: none;
   
    background-color: black;
    gap: 10px;
    border-radius: 20px;
}
.Creators-Screen-1 {
 
    background-repeat: no-repeat;
    width: 100%;
    overflow-x: hidden;
    padding-top: 150px;
    background: linear-gradient(138deg, #eeeeee 65%, rgb(122, 123, 123) 35%);
 }

.Creators-1-block {
    display: flex;
    justify-content:center;
   gap: 5px;
}
.Creators-1-textbox {
  margin-top: 20px; 
   margin-left: 50px;
}
.createrMen img {
    width: 550px;
    height: 530px;
    padding-left: 50px;
    margin-top: -90px;
    /* float: right; */
  }
  .Creators-Screen-1  p {
    padding-top: 25px;
    font-size: 20px;
    line-height: 40px;
    width: 90%;
    text-align: center;
    font-weight: 600;
    margin: 0 auto;
}

.Creators-Screen-1 .you-1 {
    width: 770px;
   border: 3px solid black;
   border-radius: 20px;
   margin-bottom: 50px;
   line-height: 60px;
   align-items: center;
   text-align: center;
   padding-top: 10px;
   font-size: 54px;
    font-weight: 700;
} 
.Creators-Screen-1 #Sign {
 color: white;
 background-color: black;
 width: 60%;
 height: 50px;
 border-radius: 40px;
 font-weight: 600;
} 

.Creators-Screen-1 .name-plate {
  background-color: rgb(235, 225, 225);
font-size: 24px;
font-weight: 500;
  text-align: center;
  border-radius: 30px 0px 30px 0;
  width: 300px;
  height: 34px;
  margin-left: 30px;
 color: rgb(20, 18, 18);
  position: relative;
  margin-bottom: 5px;
margin-left: 29%;
}

@media (max-width: 1040px) and (min-width: 740px) {
     
    .Creators-Screen-1 {
     
        padding-top: 100px;
        background: linear-gradient(138deg, #eeeeee 65%, rgb(75, 75, 75) 35%);
     }
    
        .Creators-1-textbox {
            margin-top: 10px; 
            padding-top: 20px;
           
          }
          .createrMen img {
              width: 400px;
              height: 400px;
              margin-top: 30px;
              
             
            }
            .Creators-Screen-1 p {
             
              line-height: 30px;
             
          }
          
          .Creators-Screen-1 .you-1 {
              width: 500px;
             line-height: 55px;
          
             font-size: 44px;
              font-weight: 650;
          } 
          .Creators-1 #Sign {
          
           width: 60%;
           height: 50px;
          
    
          }
          
          .createrMen .name-plate {
            background-color: #eeeeee;
          font-size: 24px;
            width: 300px;
            height: 34px;
          margin-left: 60px;
            margin-bottom: 5px;
          }
    }
    @media (max-width: 900px) and (min-width: 740px) {
        .Creators-1-block {
            display: flex;
            justify-content:center;
           gap: 0px;
        }
        .Creators-1-textbox {
           margin-top: 0px;
            margin-left: 5%;
          }
          .createrMen img {
              width: 350px;
              height: 400px;
              margin-top: 30px;
              /* padding-left: -50px; */
              margin-left: -80px;
            }
            .Creators-Screen-1 p{
             
              line-height: 35px;
            
          }
          
          .Creators-Screen-1 .you-1 {
            width: 500px;
            line-height: 55px;
    
          }
          .createrMen .name-plate {
            background-color: #eeeeee;
          font-size: 24px;
         
            width: 250px;
            height: 34px;
            margin-left: -30px;
          
            margin-bottom: 5px;
          
          }
    }
    
    @media (max-width: 540px) and (min-width: 270px) {
        
        .Creators-1-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; /* Add this line */
           height: auto;
        }
        
    
        .Creators-1-textbox {
            display: inline-block; 
            margin: 0 auto;
            text-align: center; 
        }
    
        .createrMen img {
            width: 100%; 
           height: 400px;
            margin-top: -40px;
        }
    
        .Creators-Screen-1 p{
            padding-top: 25px;
            font-size: 4vw; 
            line-height: 1.5;
            width: 100%;
            text-align: center;
            font-weight: 600;
            margin: 0 auto; 
        }
        
        .Creators-Screen-1 .you-1 {
            width: 90%; 
            border: 0px;
           margin: 0 auto;
            margin-bottom: 20px; 
            line-height: 1.2; 
            align-items: center;
            text-align: center;
            padding-top: 0px;
            font-size: 6vw;
            font-weight: 700;
            margin-top: -10%;
        } 
        
        .Creators-Screen-1 #Sign {
            width: 60%;
            max-width: 300px;
            height: 8vw; 
            border-radius: 40px;
            font-weight: 600;
            transition: background-color 1s, color 2s;
        }
        .Creators-Screen-1 #Sign:hover {
            background-color: rgb(48, 48, 48);
            color: #d4cece;
        }
        .createrMen .name-plate {
            background-color: #eeeeee;
            font-size: 3vw;
            font-weight: 700;
            text-align: center;
            width: 50%;
            max-width: 350px;
            height: 4vw;
            margin-left: auto;
            margin-right: auto;
            color: rgb(20, 18, 18);
            position: relative;
            margin-bottom: 5px;
        }
        
    }

    
/* /screen-2 */
.Creators-Screen-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* min-height: 80vh; */
    overflow-x: hidden;
    margin-bottom: 0%;
    padding-bottom: 5%;
}
.Creators-Screen-2 h1 {
    font-size: 54px;
    line-height: 70px;
    font-weight: 700;
    padding-top: 6.5%;
    width: 80%;

}
.Creators-Screen-2 .should {
    justify-content: center !important;
    margin-top: 5%;
    display: flex;
    width: 250px;
    column-gap: 40px;
   
}

.Creators-Screen-2 #notepad {
    font-size: 84px;
    margin-top: 15px;
    margin-right: 20px;
    color: #ffffff;
   
  }

.Creators-Screen-2  #dimand {
    font-size: 100px;
    margin-top: 15px;
    margin-right: 5px;
    color: #ffffff;
}
 
.Creators-Screen-2 #world {
    font-size: 100px;
    margin-top: 15px;
    margin-right: 5px;
    color: #ffffff;
  }

.Creators-Screen-2 .should .begin {
    display: flex;
    padding-top: 0px;
    justify-content: center;
    gap: 10px;
    width: 250px;
    min-height: 110px;
    background-color: rgb(75, 75, 75); 
   
}


.Creators-Screen-2 .should .notePad {
    background-color: rgb(242, 245, 245);
    width: 250px;
    margin: 0 auto;
    transition: transform 0.9s;
}
.Creators-Screen-2 .should .notePad:hover {
    transform: scale(1.1);
}
.Creators-Screen-2 .should  h2 {
    font-size: 24px;
    padding-top: 35px;
    color: #ffffff;
}

.Creators-Screen-2 .should p {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: 500;
    width: 95%;
    line-height: 1.5;
    text-align: left;
    padding-left: 20px;
  
}

.Creators-Screen-2 .should .connect {
    background-color: rgb(245, 247, 247);
    transition: transform 0.9s;
    width: 250px;
    margin: 0 auto;
}
.Creators-Screen-2 .should .connect:hover {
transform: scale(1.1); 
}
.Creators-Screen-2 .should .connect h2 { 
    padding-left: 10px;
}

.Creators-Screen-2 .should .up-skill {
    background-color: rgb(245, 247, 247);
    transition: transform 0.9s;
    width: 250px;
    margin: 0 auto;
}
.Creators-Screen-2 .should .up-skill:hover {
    transform: scale(1.1); 
    }
.Creators-Screen-2 .should .up-skill h2 { 
    padding-left: 10px;
}
@media screen and (max-width: 1040px) {
    .Creators-Screen-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: auto;
        overflow-x: hidden;
        margin-bottom: 0;
        padding-bottom: 5%;
    }
}


@media (min-width: 700px) and (max-width: 900px) {
    .Creators-Screen-2 {
        overflow-x: hidden !important;
    }
    .Creators-Screen-2  .should {
        justify-content: center;
        margin-top: 5%;
        display: flex;
        width: 250px;
        column-gap: 10px;
    }
    .Creators-Screen-2 h1 {
        font-size: 44px;
        line-height: 60px;
        font-weight: 700;
        padding-top: 6.5%;
        width: 80%;
    
    }
    .Creators-Screen-2 .should .notePad {
        background-color: rgb(242, 245, 245);
        width: 220px;
        margin: 0 auto;
    }
    .Creators-Screen-2 .should .notePad h2 {
        width: 300px;
       margin-left: -30px;
       padding-top: 30px;
    }
    .Creators-Screen-2 .should .connect {
        background-color: rgb(245, 247, 247);
        transition: transform 0.3s;
        width: 220px;
    }
    .Creators-Screen-2 .should .up-skill {
        width: 220px;
    }
    .Creators-Screen-2 .should .begin {
        width: 220px;
        height: 130px;
    }
    .Creators-Screen-2 .should  h2 {
        font-size: 24px;
        padding-top: 15px;
        color: #ffffff;
        
    }
    
}
@media (max-width: 540px) and (min-width: 270px) {
    .Creators-Screen-2  .should { 
        flex-direction: column;
    }
    .Creators-Screen-2 h1 {
        font-size: 6vw;
        line-height: 1.2; 
        font-weight: 700;
        padding-top: 6.5%;
        width: 100%;
        max-width: 770px; 
    }
    .Creators-Screen-2 .should {
        row-gap: 40px; 
    }
    .Creators-Screen-2 .should p {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 20px;
        font-weight: 500;
        width: 95%;
        line-height: 1.5;
        text-align: left;
        padding-left: 20px;
      
    }
}
 .Creators-Screen-3 {
    max-width: 100%;
    
    background-image: linear-gradient(
        to bottom,
        #31424d 0%,
        #0a171e 90%,
        #ffffff 90%,
        #ffffff 100%
    );
    color: #ffffff;
    overflow-x: hidden;
}

.Creators-Screen-3 .carly {
    display: flex;
    justify-content: center;
    padding-top: 4%;
    column-gap: 100px;
    /* background-color: antiquewhite; */
    /* padding-bottom: 8%; */
}
.Creators-Screen-3 .carly .Love {
    /* background-color: #8960f6; */
    width: 600px;
    padding-top: 3%;
    padding-bottom: 0px;
}
.Creators-Screen-3 .Love h2 {
    font-size: 32px;
    /* font-size: 54px; */
    font-weight: 700;
    line-height: 42px;
}
.Creators-Screen-3 .Love h3 {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 700;
       
}
.Creators-Screen-3 .Love p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    width: 120px;
}
.Creators-Screen-3 .Love h5 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    width: 150px;
    padding-bottom: 25px;
}
.Creators-Screen-3 .Love a {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    padding-bottom: 0px;
   
}
.Creators-Screen-3 #girl {
    width: 350px;
    height: 550px;
    margin-top: 5px;
 
}

@media (min-width: 700px) and (max-width: 1024px) {
        
    .Creators-Screen-3 .carly {
        display: flex;
        justify-content: center;
        padding-top: 0%;
        column-gap: 40px;
    
    }
    .Creators-Screen-3 .carly .Love {
      
        width: 500px;
        padding-top: 6%;
        padding-bottom: 100px;
        padding-left: 5%;
    }
    .Creators-Screen-3 .Love h2 {
        font-size: 32px;
       
        font-weight: 700;
        line-height: 42px;
    }
    .Creators-Screen-3 .Love h3 {
        padding-top: 30px;
        font-size: 18px;
        font-weight: 700;
           
    }
    .Creators-Screen-3 .Love p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        width: 120px;
    }
        
    .Creators-Screen-3 #girl {
            width: 300px;
            height: 500px;
            /* padding-top: 60px; */
            border-radius: 0px;
            margin-top: 50px;
    }
        
    .Creators-Screen-3 h1 {
            font-size: 46px;
            line-height: 50px;
            padding-top: 2%;
    }
    }
    @media (max-width: 540px) and (min-width: 270px) {
        .Creators-Screen-3 .carly {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; /* Center items horizontally */
            padding-top: 0%;
            padding-bottom: 90px;
            column-gap: 40px;
        }
    
        .Creators-Screen-3 #girl { 
            order: 1;
            width: 100%;
            height: auto;
            margin: 0 auto; /* Center horizontally */
            margin-top: 50px;
            max-width: 300px; /* Limit maximum width */
            align-self: center; /* Center the image horizontally */
        }
        
    
        .Creators-Screen-3 .carly .Love {
            order: 2;
            padding-top: 4%;
            margin-bottom: 10%;
            text-align: center; /* Center text horizontally */
        }
    
        .Creators-Screen-3 .Love h2 {
            font-size: 6vw;
            line-height: 1.4; 
            font-weight: 580;
            padding-top: 6.5%;
            width: 70%;
            max-width: 770px; 
            padding-left: 20px;
            padding-right: 20px;
            margin: 0 auto; 
        }
        
    
        .Creators-Screen-3 .Love h3 {
            padding-top: 20px;
            font-size: 16px;
            width: 100%;
            padding-left: 0; /* Reset padding */
        }
    
        .Creators-Screen-3 .Love p {
            font-size: 14px;
            line-height: 20px;
            width: 50%;
            padding-top: 10px;
            padding-left: 0; /* Reset padding */
            margin: 0 auto; /* Center the paragraph horizontally */
        }
        
    
        .Creators-Screen-3 .Love h5 {
            padding-top: 0px;
            font-size: 14px;
            width: 100%;
            padding-left: 0;
        }
    
        .Creators-Screen-3 .Love a {
            float: none; /* Reset float */
            width: 100%;
        }
    }
    @media (max-width: 320px) {
        .Creators-Screen-3 .Love h2 {
            width: 300px;
            font-size: 5.5vw; /* Adjusted font size for 270px */
            padding-left: 10px; /* Adjusted padding for 270px */
            padding-right: 10px; /* Adjusted padding for 270px */
        }
    }
    @media (max-width: 280px) {
        .Creators-Screen-3 .Love h2 {
            width: 270px;

        }
    }


.Creators-Screen-4 {
    max-width: 100%;
    background-color: #ffffff;
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: auto;
}
.Creators-Screen-4 h1 {
    font-size: 54px;
    font-weight: 700;
    padding-top: 4%;
    margin: 0%;
    line-height: 70px;
    text-align: center;
   
 }

.Creators-Screen-4 .main-card-6 {
    height: 100%;
    display: grid;
    place-items: center;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .Creators-Screen-4 .container-6 {
    padding: 0 40px;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
  }
  .Creators-Screen-4 .card-6 {
    height: 290px;
    max-width: 350px;
    margin: 0 20px;
    background: white;
    transition: 0.4s;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  }
  .Creators-Screen-4 .card-6:hover {
    height: 350px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2);
  }
  .Creators-Screen-4 .card-6 .img-6 {
    height: 290px;
    width: 100%;
  }
  .Creators-Screen-4 .card-6 .img-6 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .Creators-Screen-4 .card-6 .bottom-text-6{
    padding: 0 20px 10px 20px;
    margin-top: 5px;
    background: white;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
  }
  .Creators-Screen-4 .card-6:hover .bottom-text-6{
    opacity: 1;
    visibility: visible;
  }
  .Creators-Screen-4 .card-6 .bottom-text-6 .text-6{
    text-align: justify;
  }
  .Creators-Screen-4 .card-6 .bottom-text-6 .btn-6{
    margin: 10px 0;
    text-align: left;
  }
  .Creators-Screen-4 .card-6 .bottom-text-6 h3 {
    width: 100%;
    font-size: 24px;
    word-spacing: -8px; 
  }
  
  .Creators-Screen-4 .card-6 .bottom-text-6 h2 {
    width: 100%;
    font-size: 24px;
 
  }
  .Creators-Screen-4 .card-6 .bottom-text-6 .btn-6 a{
    text-decoration: none;
    background: #e74c3c;
    color: #f2f2f2;
    padding: 5px 8px;
    border-radius: 3px;
    display: inline-flex;
    transition: 0.2s;
  }
  .Creators-Screen-4 .card-6 .bottom-text-6 .btn-6 a:hover{
    transform: scale(0.9);
  }

@media screen and (min-width: 768px) and (max-width: 1024px) {

    .Creators-Screen-4 .container-3 {
        position:relative; 
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 4%;
        margin-bottom: 6%;
       
    }
    .Creators-Screen-4 .container-3 .mycard {
        position: relative;
        max-width: 300px;
        height: 150px;
        
        margin: 60px 15px;
       
        display: flex;
        flex-direction: column;
        
        transition: 0.4s ease-in-out;
        justify-content: center;
        align-items: center;
        
    }
}

 
/* screen-5 */
  
.Creators-Screen-5 .spreading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: rgba(36, 55, 70, 0.6); */
    background-color:  rgb(66,84,98);
    padding-top: 100px;
    padding-bottom: 50px;
}

.Creators-Screen-5 .spreading h2 {
    font-size: 54px;
    line-height: 65px;
    font-weight: 700;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
   color: white;
} 

.Creators-Screen-5 .numbers {
    display: flex;
    justify-content: center;
  
}
.Creators-Screen-5 .numbers p {
  
}
.Creators-Screen-5 .block-1 {
    background-color: #DBDBDB;
    width: 100vw;
    color: #243746;
    font-size: 22px;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px; 
}
.Creators-Screen-5 .block-2 {
    background-color: #D3D3D3;
    color:rgb(66,84,98);
    width: 100vw;
    font-size: 22px;
    text-align: center; 
    padding-top: 100px;
    padding-bottom: 50px; 
}
.Creators-Screen-5 .block-3 {
    background-color: #DBDBDB;
    width: 100vw;
    color: #243746;
    font-size: 22px;
    text-align: center; 
    padding-top: 100px;
    padding-bottom: 50px; 
}
.Creators-Screen-5 .block-1 h1,
.Creators-Screen-5 .block-2 h1,
.Creators-Screen-5 .block-3 h1 {
    font-size: 80px;
    font-weight: bolder;
    text-align: center; /* Center align the text */
}

 #join {
    width: 30%;
    height: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #141414;
    color: #ffffff;
    font-size: 24px;
   margin-left: 530px;
    align-items: center;
    
}


@media (min-width:760px) and (max-width: 1040px) {
    .Creators-Screen-5 .spreading h2 {
        font-size: 44px;
       
        width: 90%;
    
    } 
}
@media (min-width:280px) and (max-width: 540px) {
    .Creators-Screen-5  {
    
    padding-top: 8%;
    padding-bottom: 10%;  
    }
 
    .Creators-Screen-5 .spreading h2 {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 40px;
        width: 90%;
        text-align: center; 
        margin-left: auto; 
        margin-right: auto;
    }
 
   
    .Creators-Screen-5 .numbers {
        display: flex;
        flex-direction: column;
        gap: 5%;
        justify-content: center;
        text-align: center; 
        margin-left: auto; 
        margin-right: auto;
       
    }
    .Creators-Screen-5 .block-1 h1, .block-2 h1, .block-3 h1 {
        font-size: 60px;
    }
 
    .Creators-Screen-5 #join {
        width: 50%;
        height: 5%;
        font-size: 18px;
        margin-top: 2%;
    }
} 