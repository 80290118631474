/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
} */
.super-admin-Title{
  font-weight: 700;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
  font-size: 28px; 
  padding-left: 1px;
}
.main-bar {
  display: flex;
  margin: 0px;
}


.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.closed{
  margin-left:45px;
  width: 100%;
  z-index: 1;
}
.open{
  margin-left:200px;
  width: 100%;
}

.main-bar .sidebar {
  background-color: #367bea;
  color: rgb(252, 252, 252);
  height:100vh;
  position: fixed;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  z-index: 2;
  text-decoration: none;
} 

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: rgb(246, 246, 246);
  gap: 15px;
  align-items: center;
  padding: 10px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: #ece5d9;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.m-active {
  background-color:#ece5d9;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  text-decoration: none;
}

.menu {
  display: flex;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}



@media screen and (max-width: 770px) {
  .sidebar {
      width: 45px;
      z-index: 3;
  }

  .link_text {
      display: none;
  }
  .closed {
      margin-left: 45px;
      width: 100%; 
  }

  .open {
      margin-left: 45px; 
      width: 100%;
  }
}