html, body {
  margin: 0;
  padding: 0;
 
}
/* .Booking-page {
  color: rgb(30, 29, 29);
  padding-top: 30px;
    display: flex;
   
    flex-direction: column;
    overflow-x: hidden;
}
.Booking-page h4 {
  text-align: center;
  margin: 0 auto;
 } */
 
.service-page  {
  color: rgb(30, 29, 29);
 
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
 
.service-page h4{
  width: 100%;
  /* background: linear-gradient(135deg, #9ebde0, #e5c9c6); */
  background-size: 400% 400%;
  animation: gradientBG 8s ease infinite;
  height: 50px;
  /* text-align: center; */
  margin: 0 auto;
  justify-content: space-between;
 
}
 
/* @keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
 
.service-page  h4 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
 
  margin: 0 auto;
  padding-top: 5px;
 
}
 
 
 
.service-page  .table-wrapper {
  width: 100%;
  /* overflow-x: hidden; */
 
}
 
.service-page .table-container {
  margin-top: 30px;
  max-height: 500px;
  /* overflow-y: auto;
  overflow-x: auto; */
   
 
}
 
.service-page  .table {
  width: 100%;
  border-collapse: collapse;
}
 
.service-page .table th,
.table td {
  padding: 10px 15px;
  border: 1px solid #706f6f;
  text-align: left;
  white-space: nowrap;
 
}
.table {
  width: 100%;
  border-collapse: collapse;
}
 
 
.service-page  .table th {
  background-color: #393838;
  color: aliceblue;
}
 
.service-page .action-icon {
  margin-right: 5px;
}
 
.service-page  .profile-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
/* .A1{
  margin-bottom: 1rem;
  width: 20vw;
  margin-left: -1200px;
} */
 
/* Scrollbar styles */
.service-page  .table-container::-webkit-scrollbar {
  height: 12px;
  width: 12px; /* Adjust as needed */
}
 
.service-page  .table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
 
.service-page  .table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
 
/* Make specific columns sticky */
th:nth-child(2),
td:nth-child(2){
  position: sticky;
  /* background-color: white; */
  z-index: 1;
}
 
th:nth-child(2),
td:nth-child(2) {
  left: 0;
}
 
.service-filter {
 
  padding: 50px;
}
 
.service-button {
  border: 2px solid #aaa;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  padding: 8px 16px;
  margin-right: 10px;
  cursor: pointer;
}
 
.service-button:hover {
  background-color: #eee;
}
/* CSS */
.pagi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  color: white;
}
 
 
/* Media query for tablets (tab view) */
/* Media query for tablets (tab view) */
@media only screen and (max-width: 1024px) {
  .service-page   .table {
    width: 100%;
  }
  .service-page   {
    /* margin-left: -400px; */
    color: white;
    padding-top: 30px;
  }
  .service-page  .table-wrapper {
    width: 100%;
    overflow-x: auto; /* Add horizontal scrolling */
    overflow-y: auto; /* Add vertical scrolling */
  }
 
}
 
/* Media query for mobile */
@media only screen and (max-width: 580px) {
  .service-page  .table {
    width: 100%;
  }
  .service-page  {
    /* margin-left: -600px; */
    color: white;
    padding-top: 30px;
  }
  .service-page  .table-wrapper {
    width: 100%;
    overflow-x: auto; /* Add horizontal scrolling */
    overflow-y: auto; /* Add vertical scrolling */
  }
  .service-filter {
    padding: 10px;
  }
 
  .service-button {
    margin-top: 20px;
  }
  .pagi{
    margin-right: 15%;
  }
 
}
 
.filter-field {
  margin-bottom: 1rem;
  width: 20vw;
  margin-left: 10p;
  margin-top: 3rem;
}
 
@media only screen and (max-width: 1024px) {
  .filter-field {
    width: 50%;
   
  }
  .pagi{
    margin-right: 10%;
  }
  .service-filter {
    margin-left: 10%;
  }
}
 
@media only screen and (max-width: 580px) {
  .filter-field {
    width: 50%;
  }
}
.service-page {
  color: rgb(30, 29, 29);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
 
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  /* background: linear-gradient(135deg, #4a90e2, #c4bfbf); */
  background-color: #367bea;
  background-size: 400% 400%;
  animation: gradientBG 8s ease infinite;
  padding: 0 20px; /* Optional: Adjust padding as needed */
}
 
.service-page  h4 {
  height: 60px;
  line-height: 60px; /* Align text vertically */
  margin: 0;
  flex: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
 
.search-container {
  position: relative;
  display: flex;
  align-items: center;
}
 
.search-container input {
  padding: 8px 32px 8px 8px; /* Adjust padding to make room for the icon */
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 400px; /* Adjust width as needed */
 
}
 
.search-icon {
  position: absolute;
  right: 8px;
  color: #999;
}
 
/* @keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
 
.table-wrapper {
  overflow-x: auto;
}
 
.table-container {
  width: 100%;
}
 
.table {
  width: 100%;
  border-collapse: collapse;
}
 
.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
}
 
.table th {
  background-color: #f2f2f2;
  text-align: left;
}
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -30px;
  }
 
  .search-container input {
    width: 100%; /* Take full width on mobile */
     margin-bottom: 15px;
   
  }
  .search-icon {
   
    right: 8px;
    color: #999;
  }
  .service-page  h4 {
   text-align: center;
  }
}
.filter-container{
  background-color: #333;
}
/* Admin.css */
.custom-dropdown-toggle {
  background-color: #343a40; /* Dark background color */
  color: white; /* White text color */
  border: none; /* No border */
  padding: 10px 20px; /* Padding for size */
  font-size: 16px; /* Font size */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}
 
.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:active {
  background-color: #495057; /* Slightly lighter dark color on hover/focus */
  color: white; /* White text color */
}
 
.custom-dropdown-menu {
  background-color: #343a40; /* Dark background color */
  border: none; /* No border */
  margin-top: 5px; /* Margin at the top */
  border-radius: 4px; /* Rounded corners */
  overflow: hidden; /* No overflow */
}
 
.custom-dropdown-item {
  color: white; /* White text color */
  padding: 10px 20px; /* Padding for size */
  cursor: pointer; /* Pointer cursor on hover */
}
 
.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  background-color: #495057; /* Slightly lighter dark color on hover/focus */
}