.portfolio-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .portfolio-1 h3 {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .portfolio-1 .portfolio-1-table {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    align-items: center;
  }
   
  .portfolio-1  .table-1 {
    width: 100%;
  }
  .portfolio-1 .table-1-hover th {
    background-color: #2e2929;
    color: aliceblue;
  }
  .portfolio-1 .table-1-hover {
    width: 100%;
    border-collapse: collapse;
  }
   
  .portfolio-1 .table-1-hover td, .table-1-hover th {
    padding: 14px;
    border-bottom: 1px solid #b0acac;
  }
   
  .portfolio-1 .table-1-hover .table-wrapper {
    overflow-x: auto;
    max-width: 100%;
  }
   
  @media screen and (max-width: 576px) {
    .portfolio-1 {
      overflow-x: hidden;
    }
    .portfolio-1-table {
     
      overflow-x: scroll;
    }
    .table-1-hover th:nth-child(2),
    .table-1-hover td:nth-child(2) {
      position: sticky;
      z-index: 5;
      left: 4;
    }

  }