.bg-appoint{
  background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 29%, #80D0C7 94%);
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.unique-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 30px;
  width: 450px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 8%;
}

.unique-form ::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.unique-form button {
  align-self: flex-end;
}

.unique-flex-column > label {
  color: #151717;
  font-weight: 600;
}

.unique-inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.unique-input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 85%;
  height: 100%;
}

.unique-input:focus {
  outline: none;
}

.unique-inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.unique-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.unique-flex-row > div > label {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.unique-span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.unique-button-submit {
  margin: 20px 0 10px 0;
  background-color: #151717;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
}

.unique-button-submit:hover {
  background-color: #252727;
}

.unique-p {
  text-align: center;
  color: black;
  font-size: 14px;
  margin: 5px 0;
}

.unique-btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.unique-btn:hover {
  border: 1px solid #2d79f3;
}


.success{
  color: green;
  font-weight: bold;
}

.error{
  color: red;
  font-weight: bold;
}


.overfloewww{
  height: 90vh;
  overflow: scroll;
  margin-top: 15px;
}

.hostpage{
  height: 95vh;
  overflow: scroll;
}

.hostbox {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

/* Styles for the Meeting ID, Name, Start Date, and End Date paragraphs */
.hostbox p {
  margin: 5px 0;
}

/* Styles for the Host Room URL and User Room URL links */
.hostbox a {
  text-decoration: none;
  color: #fff;
}

.calender-links a {
  color: black !important; /* Set the color to black */
  text-decoration: underline !important; /* Add underline decoration if needed */
}







/* AttendedMeetings.css */

/* Container for the attended meetings */
.attended-meetings-container {
margin: 20px;
}

/* Heading style for the meetings */
.meetings-heading {
font-size: 24px;
font-weight: bold;
margin-bottom: 10px;
}

/* Style for the table */
.meetings-table {
width: 100%;
border-collapse: collapse;
border: 1px solid #ccc;
}

/* Style for table headers */
.table-header {
background-color: #f2f2f2;
font-weight: bold;
padding: 8px;
border: 1px solid #ccc;
}

/* Style for table rows */
.table-row:nth-child(even) {
background-color: #f9f9f9;
}

/* Hover effect on table rows */
.table-row:hover {
background-color: #f1f1f1;
}

/* Style for table cells */
.table-cell {
padding: 8px;
border: 1px solid #ccc;
}


/* Hide the radio buttons */
.time-slot-label input[type="radio"] {
position: absolute;
opacity: 0;
}

/* Style the labels */
.time-slot-label {
display: block;
margin-bottom: 5px;
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
cursor: pointer;
}

/* Style the labels when the radio button is checked
.time-slot-label input[type="radio"]:checked + .time-slot-label {
background-color: blue;
color: white;
} */

/* Additional style for checked radio buttons */
.time-slot-label input[type="radio"]:checked {
background-color: blue;
color: white;
}