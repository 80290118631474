.Resume-4 {
    font-family: 'Open Sans', sans-serif;
    background-color: #1a1a1a;
    padding: 0;
    margin: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
}
 
.Resume-4 .paddTop {
    padding-top: 30px;
   
}
.Resume-4 .bannerimg-2 {
  display: none;
}
.Resume-4 .darkParaTextWhite-1 {
    color: #fefefe;
    width: 75%;
}
 
.Resume-4 .bannerimg {
    margin-top: 5%;
    margin-right: 5%;
    width: 600px;
    height: 650px;
}
 
.secTitle-3 {
    align-items: flex-start;
    float: left;
    margin-left: 8%;
}
 
.Resume-4 .colorOrange {
    color: #ec5711;
}
 
.Resume-4 .mb0 {
    margin-bottom: 0;
}
 
.Resume-4 .mb50 {
    margin-bottom: 50px;
    min-height: 200px;
}
 
.Resume-4 .secTop50 {
    margin-top: 50px;
}
 
/* Background Colors */
.Resume-4 .bgSec {
    background-color: #d3c9c7;
}
 
.Resume-4 .bgDarkSec {
    background-color: #000000;
}
 
/* Text Colors */
.Resume-4 .textColor {
    color: #ec5711;
}
 
.Resume-4 .darkParaText {
    color: #c0c0c0;
}
 
 
.Resume-4 .pink {
    color: #ea25c2;
}
 
/* Text Alignments */
.Resume-4 .textCenter {
    /* text-align: center; */
    font-size: 20px;
}
 
.Resume-4 .textRight {
    text-align: right;
}
 
.Resume-4 .textLeft {
    text-align: left;
}
 
/* Font Sizes */
.Resume-4 .font15 {
    font-size: 15px;
}
 
.Resume-4 .font18 {
    font-size: 22px;
}
 
.Resume-4 .font20 {
    font-size: 20px;
}
 
.Resume-4 .font25 {
    font-size: 24px;
}
 
.Resume-4 .font30 {
    font-size: 35px;
}
 
.Resume-4 .font35 {
    font-size: 35px;
}
 
.Resume-4 .font40 {
    font-size: 40px;
}
 
.Resume-4 .font45 {
    font-size: 45px;
}
 
.Resume-4 .font50 {
    font-size: 50px;
}
 
.Resume-4 .font70 {
    font-size: 70px;
    margin-right: 30px;
    margin-bottom: 50px;
}
 
/* Line Height */
.Resume-4 .lineHeight {
    line-height: 1.7;
}
 
/* Section Titles */
.Resume-4 .secTitle {
    font-size: 1.3rem;
    line-height: 1.6;
    font-weight: 500;
    float: left;
   
}
 
.Resume-4 .subTitle {
    font-size: 25px;
    line-height: 1.6;
    width: 600px;
}
.Resume-4 .subTitle-2 {
    font-size: 28px;
    line-height: 1.6;
    /* width: 400px; */
}
.subTitle-5 {
    font-size: 28px;
    line-height: 1.6;
    width: 350px;  
}
/* Line Height */
.Resume-4 .lineHeight {
    line-height: 1.7;
}
 
 
/* Button Styles */
.Resume-4 .MuiButton-contained {
    letter-spacing: 2px;
    font-size: 14px;
    align-self: center;
    background-image: none;
    text-align: center;
    font-weight: 700;
    color: #fff;
    background-color: #1e48ec;
    margin-bottom: 40px;
    padding: 15px 40px;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #1e48ec;
    border-radius: 10px;
    min-width: 200px;
}
 
.Resume-4 .MuiButton-contained:hover,
.Resume-4 .btnStyle2:hover,
.Resume-4 .btnBorderColor:hover {
    background: #c5058c;
    border: 1px solid #c5058c;
    color: #fff;
}
 
.Resume-4 .btnStyle2 {
    background: #c5058c;
    border: 1px solid #c5058c;
    color: #fff;
    padding: 15px 30px;
}
 
.Resume-4 .btnBorderColor {
    border: 2px solid #f5773d;
    color: #111111;
    padding: 15px 30px;
}
 
.Resume-4 .btnBorderColorWhite {
    border: 2px solid #fff;
    color: #fff;
    padding: 15px 30px;
}
 
.Resume-4 .btnDark {
    border: 1px solid #fff;
}
 
.Resume-4 .btnBLock {
    width: 100%;
}
 
.Resume-4 .btnWidth {
    min-width: auto;
}
 
/* Flexbox */
.Resume-4 .secHeadFlex,
.Resume-4 .secHeadFlexCenter {
    display: flex;
    flex-direction: column;
}
 
.Resume-4 .secHeadFlexCenter {
    align-items: center;
}
 
.Resume-4 .secHeadFlex .subTitle {
    margin-right: 100px;
}
 
/* Image Styles */
.Resume-4 .gallerySec img,
.Resume-4 .ourWorkSec img,
.Resume-4 .clientSec img,
.Resume-4 .serviceSec img,
.Resume-4 .workbg {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
/*  
.Resume-4 .ourWorkSec img {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
    border: 5px solid #f5773d;
} */
 
/* .Resume-4 .clientSec img {
    border-radius: 50%;
    width: 120px;
    border: 4px solid #fff;
} */
/*  
.Resume-4 .studioImg {
    width: 40%;
}
 
.Resume-4 .studioImg1 {
    width: 60%;
} */
 
/* Background Images */
.Resume-4 .aboutStudioBg {
    background-image: url('./assets/cv.png');
    background-position: 50% 50%;
    background-size: cover;
    min-height: 400px;
}
 
.Resume-4 .aboutStudioBg1,
.Resume-4 .workbg1,
.Resume-4 .workbg2,
.Resume-4 .workbg3,
.Resume-4 .workbg4,
.Resume-4 .workbg5,
.Resume-4 .workbg6,
.Resume-4 .workbg7,
.Resume-4 .workbg8 {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url('./assets/bg8.png');
    background-position: 50% 50%;
    background-size: cover;
    min-height: 400px;
    background-repeat: no-repeat;
}
 
.Resume-4 .bgImg {
    background-image: url('./assets/bg.png');
    background-position: 50% 50%;
    background-size: cover;
}
 
.Resume-4 .bannerLeftSec {
    padding-left: 80px;
    padding-top: 50px;
   
}
.resume4-1 {
 display: flex;
    justify-content: space-between;
   
}
/* Containers */
.Resume-4 .ListContainer {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 2.2;
    margin: 0 20px 0 0;
}
 
.Resume-4 .ListContainer span {
    font-weight: bold;
}
 
/* Miscellaneous */
.Resume-4 .circleShape {
    width: 118px;
    height: 118px;
    margin: 50px auto 0 0;
    color: #ffffff;
    background-color: #f28755;
    border-radius: 50%;
}
 
.Resume-4 .imageRight {
    display: block;
    text-align: right;
}
 
.Resume-4 .pinkColor {
    background-color: #ea25c2;
    color: #fff;
}
 
.Resume-4 .bgColor {
    background-color: #ec5711;
}
 
.Resume-4 .height600 {
    min-height: 700px;
}
 
.Resume-4 .formInput {
    display: block;
    width: 93%;
    padding: 15px 12px;
    border-width: 0;
    border-radius: 10px;
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 15px;
    font-size: 18px;
}
 
.Resume-4 .serviceBox {
    display: flex;
    margin-left: 25%;
    width: 100%;
    /* flex-direction: column;
    padding-left: 10px; */
}
.sign-up {
    display: flex;
    flex-direction: column;
}
.Resume-4 .iconImg {
    width: 20px;
    margin-right: 10px;
}
 
.Resume-4 .personsContainer img {
    width: 200px;
    height: 200px;
}
 
.Resume-4 .secNumber {
    font-size: 6rem;
    font-weight: 700;
    color: #ec5711;
    font-family: 'Oswald', sans-serif;
    margin-right: 50px;
}
 
.Resume-4 .setpsHead {
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.Resume-4 .setpsHeadLeft {
    justify-content: flex-start;
}
 
.Resume-4 .paddBottomSteps {
    padding-bottom: 40px;
}
@media (max-width: 992px) {
    .Resume-4 .serviceBox {
        margin-left: 15%;
    }
    .secTitle-3 {
        margin-left: 15%;
    }
    .Resume-4 .bannerimg-2 {
        display: block;
        margin : 5%;
        width: 700px;
        height: 600px;
        border-radius: 10px;
      }
      .Resume-4 .bannerimg {
        display: none;
      }
    .secTop {
        margin-left: 5%;
    }
    .Resume-4 .font25 {
        font-size: 28px;
    }
    .Resume-4 .font30 {
        font-size: 28px;
    }
}
    @media (max-width: 600px) {
        .Resume-4 .bannerimg-2 {
         
            margin: 0 auto;
            width: 300px;
            height: 400px;
         
          }
    .Resume-4 .bannerLeftSec {
        padding-left: 0px;
        margin: 0 auto;
        /* text-align: center; */
        align-items: center;
        /* padding-top: 50px; */
    }
    .Resume-4 .subTitle {
      padding-left: 15%;
    }
    .Resume-4 .pageTitle {
        padding-left: 15%;
    }
    .Resume4-service {
        margin-left: 15%;
    }
    .Resume-4 .darkParaTextWhite-1 {
        width: auto;
        line-height: 1.6;
    }
    .Resume-4 .subTitle-2 {
        font-size: 22px;
        line-height: 1.6;
        width: auto;
       
    }
.Resume-4 .secTitle-3 {
    font-size: 20px;
    line-height: 1.6;
    margin-left: 50px;
    width: auto;
 
}
.Resume-4 .serviceBox {
    display: flex;
    /* flex-direction: column; */
    /* padding-left: 30px; */
    margin-left:0px;
}
.Resume-4 .textCenter {
    /* text-align: center; */
    font-size: 20px;
    width: auto;
    padding-left: 15%;
}
.Resume-4 .font30 {
    font-size: 1.2rem;
    padding-left: 9%;
    font-weight: 600;
 
}
.Resume-4 .font25 {
    font-size: 18px;
    width: 300px;
    font-weight: 600;
    margin: 0px auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
 
}
 
.Resume-4 .font18 {
    font-size: 20px;
    width: 300px;
    margin: 0px auto;
    font-weight: 500;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.secTop {
    margin: 0 auto;
   
}
 
}
@media (max-width: 320px) {
    .Resume-4 .font30 {
        padding-left: 2%;
 
    }
    .Resume-4 .font18 {
        width: 250px;
    }
    .Resume-4 .font25 {
        width: 250px;
    }
}