* {
    margin: 0;
}
 
.bac-1 {
    /* position:fixed; */
    height: 135vh;
    width: 100%;
    background-color: #fffA99;
}
 
.whole-1 {
    border: 1px solid black;
    background-color: #fffA99;
}
 
.bo-1 {
    /* border: 1px solid black; */
    width: 75vh;
 
    background-color: white;
    ;
    margin-left: 70vh;
    margin-top: 10vh;
    border-radius: 20px;
    ;
    height: 124vh
}
 
.log-1 {
    width: 33.3vw;
    margin-top: 1px;
    height: 80px;
    border-radius: 20px;
    ;
    border: 1px solid rgb(227, 221, 221);
}
 
.nto-1 {
    font-size: 25px;
    font-weight: 700;
    margin-left: 10px;
    margin-top: 10px;
}
 
.vi-1 {
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
    color: grey;
    margin-top: 10px;
}
 
.nm-1 {
    border: 1px solid black;
    margin-left: 22vh;
    width: 160px;
    height: 40px;
    margin-top: 20px;
    border-radius: 20px;
    font-size: 20px;
    position: relative;
    right: 20px;
}
 
 
.trik-1 {
    font-size: 15px;
    position: relative;
    top: 5px;
    font-weight: 700;
    left: 15px;
    top: 10px;
}
 
.tor-1 {
    display: flex;
 
}
 
.nae-1 {
    margin-top: 20px;
    padding-top: 20px;
    margin-left: 20px;
    font-size: 20px;
 
}
 
.icon-1 {
    width: 10px;
    color: red;
}
 
.nv-1 {
    display: flex;
    font-weight: 650;
    margin-left: 20px;
    font-size: 18px;
}
 
.last-1 {
    border: 1px solid rgb(228, 225, 225);
    margin-top: 13vh;
    margin-left: 10px;
    width: 32vw;
    height: 80px;
    border-radius: 10px;
    display: flex;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.25);
}
 
 
.five-1 {
    border: 1px solid black;
    height: 40px;
    font-size: 20px;
    font-weight: 500;
    width: 110px;
    border-radius: 10px;
    position: relative;
    top: 20px;
    left: 20px;
}
 
.tik-1 {
    font-size: 15px;
   
}
 
.pay-1 {
    font-size: 18px;
    font-weight: 500;
    margin-left: 28vh;
    margin-top: 15px;
    border: 1px solid black;
    width: 100px;
    border-radius: 5px;
    color: white;
    background-color: black;
    ;
    height: 40px;
    padding-left: 20px;
    padding-top: 10px;
    cursor: pointer;
}
 
.pay-1:hover {
    background-color: rgb(121, 116, 116);
}