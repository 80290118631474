@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Oswald&family=Raleway:wght@700&display=swap');
 
.james-body {
 
    background-color: #E799A3;
    overflow-x: hidden;
}
 
.Skills-header{
  font-size: 0;
}
.james-portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
 
 /* .james-portfolio-content {
  display: flex;
  flex-direction: row;
 
 
  width: 100%;
 
}  */
 
.james-text-content {
  flex: 1;
  color: white;
  padding: 20px;
  gap: 20px;
}
.james-text-content h1{
  flex: 1;
  color: white;
  padding: 20px;
  gap: 30px;
  font-size: 16px;
}
 
 
 
.portfolio-image {
 
  border-radius: 10px;
  width: 400px;
  height: 400px;
}
.Details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
 
 
 
.james-portfolio h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
 
}
 
.james-portfolio p {
  font-size: 1.2em;
  line-height: 1.5;
  margin-top: -20px;
}
 
/* Animation classes */
.animate__animated {
  animation-duration: 1s;
}
 
.animate__fadeInLeft {
  animation-name: fadeInLeft;
}
 
.animate__fadeInRight {
  animation-name: fadeInRight;
}
 
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
 
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/* .u-repeater.u-repeater-3 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
 
.u-repeater-item {
  width: 100%;
}
 
.u-shape-round {
  border-radius: 20px;
  overflow: hidden;
}
 
.u-container-layout {
  text-align: center;
  padding: 20px;
} */
 
 
/* .u-repeater-item {
  width: 100%;
  margin: 10px;
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 314px;
}
 
.u-text {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
} */
.james-profile {
  width: 300px;
  margin: 20px auto;
 
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  height: 537px;
 
  background-color: #571B7E;
  color: #fff;
 
}
 
.james-profile h2 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 10px;
}
 
.james-profile p {
  color: #fff;
  font-size: 14px;
 
  /* padding: 30px; */
  text-align: center;
}
 
.james-profile a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
}
 
.james-profile a:hover {
  text-decoration: underline;
}
 
.james-profile .fa-icon {
  margin-right: 5px;
}
 
 
/* .u-repeater {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
} */
 
.james-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 2 columns */
  grid-template-rows: repeat(2, auto);
  /* 2 rows */
  gap: 10px;
 
  padding: 10px;
  border: none;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10%;
  margin-right: 20%;
}
 
/* .u-repeater-item1 {
  width: 100%;
  margin: 10px;
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
 
.u-repeater-item h3 {
  font-size: 24px;
  margin-top: 10px;
}
 
.u-text-palette-1-base {
  color: #571B7E;
}
 
.u-text-palette-3-base {
  color: #4B0150;
} */
 
/* .text {
  font-size: 29px;
  color: #181819;
  padding: 10px;
  border: none;
  border-radius: 8px;
  max-width: 335px;
  margin: 0 auto;
}
 
.text1 {
  color: #141415;
  padding: 10px;
  border: none;
  border-radius: 8px;
  max-width: 339px;
  margin: 0 auto;
} */
 
.james-resume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  color: #fff;
  font-family: 'Arial', sans-serif;
  margin-top: 100px;
  font-size: 23px;
  height: 700px;
 
}
 
.james-resume div {
  text-align: center;
  margin-top: 0%;
}
 
.james-resume h1 {
  font-size: 30px;
  margin: 10px 0;
  font-family: 'Poppins', sans-serif;
  padding-top: 30%;
  font-size: 29px;
}
 
 
 
.james-resume p {
  font-size: 16px;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}
 
a.james-resumes {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #8B008B;
  color: white;
  text-decoration: none;
  font-size: 1em;
  border-radius: 5px;
  border: 2px solid #ffffff;
}
.skills-para{
  color: #fff;
  font-size: 20px;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
 
a.resumes:hover {
  background-color: #571B7E;
  border-color: white;
}
 
.u-icon-1 {
  vertical-align: middle;
  margin-left: 5px;
}
 
.james-resume ul {
  list-style-type: none;
  font-size: 18px;
}
 
.resumess {
  width: 100%;
  font-size: 18px;
  max-width: 369px;
  max-height: 336px;
  margin-top: 20px;
}
 
.resumess .contain {
  list-style-type: none;
  font-size: 16px;
  /* margin-top: -34px; */
}
 
.resumess .contain li {
  margin-bottom: 10px;
}
 
.u-container-layout1 {
  width: 100%;
  font-size: 16px;
  max-width: 369px;
  max-height: 336px;
}
 
.u-container-layout .u-align-left {
  list-style-type: none;
  font-size: 21px;
  margin-top: -132px;
}
 
/* Media Query for Responsive Design */
@media screen and (min-width: 768px) {
  .james-resume {
    flex-direction: row;
    padding: 15px;
    max-height: 700px;
  }
  .james-resume ul {
    list-style-type: none;
    font-size: 15px;
    padding-top: 10px;
  }
 
 
  .james-resume div {
    text-align: start;
  }
 
  .resumess,
  .u-container-layout1
   {
    margin-left: 22px;
    font-size: 29px;
  }
 
  /* .james-resume h2 {
    text-align: left;
    font-size: 10px;
  } */
  .james-portfolio {
    display: flex;
   flex-direction: column;
    /* padding: 20px; */
  }
  .portfolio-image {
   
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
}
 
 
 
.james-Img img {
  width: 100%;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  display: block;
 
 
  margin: 10% auto;
}
 
.james-header {
  font-size: 3em;
  margin-bottom: 50px;
  text-align: center;
  margin-top: 0;
  color: #A74AC7;
}
 
.james-descrip {
  font-size: 1.2em;
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  /* color: #A74AC7; */
}
 
 
 
@media (min-width: 968px) {
  .james-header {
    font-size: 4em;
    text-align: left;
    margin-left: 20%;
    margin-top: -30%;
  }
 
  .james-descrip {
    font-size: 1.5em;
    text-align: left;
    margin-left: 20%;
    max-width: none;
  }
 
  .james-Img img {
   margin-right: 10%;
   width: 500px;
  }
 
 
 
}
 
@media (min-width: 1024px) {
  .james-header {
    font-size: 5em;
  }
 
  .james-descrip {
    font-size: 1.8em;
  }
 
  .down {
    margin-left: calc(45% + 139px);
  }
}
.james-portfolio {
  background-color: #571B7E;
 /* margin-top: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* padding-top: 10px; */
}
 
/* .james-portfolio-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
 
} */
 
.james-text-content {
  align-items: center;
  margin-bottom: 10px;
}
 
.portfolio-image {
 
  display: flex;
  justify-content: center;
}
 
 
 
 
@media (min-width: 768px) {
  .james-portfolio-content {
    flex-direction: row;
   
    align-items: center;
  }
 
  /* .james-text-content {
    text-align: left;
   
  } */
 
  .portfolio-image {
    width: 50%;
  }
  .Details{
    height: 700px;
   
  }
}
 
@media screen and (min-width: 768px) {
  .james-resume {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
 
  .james-resume h1 {
    margin-top: 0;
  }
 
  .resumess {
    margin-left: 20px;
  }
}
.portfolio-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* margin: 20px; */
}
 
.portfolio-image {
  width: 300px;
  height: 300px;
 
  border-radius: 8px;
}
 
.portfolio-details {
  margin-top: 30px;
  color: #ccc;
}
 
.app-name,
.app-design {
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: 600;
}
 
@media (max-width: 768px) {
  .portfolio-item {
    margin: 10px;
  }
 
  .app-name,
  .app-design {
    font-size: 1em;
  }
  .james-list{
    display: flex;
    flex-direction: column;
  }
 
 
}
 