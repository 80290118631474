
.Management-dashbord {
  width: 100%;
  overflow-x: hidden;
}
 
.Management-section-top {
  display: flex;
  justify-content: space-between;
  background-color: #2196f3;
  width: 100%;
  padding: 15px;
  height: 60px;
  color: #ffffff;
  padding-left: 50px;
  padding-right: 50px;
  position: sticky;
  top: 0; /* Ensure it sticks to the top */
  z-index: 1000; /* Ensure it's above other content */
}
 .Management-section-top-2 {
  display: flex;
  gap:40px;
 }
.Management-search-button input {
  border-radius: 10px;
  width: 300px;
 height: 35px;
 padding-left: 10px;
 margin-left: 500px;
}

.Management-search-button {
  display: flex;
}
 
.Management-section-1 h4 {
  padding-top: 7px;
  font-weight: 700;
  font-size: 24px;
}
 
.Management-Name {
  text-align: center;
  padding-left: 10px;
}
 
.Management-search-icon {
  margin-left: -35px;
  padding-top: 5px;
  color: #000;
}
.add-user button {
  background-color: white;
  color: #000;
  font-weight: 600;
  transition: all 0.9s ease;
  text-wrap: nowrap;
}
.add-user button:hover {
  background-color: rgb(18, 18, 18);
  color: white;
  font-weight: 600;
}
.resourceTableHeader-1 th{
  background-color: rgb(47, 44, 44);
  color: #ffffff;
  font-size: 16px;
  vertical-align: middle;
text-wrap:nowrap;
padding: 0px;
margin: 0px;
}
.totalEmployeePage {
margin-left: 0px;
padding-left: 10px;
}




.resourceTableHeader th:first-child {
  width: auto;
}

.resourceTableHeader th:last-child {
  width: auto;
  padding-left: 10px;

}

.table-hover {
  width: 100%;
  border-collapse: collapse;
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
}

.table-hover td, .table-hover th {
  padding: 14px;
  border-bottom: 1px solid #ddd;
}

.resourceActionIcons {
  width: 150px;
}

.resourceActionIcons i {
  margin-right: 10px;
  cursor: pointer;
}
.employeesPageSearch {
  width: 300px;
}

#employeesPageSearch input[type="search"] {
  height: 40px;
  margin-top: 5px;
}
.employeesPageDropdowns{
  margin: 10px 0px;
}
#basic-addon2{
height: 40px;
margin-top: 5px;
}


.popup {
position: fixed;
top: 55%;
left: 50%;
transform: translate(-50%, -50%);
background-color: #fff;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
border-radius: 5px;
padding: 0px 20px ;
z-index: 1000;
}

.popup-inner {
max-width: 400px;
margin: 0 auto;
}

.popup h4 {
margin-top: 5px;
font-size: 20px;

font-weight: 700;
}

.popup form div {
margin-bottom: 10px;
display: flex;
align-items: center;
font-size: 16px;
}

.popup form label {
font-weight: 500;
margin-right: 10px; 
}

.popup form input {
flex: 1; 
padding: 8px;
border: 1px solid #ccc;
border-radius: 5px;
}

.edit-popup-buttons {
display: flex;
justify-content: space-between;
margin-top: 1px;
}

.popup-inner input {
height: 30px;
}

.edit-popup-buttons button {
padding: 3px 10px;
cursor: pointer;
border: none;
border-radius: 5px;
color: #fff;
font-weight: bold;
}

.edit-popup-buttons button:first-child {
background-color: #007bff;
}

.edit-popup-buttons button:last-child {
background-color: #dc3545;
}

.overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.574);
z-index: 999;
}
.view-popup{
padding: 10px;
}
.view-popup p{
height: 10px;
font-size: 15px;
}
.view-popup h3{
font-size: 22px;
font-weight: 700;
}
.view-popup strong{
font-weight: 500;
font-size: small;
padding-right: 10px;
}
.view-popup button {
border-radius: 5px;
padding: 0px 5px;
background-color: gray;
}

@media screen and (max-width: 1280px) {
.Management-search-button input {
 margin-left: auto;
 width:200px ;
}
}
@media screen and (max-width: 850px) {
.Management-search-button input {
 margin-left: auto;
 width:200px ;
}
.Management-section-top {
 
  padding-left: 20px;
  padding-right: 20px;
 
}
}
@media screen and (max-width: 769px) {

/* 
.Management-section-top {
  display: flex;
  justify-content: space-between;
  background-color: #2196f3;
  width: 100%;
  padding: 15px;
  height: 60px;
  color: #ffffff;
  padding-left: 50px;
  padding-right: 50px;
  position: sticky;
  top: 0; 
  z-index:2;
} */
/* .table-cell {
width:auto;
font-size: 22px;
font-weight: 500;
padding-left: 10px;
padding-right: 10px;
z-index: 1;
}
.TableHead {
font-size: 18px;
font-weight: bold;
background-color: #f0f0f0;
color: #333;
} */
}

@media screen and (max-width: 576px) {
.Management-dashbord {
  width: 100%;
  overflow-x: hidden;
}
.Management-section-top {
  display: flex;
  justify-content: space-between;
  background-color: #2196f3;
  width: 100%;
  padding: 15px;
  height: auto;
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  position: sticky;
  top: 0; /* Ensure it sticks to the top */
  z-index: 1; /* Ensure it's above other content */
}
.Management-section-top-2 {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  gap:15px
 }
.Management-search-button input {
  border-radius: 10px;
  width: 100px;
 height: 35px;
 padding-left: 10px;
 margin-left: 0px;
}

.Management-search-button {
  display: flex;
}
 
.Management-section-1 h4 {
  padding-top: 15px;
  font-weight: 300;
  font-size: 14px;
}


th:nth-child(1),
td:nth-child(1) {
left: 0;
}
th:nth-child(1),
td:nth-child(1){
position: sticky;
/* background-color: white; */
z-index: 5;
}

.something{
position: sticky;
z-index: 6;
}
}