.forget {
    background-image: url('./assets/login-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
.forget .forgetpass {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
}
 
.forget .login__form {
    position: relative;
    margin-inline: 1.5rem;
    background-color: hsla(0, 0%, 100%, .01);
    border: 2px solid hsla(0, 0%, 100%, .7);
    padding: 2.5rem 1rem;
    color: var(--white-color);
    border-radius: 1rem;
    backdrop-filter: blur(16px);
}
 
.forget .login__box {
    grid-template-columns: 1fr max-content;
    column-gap: .75rem;
    align-items: center;
    border: 2px solid hsla(0, 0%, 100%, .7);
    padding-inline: 1.25rem;
    border-radius: 4rem;
    margin: 0 auto;
    margin-top: 20px;
}
 
.forget .forget-enter {
    border-radius: 25px;
    padding: 10px;
    margin: 0 auto;
    margin-top: 25px;
    border: 0px;
}
 
.forget .forget-container {
    display: flex;
    justify-content: center;
}
/* .login__input {
    width: 100%;
    background-color: transparent;
    color: white;
    padding-block: 1rem;
    border: 1px;
} */