.webinar-service .title {
    display: flex;
}
.webinar-service #backarrow {
    margin-top: -50px;
    margin-right: 10px;
    font-size: 30px;
}
.webinar-service .title h1 {
    font-size: 34px;
}
.webinar-service .boxs .nav-link {
    display: flex;
    align-items: center;
    border-radius: 0 !important;
    height: auto !important;
    /* padding-left: 3% !important; */
}
.webinar-service .call-Box {
        display: flex;
        flex-direction: column;
        /* align-items: center; 
        text-align: center;  */
        float: left;
        padding-top: 10px;  
}
.webinar-service #Heart {
    font-size: 20px;
}
.webinar-service .call-Box h5 {
    padding-top: 8px;
}
.webinar-service .call-Box p {
    width: 170px;
    font-size: 14px;
    min-height: 40px;
}

.webinar-service .call-service {
    display: flex;
    flex-direction: column;
}

.webinar-service .call-service label {
    margin-bottom: 10px; /* Add spacing below the label */
}
.webinar-service .call-service input {
    /* width: 100%; */
    width: 550px;
    padding: 5px;
    border-radius: 10px;
}
.webinar-service .call-Duration {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.webinar-service .call-Duration input {
    /* width: 100%; */
    width: 550px;
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px; 
}
.webinar-service .call-Amount {
    display: flex;
    flex-direction: column;
    margin-top: 10px;  
}
.webinar-service .call-Amount .money {
    display: flex;
    margin-top: 15px; 
}
.webinar-service .call-Amount .Rupe-sym {
    border: 1px solid;
    width: 30px;
    text-align: center;
    padding-top: 5px;
    font-weight: 600;
    border-radius: 10px 0px 0px 10px ;
}
.webinar-service .call-Amount input {
    /* width: 100%; */
    width: 520px;
    padding: 5px;
    border-radius: 0px 10px 10px 0px
}
.webinar-service #Customize {
   
    background-color: black;
    color: white;
    width: 100%;
    margin-top: 30px; 
    border: none; 
    padding: 10px;
}
.webinar-service #Customize-1 {
    background-color: black;
    color: white;
    width: 100%;
    margin-top: 30px; 
    border: none; 
    padding: 10px;
}
.webinar-service #Customize-1:hover {
    background-color: rgb(56, 54, 54);
}
.webinar-service #Customize:hover {
    background-color: rgb(56, 54, 54);
}
.webinar-service .webinar-session {
    margin-top:15px; 
}
.webinar-service .Session {
  display: flex;  
  margin-top:10px;
}
.webinar-service .Session-2 {
    margin-left: 10px;
}
.webinar-service .Session input {
    /* width: 100%; */
    width: 270px;
    padding: 5px;
    border-radius:10px;
}
.webinar-service #multiple-sessions {
    padding: 10px;
    border-radius: 10px;
    margin-top: 35px;
    background-color:whitesmoke;
    color: black;
    border: black 1px solid;
    

}
.webinar-service #multiple-sessions:hover {
    background-color: rgb(196, 196, 192);
}

  
.webinar-service .hosting {
    display: flex;
    flex-direction: column;
}
.webinar-service .hosting label {
    margin-top: 20px;
}
.webinar-service .hosting button {
    margin-top: 15px; 
    width: 35%;
    padding: 10px; 
   
    border-radius: 10px;
  
    background-color:whitesmoke;
    color: black;
    border: black 1px solid;
}
.webinar-service .hosting button:hover {
    background-color: rgb(196, 196, 192);
} 

.webinar-popup .First-Session {
display: flex;  
margin-top:15px;
gap:10px;
}
.webinar-popup .First-Session input {
    width: 230px;
    padding: 5px;
    border-radius:10px;
}
.webinar-popup .webinar-First-session label {
    font-weight: 500;
}

.webinar-popup .week label {
    margin-top: 15px;
} 
.webinar-popup .weekdays {
    margin-top: 7px;
    display: flex;
}
.webinar-popup .day-button {
    background-color: white;
    color: #333; 
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border-radius: 50px;
    width:80px;
    height: 35px;
    padding-top: 3px;
  }
   
  .webinar-popup .day-button.selected {
    background-color: black; 
    color: white;

  }
  .webinar-popup .dropup  {
    width: 150px;
   border: 1px solid black;
    color: #333;
    margin-top: 15px;
  }
  .webinar-popup .Session-3 {
    display: flex;
    flex-direction: column;  
  }
  .webinar-popup .Session-3 label {
    margin-top: 10px;
}
.webinar-popup .Session-3 input {
    margin-top: 10px;
    width: 470px;
    padding: 5px;
    border-radius: 10px;
}
  .webinar-popup .Total-Sessions {
    display: flex;
    flex-direction: column;
}

.webinar-popup .Total-Sessions label {
    margin-top: 10px;
}
.webinar-popup .Total-Sessions input {
    margin-top: 10px;
    width: 470px;
    padding: 5px;
    border-radius: 10px;
}

.webinar-popup .Create-add {
    background-color: black;
    color: white;
    width: 100%;
    padding: 8px;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: 500;
}
.webinar-popup .Create-add:hover {
background-color: rgb(56, 54, 54);
}

.webinar-popup .hr-line {
    width: 100%;
    margin: 20px auto; /* Center the line */
    border-top: 1px solid rgb(7, 0, 0); /* Solid white line */
}

@media (max-width: 540px) {
    .webinar-service input {
        width: 350px !important ;
       
      }

    .webinar-service .call-Box p {
    width: 110px;
    height: 50px;
    }

    .webinar-service  .webinar-session .Session {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .webinar-popup input {
        width: 300px !important ;
    }
    .webinar-popup .day-button {
       
        margin: 1px;
      }
   
    .webinar-service .Session-2 {
        margin-left: 0px;
    }
    .webinar-service .hosting button {
        width: 50%;
    }
    .webinar-popup  .First-Session {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .webinar-service  .call-Amount input{
        width: 320px !important;
    }

    } 

    /* @media (max-width: 376px) {
        .webinar-service .boxs {
            margin-left: 0px;
        }
        .webinar-service {
            margin-left: 15px;
        }
        .webinar-service #backarrow {
            margin-top: -80px;
           
        }
    } */

    @media (max-width: 450px) {
        .webinar-service #backarrow {
            margin-top: -100px;
           
        }
        .webinar-service input {
            width: auto !important;
          }
    
        .webinar-popup input {
            width: auto !important;
        }
        .weekdays {
            display: flex;
            flex-wrap: wrap;
            width: auto;
          }
          
          .webinar-popup .day-button {
            width: calc(50% - 10px); 
            margin: 5px;
          }
        .webinar-service .Session-2 {
            margin-left: 0px;
        }
        .webinar-service #multiple-sessions {
            padding: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .webinar-service .hosting button {
            width: 90%;
        }
        .webinar-popup  .First-Session {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
        }
        .webinar-service  .call-Amount input{
            width: auto !important;
        }
    }
    @media (max-width: 320px) {
.call-1 form {
    padding-left: 40px;
}
.Webinar-1  form {
    padding-left: 40px;
}
        .webinar-service .title h1 {
            font-size: 26px;
        }
        .webinar-service #backarrow {
            margin-top: -50px;
            
        }
  
    }