.Default {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
}
.Default-set {
/* width: 100%; */
margin: 10px;
display: flex;
padding: 20px;
box-sizing: border-box;
justify-content: space-between;
text-align: center;
background: linear-gradient(175deg,rgb(87, 86, 86) 60%,rgb(172, 169, 169)100%);
color: white;
height: 100vh;

}

  .defalt-data strong {
      display: block;
      width: 600px;
    }

.default-image img {
  border-radius: 300px;
  margin-bottom: 50px;
  width: 400px;
  height: 400px;
  margin: 50px;
  margin-top: 10%;
}
.default-image h1 {
  font-weight: bolder;
  letter-spacing:8px;
  font-style:oblique;
}
.defalt-data {
  width: 50%;
  padding-top: 5%;
}

.defalt-data form {
  margin-bottom: 20px;
  width: 50%;
}

.defalt-data label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 600;

}

.defalt-data input {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 0px;
  padding-left: 14px;
  box-shadow: 0 0 5px black;
 

}

.creator-table {
  width: 100%;
  border-collapse: collapse;
}

.creator-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.view-service {
  float: left;
  border-radius: 20px;
}



@media (max-width: 890px) {
.Default {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 160vh; */
}
  .Default-set {
      /* width: 100%; */
        margin: 0px;
        display: flex;
        flex-direction: column;
        padding: 0px;
       height: auto;
       padding-bottom: 80px;
      }
      .defalt-data {
          width: 100%;
          padding-top: 5%;
        }
       
        .defalt-data form {
          margin-bottom: 20px;
          width: 70%;
          margin: 0 auto;
        }
        .view-service {
          display: block; /* Change to block for full-width centering */
          margin: 0 auto;
          align-items: center;
        }
        .default-image img {
          border-radius: 300px;
          margin-bottom: 50px;
          width: 250px;
          height: 250px;
          margin: auto;
          margin-top: 10%;
          margin-bottom: 10%;
      }
     
}
