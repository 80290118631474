.chart-wrapper {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    max-height: 70%;
    max-width: 500px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chart-container {
    width: 350px;
    height: 600px; 
    max-width: 100%;
    margin: 0 auto; 
    padding-top: 18%;
  }
  
@media (max-width: 998px) {
  .chart-wrapper {
    max-width: 60%;
    padding: 15px;
  }

  .chart-container {
    width: 500px;
    height: 500px;
   
  }
}


@media (max-width: 480px) {
  .chart-wrapper {
    max-width: 100%;
    padding: 10px;
  }

  .chart-container {
    width: 100%;
    height: auto;
    padding-top: 10%;
  }
}
 