.multi-step-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.form { 
  width: 750px;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-step {
  margin-bottom: 20px;
}

.form-step button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

.form-step button:hover {
  background-color: #2980b9;
}

.form-step label {
  margin-top: 10px;
  display: block; /* Ensures each label is on a new line */
}

.form-step input,
.form-step textarea {
  width: calc(100% - 30px); /* Adjusted width for input fields */
  background: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 10px; /* Adjusted margin for consistency */
  /* box-shadow: #d5e4c6 0px 0px 10px 5px; */
  border-inline: 10px solid transparent;
}

.form-step textarea {
  resize: vertical; /* Allow vertical resizing of textarea */
}

.form-step img {
  width: 100%; /* Make the image take 100% width of its container */
  max-width: 800px; /* Ensure the image doesn't exceed its natural size */
}

/* Add responsive styles for smaller screens */
@media screen and (max-width: 600px) {
  .form {
    width: 100%;
  }
}
