.pages{
    padding: 15px;
}

.preview-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 40px;
   
    justify-content: space-between;
}

.preview-box{
   
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0 0 10px rgb(88, 78, 78);
    border-radius: 15px;
    
}

.preview-box:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }

.preview-img{
    width: 280px;
    height: 150px;
}

.view{
    text-decoration: none;
    margin-bottom: 6px;
    color: white;
    border: 2px solid black;
    padding: 4px 15px;
    border-radius: 20px;
    background-color: black;
    cursor: pointer;
}

.usethis{
    text-decoration: none;
    color: black;
    margin-bottom: 6px;
    color: white;
    padding: 4px 15px;
    border-radius: 20px;
    background-color: black;
    cursor: pointer;

}


.links{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;
}