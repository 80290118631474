.landingpage{
 
    display: flex;
    flex-direction: column;
}
 
.top-bar{
    position: fixed;
    width: 100%;
    background-color: black;
    z-index: 4;
}

.topmate-logo{
    width: 200px;
}

.nav-heads{
    font-weight: bold;
    color: white;
}

.navbar .nav-link {
    color: white;
}

.navbar-nav .nav-link.active {
    color: white; 
  }


.ai-bar{
    border: none;
 
    background-color: black;
    gap: 10px;
    border-radius: 20px;
}
 
.Software-1 {
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 150px;
    background: linear-gradient(138deg, #eeeeee 65%, rgb(75, 75, 75) 35%);
    overflow-x: hidden;
 }

.Software-1-block {
    display: flex;
    justify-content:space-around
   
}
.Software-1-textbox {
  margin-top: 20px;  
}
.handsomeSoftware img {
    width: 400px;
    height: 500px;
    margin-top: -50px;
  }
.Software-1 p {
    padding-top: 25px;
    font-size: 20px;
    line-height: 40px;
    width: 90%;
    text-align: center;
    font-weight: 600;
    margin: 0 auto; 
}

.Software-1 .you-1 {
    width: 770px;
   border: 3px solid black;
   border-radius: 20px;
   margin-bottom: 50px;
   line-height: 60px;
   align-items: center;
   text-align: center;
   padding-top: 10px;
   font-size: 54px;
    font-weight: 700;
} 
.Software-1 #Sign {
 color: white;
 background-color: black;
 width: 60%;
 height: 50px;
 border-radius: 40px;
 font-weight: 600;
 transition: background-color 1s, color 2s;
}
.Software-1 #Sign:hover{
    background-color: rgb(48, 48, 48);
    color: #d4cece;

}
.Software-1 .name-plate {
  background-color: #eeeeee;
font-size: 24px;
font-weight: 700;
  text-align: center;
  border-radius: 30px 0px 30px 0;
  width: 350px;
  height: 34px;
  margin-left: 30px;
 color: rgb(20, 18, 18);
  position: relative;
  margin-bottom: 5px;

}


@media (max-width: 1040px) and (min-width: 740px) {
     
.Software-1 {
    overflow-x: hidden;
    padding-top: 100px;
    background: linear-gradient(138deg, #eeeeee 65%, rgb(75, 75, 75) 35%);
 }

    .Software-1-textbox {
        margin-top: 10px; 
        padding-top: 20px;
      }
      .handsomeSoftware img {
          width: 300px;
          height: 400px;
          margin-top: 30px;
         
        }
      .Software-1 p {
         
          line-height: 30px;
        
      }
      
      .Software-1 .you-1 {
          width: 590px;
         line-height: 55px;
       
         font-size: 44px;
          font-weight: 650;
      } 
      .Software-1 #Sign {
      
       width: 60%;
       height: 50px;
      

      }
      
      .Software-1 .name-plate {
        background-color: #eeeeee;
      font-size: 24px;
        width: 300px;
        height: 34px;
        margin-left: 0px;
        margin-bottom: 5px;
      
      }
      .notePad .begin {
        padding-left: 50px;
        padding-right: -20px;
        }
}

@media (max-width: 900px) and (min-width: 740px) {
    .Software-1-textbox {
       
        margin-left: 10px;
      }
      .handsomeSoftware img {
          width: 280px;
          height: 400px;
          margin-top: 30px;

        }
      .Software-1 p {
         
          line-height: 35px;
        
      }
      
      .Software-1 .you-1 {
          width: 500px;
         line-height: 55px;

      }
      .Software-1 .name-plate {
        background-color: #eeeeee;
      font-size: 24px;
     
        width: 250px;
        height: 34px;
        margin-left: 2px;
      
        margin-bottom: 5px;
      
      }
}

@media (max-width: 540px) and (min-width: 270px) {
    
    .Software-1-block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Software-1-textbox {
        margin-top: 0px;
        text-align: center; 
    }

    .handsomeSoftware img {
        width: 100%; 
       height: 400px;
        margin-top: 10px;
    }

    .Software-1 p {
        padding-top: 25px;
        font-size: 4vw; 
        line-height: 1.5;
        width: 90%;
        text-align: center;
        font-weight: 600;
        margin: 0 auto; 
    }
    
    .Software-1 .you-1 {
        width: 100%;
        max-width: 770px; 
        border: 3px;
        border-radius: 20px;
        margin-bottom: 20px; 
        line-height: 1.2; 
        align-items: center;
        text-align: center;
        padding-top: 0px;
        font-size: 6vw;
        font-weight: 700;
        margin-top: -10%;
    } 
    
    .Software-1 #Sign {
        width: 60%;
        max-width: 300px;
        height: 8vw; 
        border-radius: 40px;
        font-weight: 600;
        transition: background-color 1s, color 2s;
    }

    .Software-1 #Sign:hover {
        background-color: rgb(48, 48, 48);
        color: #d4cece;
    }

    .Software-1 .name-plate {
        background-color: #eeeeee;
        font-size: 3vw;
        font-weight: 700;
        text-align: center;
        width: 90%; 
        max-width: 350px; 
        height: 4vw;
        margin-left: 5vw;
        color: rgb(20, 18, 18);
        position: relative;
        margin-bottom: 5px;
    }
}



/* /screen-2 */
.Software-Screen-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* min-height: 80vh; */
    overflow-x: hidden;
    margin-bottom: 0%;
    padding-bottom: 5%;
}
.Software-Screen-2 h1 {
    font-size: 54px;
    line-height: 70px;
    font-weight: 700;
    padding-top: 6.5%;
    width: 80%;

}
.Software-Screen-2 .should {
    justify-content: center !important;
    margin-top: 5%;
    display: flex;
    width: 250px;
    column-gap: 40px;
   
}

.Software-Screen-2 #notepad {
    font-size: 84px;
    margin-top: 15px;
    margin-right: 20px;
    color: #ffffff;
   
  }

.Software-Screen-2  #dimand {
    font-size: 100px;
    margin-top: 15px;
    margin-right: 5px;
    color: #ffffff;
}
 
.Software-Screen-2 #world {
    font-size: 100px;
    margin-top: 15px;
    margin-right: 5px;
    color: #ffffff;
  }

.Software-Screen-2 .should .begin {
    display: flex;
    padding-top: 0px;
    justify-content: center;
    gap: 10px;
    width: 250px;
    min-height: 110px;
    background-color: rgb(75, 75, 75); 
   
}


.Software-Screen-2 .should .notePad {
    background-color: rgb(242, 245, 245);
    width: 250px;
    margin: 0 auto;
    transition: transform 0.9s;
}
.Software-Screen-2 .should .notePad:hover {
    transform: scale(1.1);
}
.Software-Screen-2 .should  h2 {
    font-size: 24px;
    padding-top: 35px;
    color: #ffffff;
}

.Software-Screen-2 .should p {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: 500;
    width: 95%;
    line-height: 1.5;
    text-align: left;
    padding-left: 20px;
  
}

.Software-Screen-2 .should .connect {
    background-color: rgb(245, 247, 247);
    transition: transform 0.9s;
    width: 250px;
    margin: 0 auto;
}
.Software-Screen-2 .should .connect:hover {
transform: scale(1.1); 
}
.Software-Screen-2 .should .connect h2 { 
    padding-left: 10px;
}

.Software-Screen-2 .should .up-skill {
    background-color: rgb(245, 247, 247);
    transition: transform 0.9s;
    width: 250px;
    margin: 0 auto;
}
.Software-Screen-2 .should .up-skill:hover {
    transform: scale(1.1); 
    }
.Software-Screen-2 .should .up-skill h2 { 
    padding-left: 10px;
}
@media screen and (max-width: 1040px) {
    .Software-Screen-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: auto;
        overflow-x: hidden;
        margin-bottom: 0;
        padding-bottom: 5%;
    }
}


@media (min-width: 700px) and (max-width: 900px) {
    .Software-Screen-2 {
        overflow-x: hidden !important;
    }
    .Software-Screen-2  .should {
        justify-content: center;
        margin-top: 5%;
        display: flex;
        width: 250px;
        column-gap: 10px;
    }
    .Software-Screen-2 h1 {
        font-size: 44px;
        line-height: 60px;
        font-weight: 700;
        padding-top: 6.5%;
        width: 80%;
    
    }
    .Software-Screen-2 .should .notePad {
        background-color: rgb(242, 245, 245);
        width: 220px;
        margin: 0 auto;
    }
    .Software-Screen-2 .should .notePad h2 {
        width: 300px;
       margin-left: -30px;
       padding-top: 30px;
    }
    .Software-Screen-2 .should .connect {
        background-color: rgb(245, 247, 247);
        transition: transform 0.3s;
        width: 220px;
    }
    .Software-Screen-2 .should .up-skill {
        width: 220px;
    }
    .Software-Screen-2 .should .begin {
        width: 220px;
        height: 130px;
    }
    .Software-Screen-2 .should  h2 {
        font-size: 24px;
        padding-top: 15px;
        color: #ffffff;
        
    }
    
}
@media (max-width: 540px) and (min-width: 270px) {
    .Software-Screen-2  .should { 
        flex-direction: column;
    }
    .Software-Screen-2 h1 {
        font-size: 6vw;
        line-height: 1.2; 
        font-weight: 700;
        padding-top: 6.5%;
        width: 100%;
        max-width: 770px; 
    }
    .Software-Screen-2 .should {
        row-gap: 40px; 
    }
    .Software-Screen-2 .should p {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 20px;
        font-weight: 500;
        width: 95%;
        line-height: 1.5;
        text-align: left;
        padding-left: 20px;
      
    }
}

 .Software-Screen-3 {
    max-width: 100%;
    
    background-image: linear-gradient(
        to bottom,
        #31424d 0%,
        #0a171e 90%,
        #ffffff 90%,
        #ffffff 100%
    );
    color: #ffffff;
   overflow-x: hidden;
}

.Software-Screen-3 .carly {
    display: flex;
    justify-content: center;
    padding-top: 5%;
    column-gap: 100px;
    /* background-color: antiquewhite; */
    /* padding-bottom: 8%; */
}
.Software-Screen-3 .carly .Love {
    /* background-color: #8960f6; */
    width: 600px;
    padding-top: 5%;
    padding-bottom: 0px;
}
.Software-Screen-3 .Love h2 {
    font-size: 32px;
    /* font-size: 54px; */
    font-weight: 700;
    line-height: 42px;
}
.Software-Screen-3 .Love h3 {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 700;
       
}
.Software-Screen-3 .Love p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    width: 120px;
}
.Software-Screen-3 .Love h5 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    width: 150px;
    padding-bottom: 25px;
}
.Software-Screen-3 .Love a {
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    padding-bottom: 0px;
   
}


.Software-Screen-3 #girl {
    width: 400px;
    height: 550px;
    margin-top: 5px;
}
@media (min-width: 700px) and (max-width: 1024px) {
        
.Software-Screen-3 .carly {
    display: flex;
    justify-content: center;
    padding-top: 0%;
    column-gap: 40px;

}
.Software-Screen-3 .carly .Love {
    width: 500px;
        padding-top: 6%;
        padding-bottom: 100px;
        padding-left: 5%;
}
.Software-Screen-3 .Love h2 {
    font-size: 32px;
   
    font-weight: 700;
    line-height: 42px;
}
.Software-Screen-3 .Love h3 {
    padding-top: 30px;
    font-size: 18px;
    font-weight: 700;
       
}
.Software-Screen-3 .Love p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    width: 120px;
}
    
.Software-Screen-3 #girl {
        width: 300px;
        height: 500px;
        /* padding-top: 60px; */
        border-radius: 0px;
        margin-top: 50px;
}
    
.Software-Screen-3 h1 {
        font-size: 46px;
        line-height: 50px;
        padding-top: 2%;
}
}
@media (max-width: 540px) and (min-width: 270px) {
    .Software-Screen-3 .carly {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; /* Center items horizontally */
        padding-top: 0%;
        padding-bottom: 90px;
        column-gap: 40px;
    }

    .Software-Screen-3 #girl { 
        order: 1;
        width: 100%;
        height: auto;
        margin: 0 auto; /* Center horizontally */
        margin-top: 50px;
        max-width: 300px; /* Limit maximum width */
        align-self: center; /* Center the image horizontally */
    }
    

    .Software-Screen-3 .carly .Love {
        order: 2;
        padding-top: 4%;
        margin-bottom: 10%;
        text-align: center; /* Center text horizontally */
    }

    .Software-Screen-3 .Love h2 {
        font-size: 6vw;
        line-height: 1.4; 
        font-weight: 580;
        padding-top: 6.5%;
        width: 70%;
        max-width: 770px; 
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 auto; 
    }

    .Software-Screen-3 .Love h3 {
        padding-top: 20px;
        font-size: 16px;
        width: 100%;
        padding-left: 0; /* Reset padding */
    }

    .Software-Screen-3 .Love p {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        padding-top: 10px;
        padding-left: 0; /* Reset padding */
    }

    .Software-Screen-3 .Love h5 {
        padding-top: 0px;
        font-size: 14px;
        width: 100%;
        padding-left: 0;
    }

    .Software-Screen-3 .Love a {
        float: none; /* Reset float */
        width: 100%;
    }
}
@media (max-width: 320px) {
    .Software-Screen-3 .Love h2 {
        width: 300px;
        font-size: 5.5vw;
        padding-left: 10px; 
        padding-right: 10px;
    }
}
@media (max-width: 280px) {
    .Software-Screen-3 .Love h2 {
        width: 270px;

    }
}

.Software-Screen-4 {
    max-width: 100%;
    background-color: #ffffff;
    padding-bottom: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: auto;
}
.Software-Screen-4 h1 {
    font-size: 54px;
    font-weight: 700;
    padding-top: 4%;
    margin: 0%;
    line-height: 70px;
    text-align: center;
   
 }

 .Software-Screen-4 .container-3 {
    position:relative; 
    width:100%;
    display: flex;
    justify-content: center;
   
    flex-wrap: wrap;
    margin-top: 4%;
    margin-bottom: 6%;
   
}

.Software-Screen-4 .container-3 .mycard {
    position: relative;
    max-width: 300px;
    height: 190px;
    margin: 60px 25px;
    display: flex;
    flex-direction: column;
    transition: 0.4s ease-in-out;
    justify-content: center;
    align-items: center;
    
}
.Software-Screen-4 .container-3 .mycard:hover {
    height: 500px;
    background-color: #f4f0f0;
}

.Software-Screen-4 .container-3 .mycard .card-img {
  position: absolute;
    width: 300px;
    height: 200px;
    top: 0;
    left: 0;
    z-index: 1;
}

.Software-Screen-4 .container-3 .mycard .card-img img {
    max-width: 100%;
    /* height: 100%; */
    border-radius: 6px;
    
}

.Software-Screen-4 .container-3 .mycard .card-detail {
    position: relative;
    margin-top: -140px;
    padding: 10px 15px;
    text-align: center;
    color: black;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.Software-Screen-4 .container-3 .mycard:hover .card-detail {
    visibility: visible;
    opacity: 1;
    margin-top:50%;
}
.Software-Screen-4 .container-3 .mycard  .content p {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
    color: #414141;
   font-size: 18px;
   margin-bottom: 20px;
  
 }
 
 .Software-Screen-4 .container-3 .mycard .content h3 {
    font-size: 22px;
    font-weight: 550;
    line-height: 35px;
    width: 100%;
    float: left;
    
 } 
 .Software-Screen-4 .container-3 .mycard  .content a { 
    color: #faecec;
   font-size: 18px;
   background-color: rgb(241, 45, 45);
   padding: 10px;
   border-radius: 40px;
   padding-left: 20px;
   padding-top: 10px;
   padding-right: 15px;
   transition: all 0.9s ease;
 }
 .Software-Screen-4 .container-3 .mycard  .content a:hover {  
    background-color: #0a171e;
 }



    @media screen and (min-width: 768px) and (max-width: 1024px) {

        .Software-Screen-4 .container-3 {
            position:relative; 
            width:100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 4%;
            margin-bottom: 6%;
           
        }
        .Software-Screen-4  .container-3 .mycard {
            position: relative;
            max-width: 220px;
            height: 150px;
            margin: 60px 5px;
            display: flex;
            flex-direction: column;
            transition: 0.4s ease-in-out;
            justify-content: center;
            align-items: center;
            
        }
        
    .Software-Screen-4 .container-3 .mycard .card-img {
        position: absolute;
          width: 220px;
          height: 200px;
          top: 0;
          left: 0;
          z-index: 1;
      }
      
      .Software-Screen-4 .container-3 .mycard .card-img img {
          max-width: 100%;
          height: 100%;
          border-radius: 6px;
          
      }
      
      .Software-Screen-4 .container-3 .mycard .card-detail {
          position: relative;
          margin-top: -140px;
          padding: 10px 15px;
          text-align: center;
          color: black;
          visibility: hidden;
          opacity: 0;
          transition: 0.4s ease-in-out;
      }
      
    }
    
@media screen and (min-width: 280px) and (max-width: 540px) {
    .Software-Screen-4 {
        max-width: 100%;
        background-color: #ffffff;
        padding-bottom: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-height: auto;
    }
    .Software-Screen-4 h1 {
        font-size: 7vw;
        line-height: 1.2; 
        font-weight: 700;
        padding-top: 1.5%;
        width: 100%;
        max-width: 770px; 
        margin-top: -20%; 
     }
    .Software-Screen-4 .container-3{
        flex-direction: column;
        grid-template-columns: auto;
        position:relative; 
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        
         margin-bottom: 0%;

    }  
    
    
    .Software-Screen-4  .container-3 .mycard {
        position: relative;
        max-width: 250px;
        height: 190px;
        margin: 30px 25px;
        display: flex;
        flex-direction: column;
        transition: 0.4s ease-in-out;
        justify-content: center;
        align-items: center;
        
    }
    
.Software-Screen-4 .container-3 .mycard .card-img {
    position: absolute;
      width: 250px;
      height: 200px;
      top: 0;
      left: 0;
      z-index: 1;
  }
  
  .Software-Screen-4 .container-3 .mycard .card-img img {
      max-width: 100%;
      height: 100%;
      border-radius: 6px;
      
  }
  
  .Software-Screen-4 .container-3 .mycard .card-detail {
      position: relative;
      margin-top: -140px;
      padding: 10px 15px;
      text-align: center;
      color: black;
      visibility: hidden;
      opacity: 0;
      transition: 0.4s ease-in-out;
      /* margin-bottom: -100px; */
  }
}
  .Software-Screen-4 #join {
    width: 100%;
    height: 60px;
    margin: auto; 
padding-left: 30px;
padding-right: 30px;
background-color: #141414;
color: #ffffff;
font-size: 24px;
margin-top: 50px;
    align-items: center;
    justify-content: center;
    transition: background-color 1s, color 2s;
    border-radius: 40px;
}
.Software-Screen-4 #join:hover{
    background-color: rgb(48, 48, 48);
    color: #d4cece;

}


  
/* screen-5 */
 
.Software-Screen-5 .spreading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: rgba(36, 55, 70, 0.6); */
    background-color:  rgb(66,84,98);
    padding-top: 50px;
    padding-bottom: 50px;
}

.Software-Screen-5 .spreading h2 {
    font-size: 54px;
    line-height: 65px;
    font-weight: 700;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
   color: white;
} 

.Software-Screen-5 .numbers {
    display: flex;
    justify-content: center;
  
}
.Software-Screen-5 .numbers p {
  
}
.Software-Screen-5 .block-1 {
    background-color: #DBDBDB;
    width: 100vw;
    color: #243746;
    font-size: 22px;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 50px; 
}
.Software-Screen-5 .block-2 {
    background-color: #D3D3D3;
    color:rgb(66,84,98);
    width: 100vw;
    font-size: 22px;
    text-align: center; 
    padding-top: 100px;
    padding-bottom: 50px; 
}
.Software-Screen-5 .block-3 {
    background-color: #DBDBDB;
    width: 100vw;
    color: #243746;
    font-size: 22px;
    text-align: center; 
    padding-top: 100px;
    padding-bottom: 50px; 
}
.Software-Screen-5 .block-1 h1,
.Software-Screen-5 .block-2 h1,
.Software-Screen-5 .block-3 h1 {
    font-size: 80px;
    font-weight: bolder;
    text-align: center; /* Center align the text */
}





 
@media (min-width:760px) and (max-width: 1040px) {
    .Software-Screen-5 .spreading h2 {
        font-size: 44px;
       
        width: 90%;
    
    } 
}
@media (min-width:280px) and (max-width: 540px) {
    .Software-Screen-5 {
   
   margin-top: 8%;
    }
 
    .Software-Screen-5 .spreading h2 {
        font-size: 24px;
        line-height: 34px;
        width: 90%;
        text-align: center; 
        margin-left: auto; 
        margin-right: auto;
        padding-top: 0%;
        /* padding-bottom: -40px;   */
        
    }
 
   
    .Software-Screen-5 .numbers {
        display: flex;
        flex-direction: column;
        gap: 5%;
        justify-content: center;
        text-align: center; 
        margin-left: auto; 
        margin-right: auto;
       
    }
    .block-1 h1, .block-2 h1, .block-3 h1 {
        font-size: 60px;
    }
 
  
} 
