/* @import '@radix-ui/colors/mauve.css';
  */
/* reset */
a {
  all: unset;
}
.day-8 {
  display: none;
}
#ScheduleAdd {
  /* color: rgb(6, 6, 6); */
  font-size: 14px;
 
  margin-right: 10px;
}
.Slot-Add {
  color: #000203;
  background-color: white;
}
.Slot-Add:hover {
  color: #ffffff;
  background-color: rgb(14, 14, 14);
}
.Top-2 {
  display: flex;
  gap: 70px;
}
/* #ScheduleDelete {
 
  font-size: 18px;
 
}
 
.Slot-Delete {
  background-color: rgb(231, 69, 69);
  color: white;
 
}
.Slot-Delete:hover {
  background-color: rgb(246, 239, 239);
  color: rgb(11, 5, 5);
 
} */
.Schudle {
background-image: linear-gradient(to top, #454556 0%, rgb(255, 255, 255) 100%);
width: 100%;
}
.Schudle-delete {
  background-color: #1a1b1a;
  color: white;
  padding: 7px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.Schudle-delete:hover {
  background-color: rgb(244, 52, 52);
  border: 0px;
}
#Schedule-delete {
  font-size: 16px;
}
.slots-section {
  line-height: 15px;
  height: 30px;
  margin-bottom: 40px;
  display: flex;
}
.Schedule-HoverCardContent {
  border-radius: 6px;
  padding: 10px;
  padding-left: 30px;
  padding-top: 20px;
  margin-top: 0px;
  width: 760px;
  background-color: #c2c9f0;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  margin-top: 75px;
  padding-bottom: 10px;
  border: 1px solid black;
  padding-right: 60px;
}
.Schedule-HoverCardContent [data-side='top'] {
  animation-name: slideDownAndFade;
}
.Schedule-HoverCardContent [data-side='right'] {
  animation-name: slideLeftAndFade;
}
.Schedule-HoverCardContent [data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.Schedule-HoverCardContent [data-side='left'] {
  animation-name: slideRightAndFade;
}
 
.Schedule-HoverCardArrow{
  fill: rgb(80, 80, 80);
 
}
.HoverCard-indication {
  width: 15px;
  height: 20px;
  fill: rgb(79, 79, 79);
  margin-left: -20px;
}
.ImageTrigger {
  cursor: auto;
  border-radius: 100%;
  display: inline-block;
}
.ImageTrigger:focus {
  box-shadow: 0 0 0 2px white;
}
 
 
 
@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
 
@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
 
@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
 
@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: translateX(2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
 
   .Schedule {
    /* background-color: rgb(204, 236, 226); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
  margin: 0 auto;
  }
  .Schedule-default {
      font-size: 35px;
      padding-left: 10px;
  }
  .Schedule-top {
      display: flex;
      gap: 100px;
      align-items: center;
      justify-content: center;
    width: 100%;
  margin: auto auto;
  }
  .Schedule-save {
      background-color: black;
      color: azure;
      width: 130px;
      padding: 5px;
      border-radius: 25px;
 
 
  }
  .time {
    display: flex;
  }
  .Schedule-time {
      width: 200px;
      border-radius: 20px;
      padding: 10px;
      align-items: center;
  }
  .Schedule-weekdays {
      display: flex;
      font-size: 20px;
      /* background-color: white; */
      padding-left:10%;
      padding-top: 20px;
  }
  .card-text {
    padding: 20px;
    line-height: 20px;
 
  }
  .Schedule-day-1 {
      /* background-color: #8698f0; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      margin-top: 10px;
      border-radius: 10px;
      font-size: 20px;
      width: 160px;
      height: 60px;
      border: 1px solid black;
   
     
     
  }
  .Schedule-day-1:active,
  .Schedule-day-1:hover,
  .Schedule-day-2:active,
  .Schedule-day-2:hover,
  .Schedule-day-3:active,
  .Schedule-day-3:hover,
  .Schedule-day-4:active,
  .Schedule-day-4:hover,
  .Schedule-day-5:active,
  .Schedule-day-5:hover,
  .Schedule-day-6:active,
  .Schedule-day-6:hover,
  .Schedule-day-7:active,
  .Schedule-day-7:hover {
      background-color: rgb(252, 252, 252);
      color: rgb(1, 1, 1);
      font-weight: 600;
  }
 
  .cat {
      width: auto;
      display: flex;
  }
  .day-1 {
      margin-left: 10px;
      width: 100px;
      margin-top: 15px;
  }
  .checkbox-1 {
      margin-left: 10px;
      margin-top: 25px;
  }
  .Schedule-day-2 {
      /* background-color: #9ba9ef;
      color: black; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      margin-top: 10px;
      border-radius: 10px;
      font-size: 20px;
      width: 160px;
      height: 60px;
      border: 1px solid black;
     
  }
  .Schedule-day-3 {
      /* background-color: #acb8f2;
      color: black; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      font-size: 20px;
      width: 160px;
      height: 60px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid black;
  }
  .Schedule-day-4 {
      /* background-color: #c2c9f0;
      color: black; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      font-size: 20px;
      width: 160px;
      height: 60px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid black;
  }
  .Schedule-day-5 {
      /* background-color: #d1d7f2;
      color: black; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      font-size: 20px;
      width: 160px;
      height: 60px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid black;
  }
  .Schedule-day-6 {
      /* background-color: #e0e3f1;
      color: black; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      font-size: 20px;
      width: 160px;
      height: 60px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid black;
  }
  .Schedule-day-7 {
      /* background-color: #edeff7;
      color: black; */
      background-color: #3a3838;
      color: rgb(255, 255, 255);
      font-size: 20px;
      width: 160px;
      height: 60px;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid black;
  }
 
  .time select {
      background-color: rgb(245, 247, 246);
      width: 150px;
      height: 40px;
      border-radius: 10px;
      text-align: center;
      font-size: 15px;
 
  }
  .Schedule-buttons  {
      margin-left: 40px;
      display: flex;
      gap: 25px;
      font-size: 15px;
      border-radius: 10px;
      height: 40px;
 
     
  }
  .Schedule-buttons button {
      padding: 5px;
      border-radius: 20px;
      width: 100px;
  }
 
 
 
  /* .bottom-nav .nav-pills .nav-link {
      color: white;
      background-color: white;
      color: black;
      border: 2px solid rgb(134, 114, 114) ;
      margin: 10px;
      border-radius: 50px;
  } */
  .bottom-nav {
    background-color: rgb(221, 219, 219);
    margin-top: 30px;
    width: 100%;
    color: black;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: none;
}
 
.schedule-filter{
 margin-right: 20px;
 width: 200px;
 height: 40px;
 border-radius: 40px;
 padding-left: 10px;
 font-size: 18px;
 margin-right: 50px;
 
}
  .bottom-nav .nav-link {
      color: black;
  }
 
  .nav-tabs .nav-link {
      border-bottom: none;
  }
 
  .bottom-nav .nav-buttons .nav-link {
      color: #0e0000;
      background-color: #a9adb1;
      font-size: 22px;
      font-weight: 500;
      width: 100px;
      text-align: center;
      border-radius: 50px;
      margin-left: 20px;
      border: 1px solid black;
  }
 
  .bottom-nav .nav-buttons .nav-link.active {
      color: #000203;
      background-color: #ffffff;
      border: 1px solid black;
  }
  .nav-buttons {
      margin-left: 30%;
  }
  .saved-data {
      padding-top: 20px;
      /* background-color: rgb(233, 232, 229); */
  }
  .Schedul-booked-cards {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: space-evenly;
  }
  .Schedule-Slots {
      display: flex;
  }
  .Schedul-booked-slots {
      display: flex;
  }
  .Schedule-Slots-cards {
   
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: space-evenly;
  }
  .Schudle-card {
    border: 1px solid black;
   padding-bottom: 20px;
   padding-top: 10px;
    text-align: left;
    width: 340px;
    background-color: #eff1eb;
    text-align: center;
      /* margin: 0 auto; */
      margin-top: 50px;
      border-radius: 30px;
       border: 2px black solid;
  }
  .Schudle-delete {
    background-color: #1a1b1a;
    color: white;
  }
 
  .Schudle-card p {
    display: flex;
    margin-top: 20px;
line-height: 10px;
text-align: left;
padding-left: 70px;
 
  }
  /* .scheduleData {
   
   
  } */
  .Schudle-card p h6 {
    width: 80px;
    padding-bottom: 0px;
    padding-top: 0px;
  line-height: 10px;
  }
 
  @media screen and (max-width: 884px) {
    .Schedule-HoverCardContent {
      width:550px;
     
    }
  .Schedule-buttons button {
      width: 90px;
  }
                                 
  .time select {
    width: 90px;
}
.time {
  padding-left: 0px;
}
.Schedule-buttons  {
  margin-left: 20px;
  gap: 15px;
}
  }
 
 
  @media screen and (max-width: 800px) {
    .Schedule-weekdays {
      padding-left:5%;
  }
  .Schedule-HoverCardContent {
    width:500px;
   
  }
.Schedule-buttons button {
    width: 80px;
}
  }
  @media (min-width: 391px) and (max-width: 430px) {
    .Schedule-top {
   
      flex-direction: column;
      gap: 10px;
     
  margin-bottom: 20px;
  padding-top: 20px;
  }
  .Schedule-default p {
    font-weight: bold;
  }
  .Top-2 {
    display: flex;
    gap: 10px;
  }
 
.Schedule-save {
    width: 100px;
    height: 50px;
}
.time {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
/* select {
  height: 30px;
} */
 
.Schedule-time {
    width: 140px;
    /* float: left; */
    /* margin-left: -50px; */
}
.Schedule-buttons {
  flex-direction: column;
  margin-left:0px;
  margin-top: 40px;
  gap: 30px;
  margin-bottom:10px;
 
}
.Schedule-HoverCardContent {
 
  width: 180px;
  margin-top: 179px;
  padding-bottom: 10px;
 padding-left: 0px;
  height: 550px;
overflow-x: hidden;
  overflow-y: scroll;
}
.Schedule-weekdays {
    display: flex;
    font-size: 20px;
    background-color: white;
    padding-left:5%;
    padding-top: 15px;
}
.Schedule-weekdays {
 
  font-size: 18px;
 
}
.card-text {
padding-top: 0px;
line-height: 20px;
}
.Schedule-day-1,
.Schedule-day-2,
.Schedule-day-4,
.Schedule-day-5,
.Schedule-day-6,
.Schedule-day-7 {
  font-size: 18px;
  width: 140px;
}
 
.Schedule-day-3 {
  font-size: 17px;
  width: 140px;
}
.slots-section {
  line-height: 5px;
  height:auto;
  margin-bottom: 40px;
  display: flex;
 
}
.HoverCard-indication {
  width: 15px;
  height: 20px;
  fill: rgb(79, 79, 79);
  margin-left: -90px;
}
.Schedule-buttons button {
  width: 100px;
  height:50px;
}
                             
.time select {
width: 120px;
height: 50px;
}
.dash {
  margin:0 auto;
  padding-left: 40px;
}
.Slot-Add {
  height: 50px;
}
/* .time-dropdown {
  height: 300px;
} */
  }
  @media screen and (max-width: 391px) {
 
    /* .Schedule-sidebar {
      display: none !important;
    } */
  .Schedule-top {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding-top: 20px;
  }
  .Schedule-default p {
    font-weight: bold;
  }
  .Top-2 {
    display: flex;
    gap: 10px;
  }
 
.Schedule-save {
    width: 100px;
    height: 50px;
}
.time {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}
 
.Schedule-time {
    width: 140px;
    /* float: left; */
    /* margin-left: -50px; */
}
 
 
 
    .Schedule-day-1,
    .Schedule-day-2,
    .Schedule-day-4,
    .Schedule-day-5,
    .Schedule-day-6,
    .Schedule-day-7,
    .Schedule-day-3 {
      font-size: 15px;
      width: 80px;
    }
   
    .Schedule-HoverCardContent {
    width: 200px;
    margin-top: 90px;
    padding-right: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
    padding-bottom: 190px;
    margin-top: 180px;
    border: 1px solid black;
    }
    .time {
     margin-left: 10px;
    }
    .cat {
      display: flex;
      /* flex-direction: column; */
    }
    /* .cat span {
      margin-top: 0px;
    }
    .checkbox-1 {
      margin-top: 5px;
    } */
    .HoverCard-indication {
      height: 13px;
      margin-left: -70px;
    }
 
 
    .Schedule-buttons {
      flex-direction: column;
      margin-left:0px;
      margin-top: 40px;
      gap: 30px;
      margin-bottom:10px;
   
    }
    .Schedule-buttons button {
      width: 100px;
      height:50px;
    }
                                 
    .time select {
    width: 100px;
    height: 50px;
    }
    .dash {
      margin:0 auto;
      padding-left: 40px;
    }
    .Slot-Add {
      height: 50px;
    }
    .day-8 {
      display: block;
      font-size: 18px;
      font-weight: 400;
      padding-left: 5px;
      padding-top: 15px;
    }
    .day-1 {
      display: none;
    }
    .card-text {
      padding-top: 0px;
      line-height: 20px;
      }
   
    .slots-section {
      line-height: 5px;
      height:auto;
      margin-bottom: 0px;
      display: flex;
     
    }
    .sch-head {
      padding-left: 20px;
    }
    .Schudle-card {
      width: 300px;
    }
  }

.def-icon-arrow{
  display: flex;
  gap: 15px;
  align-items: center;
}  