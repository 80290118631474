/*=============== GOOGLE FONTS ===============*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"); */
 
/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --white-color: hsl(0, 0%, 100%);
  --black-color: hsl(0, 0%, 0%);
 
  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --h1-font-size: 2rem;
  --normal-font-size: 1rem;
  --small-font-size: .813rem;
}
 
/*=============== BASE ===============*/
.login-page {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.login-page
body,
input,
button {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}
 
.login-page a {
  text-decoration: none;
}
 
.login-page img {
  display: block;
  max-width: 100%;
  height: auto;
}
 
/*=============== LOGIN ===============*/
.login-page {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
 
  .login-page .login__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
 
  .login-page .login__form {
    position: relative;
    margin-inline: 1.5rem;
    background-color: hsla(0, 0%, 100%, .01);
    border: 2px solid hsla(0, 0%, 100%, .7);
    padding: 2.5rem 1rem;
    color: var(--white-color);
    border-radius: 1rem;
    backdrop-filter: blur(16px);
  }
 
 
.login-page .login__title {
  text-align: center;
  font-size: var(--h1-font-size);
  margin-bottom: 1.25rem;
}
 
.login-page .login__inputs,
.login__box {
  display: grid;
}
 
.login-page .login__inputs {
  row-gap: 1.25rem;
  margin-bottom: 1rem;
 
}
 
.login-page .login__box {
  grid-template-columns: 1fr max-content;
  column-gap: .75rem;
  align-items: center;
  border: 2px solid hsla(0, 0%, 100%, .7);
  padding-inline: 1.25rem;
  border-radius: 4rem;
}
 
.login__input,
.login__button {
  border: none;
  outline: none;
 
}
 
.login__input {
  width: 100%;
  background-color: transparent;
  color: white;
  padding-block: 1rem;
  border: 0px;
}
 
.login__input::placeholder {
  color: rgb(223, 218, 218);
}
 
.login-page .login__box i {
  font-size: 1.25rem;
}
 
.login-page .login__check,
.login__check-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
padding: 3px;
}
 
.login-page .login__check {
  margin-bottom: 1rem;
  font-size: var(--small-font-size);
}
 
.login-page .login__check-box {
  column-gap: .5rem;
}
 
.login-page .login__check-input {
  width: 1rem;
  height: 1rem;
  accent-color: var(--white-color);
}
 
.login-page .login__forgot {
  color: var(--white-color);
}
 
.login-page .login__forgot:hover {
  text-decoration: underline;
}
 
.login-page .login__button {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: var(--white-color);
  border-radius: 4rem;
  color: var(--black-color);
  font-weight: 500;
  cursor: pointer;
}
 
.login-page .login__register {
  font-size: var(--small-font-size);
  text-align: center;
}
 
.login-page .login__register a {
  color: var(--white-color);
  font-weight: 500;
}
 
.login-page .login__register a:hover {
  text-decoration: underline;
}
 
/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (min-width: 576px) {
  .login {
    justify-content: center;
  }
  .login__form {
    width: 420px;
    padding-inline: 2.5rem;
  }
  .login__title {
    margin-bottom: 2rem;
  }
}