.graph-container {
    height: 500px;
    width: 800px;
    border: 2px solid #b8b4b4; 
    padding: 10px; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
    border-radius: 8px; 
    margin-top: 3%;
  }
 
  @media (max-width: 998px) {
    .graph-container {
      width: 90%;
      height: 400px;
      padding: 8px;
      margin-top: 5%;
    }
  }
  
  
  @media (max-width: 480px) {
    .graph-container {
      width: 100%;
      height: 200px;
      padding: 5px;
      margin-top: 7%;
    }
  }