/* *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carou-img {
  font-family: system-ui;
  height: auto;
  background-color: whitesmoke;
  overflow: hidden;
}

.carou-img #root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carou-img .swiper {
  max-width: 800px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carou-img .swiper-wrapper {
  width: 100%;
  height: 300px;
}

.carou-img .swiper-slide {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carou-img .slide-inner img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.carou-img .swiper-pagination-bullet-active {
  width: 15px;
  border-radius: 10px;
  transition: width 0.3s ease;
}

.carou-img .swiper {
  --swiper-pagination-color: rgba(56, 245, 255, 1);
  --swiper-pagination-bullet-inactive-color: white;
}

.carou-img .button-next,
.carou-img .button-prev {
  position: absolute;
  top: 50%;
  width: 50px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(56, 245, 255, 0.2);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}
:is(.carou-img .button-next,.carou-img  .button-prev):hover {
  background-color: rgba(56, 245, 255, 0.3);
}

.carou-img .button-next {
  right: 10px;
}
.carou-img .button-prev {
  left: 10px;
} */




.swui {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  /* height:400px; */
}

.swiper {
  width: 100%;
  height: 400px;
  z-index: 10;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* .searchcard{
  margin: 10px 0px;

} */