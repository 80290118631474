 
.MainCards {
  background-color: rgb(252, 242, 251);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
}
 
 
.kar {
  display: flex;
  height: 70px;
}
 
 
 
.AAA {
  background-color: black;
  border-radius: 9px;
  width: 60px;
  height: 40px;
  color: white;
  border: 1px solid black;
  font-size: 17px;
  font-weight: 600;
  /* margin-left: 500px; */
  margin-top: 10px;
}
 
.BBB {
  background-color: transparent;
  border-radius: 9px;
  width: 90px;
  height: 40px;
  font-weight: 700;
  border: 1px solid black;
  font-size: 17px;
  margin-top: 10px;
  margin-left: 90px;
}
 
.BBB:hover {
  background-color: rgb(236, 240, 247);
}
 
.CCC:hover {
  background-color: rgb(236, 240, 247);
}
 
.CCC {
  background-color: transparent;
  border-radius: 9px;
  font-weight: 700;
  width: 130px;
  height: 40px;
  border: 1px solid black;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 50px;
}
.card-body {
  background-color: rgb(240, 236, 236);
  height: 168px;
  border-radius: 20px;
}
.Cards {
  background-color: #6d6d6d;
  /* height: 46vh; */
  width: 27vw;
  margin-top: 60px;
  border-radius: 20px;
  box-shadow: rgb(11, 11, 11);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  color: #000;
  padding-bottom: 40px;
}
.MainCards h6 {
  color: #000;
  text-align: center;
  /* display: block; */
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
  width: 80%;
}
 
 
.DDD {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 25px;
  text-align: center;
}
 
 
.ss .EEE {
  font-size: 15px;
 
  font-weight: bold;
 
}
 
.nu {
  font-size: 13px;
  padding: 5px;
  text-decoration: line-through;
  text-decoration-thickness: 1.5px;
}
 
.ss .FFF {
  font-size: 15px;
  margin-top: 0%;
  color: gray;
  font-weight: 500;
 
}
 
.SubCard {
  display: flex;
  background-color: rgb(240, 236, 236);
  width: 27vw;
  margin-top: 0px;
  margin-left: 0px;
  align-items: center;
  justify-content: center;
  gap:70px;
  height: 80px;
  margin-bottom: 20px;
}
 
.GGG {
  background-color: rgb(11, 10, 10);
  border-radius: 20px;
  height: 40px;
  border: 1px solid black;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  transition: 1.0s;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  display: block;
  width: 140px;
 
}
 
 
.GGG:hover {
  background-color: rgb(51, 52, 53);
  color: rgb(250, 248, 248);
  text-decoration: none;
}
 
 
.GGG span.price {
  display: none; /* Hide the price initially */
}
.GGG:hover span {
  display: none; /* Hide the 'book now' text on hover */
}
.GGG:hover span.price {
  display: inline; /* Display the price on hover */
 
}
 
.vinnu {
  display: flex;
  margin-top: 20px;
 
}
 
.vinnu-1 {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-evenly;
 
}
 
.vinnu-2 {
  display: flex;
 
 
}
 
.Cards-1 {
  background-color: #6d6d6d;
  height: 46vh;
  width: 27vw;
  margin-top: 100px;
  margin-left: 40px;
  border-radius: 20px;
  box-shadow: rgb(11, 11, 11);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  color: #000;
}
 
 
.ss {
  /* border:1px solid black; */
  /* width: 130px; */
   margin-right: 20px;
  margin-top: 15px;
  line-height: 8px;
  /* float: left; */
}
 
.SubCard-1 {
  background-color: rgb(240, 236, 236);
  border-radius: 15px;
  width: 23vw;
  align-items: center;
  justify-content: center;
  height: 80px;
  margin-top: 40px;
  margin-left: 10px;
  display: flex;
  gap: 20px;
 
}
 
 
 
 
.con {
 
  font-size: 40px;
  margin-left: 10px;
 
}
 .cus-comments {
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
background-color: rgb(253, 253, 253);
color: rgb(0, 0, 0);
font-weight: 600;
transition: background-color 0.9s, color 0.9s; /* Smooth transition for background-color and color */
}
 
.cus-comments:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(253, 253, 253);
}
@media screen and (max-width: 1024px) {
  /* .Cards {
      height: 340px;
     
  } */
  /* .Cards-1{
      height: 340px;
  } */
 
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .Cards {
    background-color: #6d6d6d;
    /* height: 46vh; */
    width: 45vw;
    margin-top: 60px;
    border-radius: 20px;
    box-shadow: rgb(11, 11, 11);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    color: #000;
    padding-bottom: 40px;
  }
  .vinnu-1 {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
  .MainCards h6 {
      font-size: 16px;
      line-height: 1.5;
  }
  .MainCards p {
     
      font-size: 14px;
      line-height: 0.9;
  }
 
  .DDD {
      padding-top: 40px;
      font-size: 24px;
      text-align: center;
      /* height: 0px; */
 
  }
  .SubCard {
   
    width: 45vw;
 
}
 
}
@media screen and (min-width: 280px) and (max-width: 540px) {
 
   
  .vinnu-1 {
      display: flex;
      /* flex-direction: column; */
      margin: 0 auto;
   
  }
 
  .Cards {
      /* width: 280px; */
      width: 85vw;
      margin-top: 30px;
      /* height: 360px; */
      padding-bottom: 28px;
  }
 
  .Cards-1 {
    width: 85vw;
      margin-left: 0px;
      margin-top: 50px;
      height: 360px;
  }
 
  .DDD {
    padding-top: 15px;
    padding-bottom: 0px;
}
.AAA {
  margin-top: 10px;
}
 
.BBB {
  margin-left: 10px;
}
 
/* .CCC {
  margin-left: 10px;
} */
 
.ss {
  /* border:1px solid black; */
  /* width: 130px; */
   margin-right: 0px;
  margin-top: 0px;
  padding-top: 20px;
  line-height: 10px;
  /* float: left; */
}
.SubCard {
 
  width: 85vw;
 
  justify-content:space-evenly;
  gap: 10px;
 
}
}