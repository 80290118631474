.profile-container {
  width: 100%;
  display: flex;
  position: relative;
}

.profile-content {
  
  margin-left: 5%;
}

.profile-title {
  font-size: 30px;
  color: black;
  font-weight: 600; 
  margin: 10%;
}

.profile-image-container {
  display: flex;
  /* align-items: center; */
  gap: 50px;
}

.selected-image {
  width: 180px; 
  height: 180px; 
  overflow: hidden;
  border-radius: 50%; 
  border: 2px solid black; 
}

.image-upload {
  margin-left: 20px; 
}

.image-upload label {
  display: block;
  margin-bottom: 5px;
  color: black; 
  font-weight: 600; 
}

.image-upload input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-sizing: border-box;

}

.form-container {
  margin-top: 20px; 
  max-width: 300px; 
}

.form-container label {
  display: block;
  margin-bottom: 5px;
  color: black; 
  font-weight: 600; 
}

.form-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  /* border: 1px solid #0a0909; */
  border-radius: 4px;
  box-sizing: border-box;
}




.save-button {
  margin-top: 10px;
  margin-right: 10px; 
  padding: 10px 20px;
  background-color: #007bff; 
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.logout-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #dc3545; 
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #c82333; 
}


.profile-details-container {
  display: flex;
}

.profile-container {
  flex: 1;
}

.user-details-image {
 
  margin-right: 0; 
  margin-top: 15%;
  
}

.user-details-image img {
  width: 550px; 
  height: auto; 
}
.user-details-image p {
 color: #0a0909;
}

@media (min-width: 580px) {
  .user-details-image {
    display: block;
    text-align: center;  
    margin-right: 10%; 
    margin-top: 15%;
  }

  .profile-details-container {
    display: flex;
  }
  .form-container input {
    width: 100%;
    
  }
}


@media (max-width: 580px) {
  .user-details-image {
    display: none; 
  }

  .profile-details-container {
    display: block; 
  }

  .profile-content {
    width: 100%; 
    margin-left: 5%; 
    margin-right: 5%;
  }
  .form-container input {
    width: 100%;
    
  }
  .profile-content input[type="text"] {
    min-width: 250px;
    min-height: 35px;
  }
  .profile-image-container {
    flex-direction: column;
    align-items: center;
  }

  .selected-image {
    margin-right: 0; 
    margin-bottom: 10px; 
  }

  .image-upload {
    margin-top: 10px; 
  }
}
@media (min-width: 580px) and (max-width: 1024px) {
  .user-details-image {
    display: none; 
  }
}

