@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');
 
.main {
    display: flex;  
}
  
.main .left {
    border: 2px solid black;
    width: 750px;
    /* height: 150vh; */
    background-color: rgba(5, 56, 58, 0.947);
    color: #fff;
    padding-left: 120px;
}
.main .hello{
    color: white;
}
.expdescp{
    max-width: 300px;
    line-height: 1.5; /* Adjust the number to control the space between lines */
    word-wrap: break-word; /* Ensures long words will break to fit the width */
    margin-bottom: 10px;
}
.main .left .aaa {
    /* margin-left: 120px; */
    margin-top: 100px;
    font-size: 18px;
}
.main .hello-1 {
    display: none;
}
.main .left .aaa p {
    margin: 20px auto;
    line-height: 30px;
    font-size: 22px;
    color: rgb(225, 219, 219);
    padding-left: 20px;
    width: 300px;
    word-wrap: break-word;
overflow-wrap: break-word;
margin-left: 40px;
}
.main .left .bbb {
    /* margin-left: 120px; */
    margin-top: 20px;
    font-size: 20px;
    line-height: 25px;
}
.main .left .bbb p {
    margin-top: 20px;
    line-height: 30px;
    font-size: 22px;
    color: rgb(225, 219, 219);
    padding-left: 20px;
    width: 300px;
    word-wrap: break-word;
overflow-wrap: break-word;
margin-left: 40px;
 }
 
.main .left .bbb1 {
    /* margin-left: 120px; */
    margin-top: 20px;
    font-size: 20px;
    line-height: 10px;
   
   
}
.main .left .bbb1 p {
 
    margin: 20px auto;
    line-height: 30px;
    font-size: 22px;
    color: rgb(225, 219, 219);
    padding-left: 20px;
    width: 300px;
    word-wrap: break-word;
overflow-wrap: break-word;
margin-left: 40px;
}
.eee {
    margin: 20px auto;
    line-height: 30px;
    font-size: 22px;
    color: rgb(225, 219, 219);
    padding-left: 20px;
    width: 300px;
    word-wrap: break-word;
overflow-wrap: break-word;
margin-left: 40px;
}
.main .left .bbb2 {
    /* margin-left: 120px; */
    margin-top: 20px;
    font-size: 20px;
    line-height: 10px;
}
.main .left .bbb2 p {
    margin: 20px auto;
    line-height: 30px;
    font-size: 22px;
    color: rgb(225, 219, 219);
    padding-left: 20px;
    width: 300px;
    word-wrap: break-word;
overflow-wrap: break-word;
margin-left: 40px;
}
.right {
    /* border:2px solid black; */
    width: 320px;
    margin-left: -200px;
    background-color: #fff;
    margin-top: 100px;
    margin-bottom: 15%;
   height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
 
.men {
    width: 260px;
    height: 300px;
    margin-top: 30px;
    /* margin-left: -150px; */
    /* border: 2px solid black; */
    margin-left: 30px;
}
 
.ddd {
    font-size: 18px;
    margin-top: 20px;
    line-height: 25px;
   color: rgb(225, 219, 219);
   padding-left: 20px;
}
 
.ccc h1 {
    margin-left: 34px;
    font-size: 35px;
}
 
.mmm {
    margin-top: 20px;
}
 
.nnn {
    margin-left: 34px;
    font-size: 18px;
    font-weight: 500;
}
 
.ooo {
    margin-left: 34px;
    font-size: 22x;
    font-weight: 600;
    padding-top: 20px;
}
 
 
 
.eight {
    font-size: 17px;
 padding-left: 15px;
}
 
.phone {
    font-size: 15px;
    margin-left: 25px;
    margin-top: 2px;
}
 
.num {
    margin-top: -25px;
    /* margin-left: 15px; */
    margin-left: 50px;
}
 
.email {
    font-size: 20px;
    margin-left: 22px;
    margin-top: 10px;
}
 
.mail {
    margin-top: -26px;
    margin-left: 50px;
}
 
.loc {
    font-size: 20px;
    margin-left: 22px;
    margin-top: 10px;
}
 
.location {
    margin-left: 53px;
    margin-top: -26px;
}
 
 
 
.main .left .Resume-view-service {
    /* margin-right: 8%;
    float: right; */
    background-color: black;
    color: white;
    border: none;
    border-radius: 10px;
    /* height: 7vh; */
    /* width: 15vw; */
    padding: 15px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 7s, color 0.3s;
    font-weight: 600;
    margin-bottom: 10%;
    flex-wrap: nowrap;
}
 
.main .left .Resume-view-service:hover {
    background-color: white;
    color: black;
    transition: background-color 7s, color 0.3s;
}
 
 
.profile {
    font-size: 30px;
}
.exp{
    color: white;
}
.contact-2 {
    display: none;
}
@media only screen and (max-width: 900px) {
 
    .main .right {
        width: 320px;
        margin-left: -160px;
       height: 80vh;
    }
    .main .left {
        width: 590px;
        height: 100vh;
        padding-left: 30px;
    }
   
 
}
@media only screen and (max-width: 540px) {
    .main {
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
    }
   
    .main .left {
        border: 10px solid rgb(254, 253, 253);
        border-top: none; /* Remove top border */
        width: 100%;
        height: auto;
        background-color: rgba(5, 56, 58, 0.947);
        color: #fff;
        padding-bottom: 55.5px;
        /* padding-left: 10px; */
    }
    .main .right {
        width: auto;
        height: auto;
        width: auto;
        margin-left: 1px;
        background-color: #ffffff;
        height: auto;
        margin-top: 0px;
        margin-bottom: 0%;
    }
   
.men {
    width: 150px;
    height: 180px;
    margin-top: 15px;
   
    margin-left: 10px;
    padding-bottom: 10px;
}
.contact {
    display: none;
}
.photo-men {
    display: flex;
 
}
.men-name {
    padding-top: 45px;
    padding-left: 15px;
}
 
/*
.main .hello{
    color: white;
} */
 
.main .left .aaa {
    margin-left: 20px;
    margin-top: 40px;
    font-size: 18px;
   
}
.main .left .aaa p {
    margin: 20px auto;
    font-size: 20px;
    padding-left: 20px;
}
.main .left .bbb {
    margin-left: 20px;
    /* margin-top: 20px;
    font-size: 20px;
    line-height: 25px; */
}
.main .left .bbb p {
    margin-top: 20px;
    line-height: 30px;
    padding-left: 20px;
 }
 .ddd {
    font-size: 18px;
   padding-left: 20px;
    margin-top: 18px;
    line-height: 25px;
}
.main .left .bbb1 {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
    line-height: 10px;
}
.main .left .bbb1 p {
    margin-top: 20px;
    padding-left: 20px;
    color: rgb(225, 219, 219);
}
.main .left .bbb1 .eee {
    font-size: 20px;
    padding-left: 20px;
    margin-top: 10px;
    color: rgb(225, 219, 219);
}
.main .left .bbb2 {
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
    line-height: 10px;
 
}
.main .left .bbb2 p {
    padding-top: 10px;
    padding-left: 25px;
}
 
     .ccc {
        display: flex;
     }
     .ccc h1 {
        margin-left: 12px;
        font-size: 30px;
    }
     
    .mmm {
        margin-top: 20px;
        padding-left: 30px;
 
    }
       
    .mmm1 {
        padding-left: 30px;
    }
 
    .nnn {
        margin-left: 20px;
        font-size: 18px;
        font-weight: 600;
    }
     
    .ooo {
        margin-left: 34px;
        font-size: 18px;
        /* font-weight: 600; */
        margin-top: 240px;
    }
     
   
     
    .eight {
        font-size: 18px;
    }
  /* .ddd-9 p {
    color: bisque;
    font-size: 0px;
  } */
  .main .left .Resume-view-service {
    margin: 0 auto;
   
    border: none;
    border-radius: 10px;
   
    padding: 20px;
 
    align-items: center;
    text-align: center;
    margin-top: 5.5px;
margin-left: 20%;
 
}
 
     
.main .left .Resume-view-service:hover {
        background-color: white;
        color: black;
        font-weight: 600;
        font-size: 20px;
    }
    .contact-2 {
        display: block;
        margin-top: 0px;
        padding-top: 0px;
        font-size: 18px;
       
    }
    .contact-2 .ooo-2 {
        padding-left: 20px;
       
    }
    .contact-2 .eight-2 {
        padding-left: 50px;
        padding-top: 10px;
        color: rgb(225, 219, 219);
    }
    .call-point {
        display: flex;
        gap: 15px;
        color: rgb(225, 219, 219);
        /* padding-top: 10px; */
    }
   
}
 
@media only screen and (max-width: 320px) {
    .main .left .bbb p {
        margin-top: 20px;
        line-height: 25px;
        padding-left: 10px;
        color: rgb(225, 219, 219);
    }
    .men-name {
        padding-top: 15px;
        padding-left: 0px;
    }
    .ccc h1 {
        margin-left: 4px;
        font-size: 30px;
    }
    .nnn {
        margin-left: 30px;
        font-size: 18px;
        font-weight: 500;
    }
    .main .hello-1 {
        display: block;
        text-indent: 60px;
        width: 100%;
        color: rgb(225, 219, 219);
     
    }
   
    .main .left .aaa p {
        display: none;
 
    }
    .main .left {
     padding-left: 1px;
     
    }
 
    .main .left .bbb1 .eee {
        font-size: 18px;
        padding-left: 10px;
        margin-top: 20px;
    }
    .ddd {
        font-size: 16px;
       padding-left: 10px;
        margin-top: 18px;
        line-height: 25px;
    }
    .contact-2 {
        display: block;
        margin-top: 0px;
        padding-top: 0px;
        font-size: 18px;
       
    }
    .contact-2 .ooo-2 {
        padding-left: 20px;
       
    }
    .contact-2 .eight-2 {
        padding-left: 30px;
        padding-top: 10px;
        color: rgb(225, 219, 219);
    }
    .call-point {
        display: flex;
        gap: 15px;
        color: rgb(225, 219, 219);
       
    }
}
@media only screen and (max-width: 376px) {
    .ccc h1 {
        margin-left: 0px;
        padding-left: 20px;
        font-size: 20px;
    }
    .main .left {
        padding-left: 0px;
    }
    .main .left .aaa p {
      width: 250px;
    margin-left: 15px;
    }
    .main .left .bbb p {
        width: 250px;
        margin-left: 15px;
     }
     .main .left .bbb1 p {
        width: 250px;
    margin-left: 15px;
     }
     .main .left .eee {
        width: 250px;
        margin-left: 15px;
     }
     .main .left .bbb2 p {
        width: 250px;
        margin-left: 15px;
     }
 }