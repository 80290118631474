.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #e4e8eb, #2c3e50); /* Adjust gradient colors as needed */
}

.page {
  background: #fff;
  border: 1px solid #ddd; /* Border color */
  border-radius: 8px; /* Border radius */
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow */
  width: 80%;
  max-width: 400px; /* Adjust max-width based on your design */
}

.sub-heading {
  text-align: center;
  color: #0e0101; /* Sub-heading color */
  font-size: 25px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #0e0d0d; /* Input border color */
  border-radius: 4px; /* Input border radius */
}


#datepicker {
  width: 100%; /* Adjust width based on your design */

}

.time-slot-button {
  background-color: #dee4e7; /* Button background color */
  color: #131212; /* Button text color */
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border-radius:30px;
  width:50%;
  text-align: center;
  
}

.time-slot-button:hover{
  background-color: green;
}

.time-slot-button.selected {
  /* Your styling for selected time slots */
  background-color: #3069af;
  color: #fff;
}

p.selected-slot {
  /* Styling for the selected slot message */
  color: #6fa4e6;
  margin-top: 10px;
}

.button-type {
  background: #34db5e; /* Button background color */
  color: #fff; /* Button text color */
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}


.parent p {
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .page {
    width: 90%;
  }
}
