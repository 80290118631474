/* body {
    margin: 0;
    padding: 0;
    background-color: #fbfbfb;
    color: #9e9ea4;
    font-family: 'Montserrat', sans-serif;
  } */
  
  .icon {
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
  }
  .icon_header {
    vertical-align: middle;
    line-height: 1px;
    font-size: 26px;
  }
  .icon, .icon_header {
    margin-right: 5px;
  }
  
  .close_icon {
    color: red;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 260px 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'sidebar header header header'
      'sidebar main main main';
    height: 100vh;
  }
  
  /* Header  */
  .header {
    grid-area: header;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  }
  
  .menu-icon {
    display: none;
  }
  /* End Header  */
  
  /* Sidebar  */
  #sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #263043;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .sidebar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
  }
  
  .sidebar-title > span {
    display: none;
  }
  
  .sidebar-brand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .sidebar-list {
    padding: 0;
    list-style-type: none;
  }
  
  .sidebar-list-item {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }
  
  .sidebar-list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  
  .sidebar-list-item > a {
    text-decoration: none;
    color: #9e9ea4;
  }
  
  .sidebar-responsive {
    display: inline !important;
    position: absolute;
    /*
      we want the z-index of the sidebar higher so that
      the charts are not showing over the sidebar 
      on small screens
    */
    z-index: 12 !important;
  }
  
  /* End Sidebar  */
  
  
  /* Main  */  
  .dashbord-main .main-container {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .main-title {
    display: flex;
    justify-content: space-between;
  }
  
  .main-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
  }
  
  .card:first-child {
    background-color: #2962ff;
  }
  
  .card:nth-child(2) {
    background-color: #ff6d00;
  }
  
  .card:nth-child(3) {
    background-color: #2e7d32;
  }
  
  .card:nth-child(4) {
    background-color: #d50000;
  }
  
  .card-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-inner > .card_icon {
    font-size: 25px;
  }
  
  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }
  
  /* End Main  */
  
  
  /* Medium <= 992px */
    
  @media screen and (max-width: 992px) {
    .grid-container {
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr 3fr;
      grid-template-areas:
        'header'
        'main';
    }
  
    #sidebar {
      display: none;
    }
  
    .menu-icon {
      display: inline;
    }
  
    .sidebar-title > span {
      display: inline;
    }
  }
  /* Small <= 768px */
    
  @media screen and (max-width: 768px) {
    .main-cards {
      grid-template-columns: 1fr;
      gap: 10px;
      margin-bottom: 0;
    }
  
    .charts {
      grid-template-columns: 1fr;
      margin-top: 30px;
    }
  }
  
  /* Extra Small <= 576px */
  
  @media screen and (max-width: 576px) {
    .hedaer-left {
      display: none;
    }
  }
  




/* dashboard */


.dashbord-main {
  width: 100%;
  overflow-x: hidden;
}

.dashbord-section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2196f3;
  height: 60px;
  color: white;
  /* position: fixed; */
}
.dashbord-section-1-top {
  display: flex;
  justify-content: space-between;
}
.dashbord-section-1 h2 { 
  padding-top: 10px;
  padding-left: 50px;
}
  .search-button {
    display: flex;
    position: relative;
    padding: 20px 30px;
  }
   
  .dashbord-section-1 input[type="text"] {
    padding-left: 20px; /* Adjust as necessary */
    min-width: 350px;
    min-height: 35px;
    border-radius: 10px;
    border: 1px solid black;
  }
  .search-icon {
    position: absolute;
    font-size: 1rem;
    right:  50px; /* Adjust as necessary */
    margin-top: -8px;
    color: #000;
  
    transform: translateY(50%);
    pointer-events: none; /* Ensure that the icon does not interfere with input focus */
  }
  
  .notifications {
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-right: 20px;
  }
  
  .notifications svg {
    margin: 0 10px; /* Adjust the gap between icons */
    color: white; /* Set the icon color */
  }
  .dashbord-section-2 {
    width: 100%;
    height: auto;
    background-color: rgb(226, 241, 241);
    padding-top: 0.8%;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 50px;
  }
  .section-2-proced {
    display: flex;
  }
  .section-2-top {
    display: flex;
    justify-content: space-between;
    color: #000;
  }
  .section-2-left {
    padding-left: 2%;
  }
  .section-2-proced h4 {
    font-size: 20px;
  }
  .section-2-proced h6 {
    color: #646161;
    padding-top: 6px;
    font-size: small;
  }
  .section-2-right {
    padding-right: 2%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
  .section-2-right h6 {
    color: #646161;
    padding-top: 0px;
    font-size: 16px;
  }
  .profile-photo {
    width: 30px;
    height: 30px;
    border-radius: 90px;
    margin-right: 12px;
  }
  .dashbord-section-2-graphs {
    background-color: white;
    margin-top: 20px;
    padding-top: 50px;
    gap:70px;
    display: flex;
    justify-content: center;
  }
  .graphs-1 {
    background-color: #eeeeee;
    border-left:5px solid #4b4b4b;
    border-right:5px solid #4b4b4b;
    border-bottom:5px solid #4b4b4b;
    border-radius: 15px;
   }
 .graphs-2 {
  background-color: #eeeeee;
  border-radius: 15px;
  border-left:5px solid #4b4b4b;
  border-right:5px solid #4b4b4b;
  border-bottom:5px solid #4b4b4b;
 }
 .graph-header {
  background-color: #4b4b4b;
  min-height: 50px;
  border-radius: 9px 9px 0px 0px;
  width: 100%;
 }

 .graph-datepicker {
  float: right;
  padding-top: 10px;
  padding-right: 20px;
  display: flex;
  gap: 10px;


 }

 .graph-datepicker select,
.graph-datepicker button {
  margin: 0; /* Reset default margins */
  border-radius: 8px;
  background-color: #ffffff;
  padding: 5px;
  
}
.Name{
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  
  .dashbord-section-1 {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .dashbord-section-1 h2 {
    padding-left: 0;
    text-align: center;
  }

  .search-button {
    padding: 10px;
  }

  .dashbord-section-1 input[type="text"] {
    min-width: auto;
    width: 100%;
    margin-bottom: 10px;
  }
  .graphs-1{
    width: 100%;
   }
   .graph-header {
    width: 700px;
   }
   .graphs-1 {
    width: 700px;
    overflow-y: auto;
   }
}


@media (max-width: 768px) {
  /* Add your responsive styles here */
  .dashbord-section-2 {
    width: 100%;
    
    overflow: auto;
  }
  .dashbord-section-2-graphs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    
    
  }
  .graph-header {
    width: 700px;
   }
   .graphs-2 {
    width: 700px;
    overflow-y: auto;
   }
 
  /* Media query for mobile view */
 
    
  

  
  
}

@media (max-width: 998px) {
  
  .dashbord-section-2 {
    width: 100%;
    
    overflow: auto;
  }
  .dashbord-section-2-graphs {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    
  }
  
 
  
}

@media (max-width: 576px) {
  .dashbord-section-1 {
    height: auto;
    flex-direction: column;
    align-items: center;
  }

  .dashbord-section-1 h2 {
    padding-left: 0;
    text-align: center;
  }

  .search-button {
    padding: 10px;
  }

  .dashbord-section-1 input[type="text"] {
    min-width: auto;
    width: 100%;
    margin-bottom: 10px;
  }

  .graphs-1 {
    width: 100%;
    max-height: 900px; /* Adjust as needed */
    overflow-y: auto;
    padding: 10px; /* Add padding for better appearance */
  }

  .graph-header {
    width: 100%; /* Adjust width as needed */
  }
}


@media (max-width: 768px) {
  .dashbord-section-2 {
    min-width: auto;
    width: 100%;
    margin-bottom: 10px;
  }
 
  .dashbord-section-2-graphs {
    display: flex;
    flex-direction: column;
    width: auto;
    max-height: 900px;
   
    padding: 10px;
  }
  .graphs-2 {
    width: 100%;
    max-height: 900px;
    overflow-y: auto;
    padding: 10px;
  }
  .graph-header {
    width: 100%;
  }
  .filter-section{
    display: flex;
    flex-direction: column;
 
  }
}
.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
 
}
.filter-section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #000;
 
}
 
.custom-dropdown-toggle {
  background-color: #343a40;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}
 
.custom-dropdown-toggle:hover,
.custom-dropdown-toggle:focus,
.custom-dropdown-toggle:active {
  background-color: #495057;
  color: white;
}
 
.custom-dropdown-menu {
  background-color: #343a40;
  border: none;
  margin-top: 5px;
  border-radius: 4px;
  overflow: hidden;
}
 
.custom-dropdown-item {
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}
 
.custom-dropdown-item:hover,
.custom-dropdown-item:focus {
  background-color: #495057;
}


