
.app-container {
    position: relative;
  }
  
  .toggle-chatbot {
    /* padding: 10px 20px; */
    border: none;
    border-radius: 20px;
    background: transparent;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .toggle-chatbot:hover {
    background: transparent;
  }
  
  .support-chat-container {
    width: 100%;
    max-width: 400px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    
    position: fixed;
    bottom: -100%;
    right: 20px;
    transition: bottom 0.5s ease;
    max-height: 500px;
  }
  
  .support-chat-container.visible {
    bottom: 20px;
  }
  
  .support-chat-container.hidden {
    bottom: -100%;
  }
  .chatimg{
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .support-chat {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 500px;
  }
  
  .chat-messages {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    border-bottom: 1px solid #eee;
  }
  
  .bot-message,
  .user-message {
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 20px;
    max-width: 80%;
    word-wrap: break-word;
  }
  
  .bot-message {
    background: #e7f3fe;
    align-self: flex-start;
  }
  
  .user-message {
    background: #dff7e1;
    align-self: flex-end;
    margin-left: auto;
  }
  
  .suggestion-buttons {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    justify-content: center;
    background: #f9f9f9;
    border-top: 1px solid #eee;
  }
  
  .suggestion-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .suggestion-buttons button:hover {
    background: #0056b3;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    background: #fff;
  }
  
  .chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-right: 10px;
  }
  
  .chat-input button {
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background: transparent;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .chat-input button:hover {
    background: #0056b3;
  }
  
  .closebtn {
    text-align: right;
    padding-right: 10px;
    color: red;
    font-weight: bold;
    cursor: pointer;
  }
  
  @media (max-width: 600px) {
    .support-chat-container {
      width: 85%;
      bottom: 10px;
      right: 2%;
      /* height: 80%; */
      overflow-x: hidden;
    }
  
    .toggle-chatbot {
      bottom: 10px;
      right: 10px;
      padding: 8px 16px;
    }
  
    .chat-input input {
      padding: 8px;
      margin-right: 5px;
    }
  
    .chat-input button {
      padding: 8px 10px;
    }
  
    .bot-message, .user-message {
      padding: 8px 12px;
      margin: 8px 0;
    }
  
    .suggestion-buttons {
      padding: 8px;
    }
  
    .suggestion-buttons button {
      padding: 8px 10px;
    }
  }
  .typing {
    font-style: italic;
    color: grey;
  }
  @media (max-width: 400px) {
    .support-chat-container {
      width: 80%;
      bottom: 10px;
      right: 2%;
      height: 100%;
      overflow-x: hidden;
    }  
    
    .support-chat-container {
      width: 80%;
      /* max-height: 450px; */
    }
  }  