/* *{
    margin: 0px;
    padding: 0px;
    font-family: poppins;
    box-sizing: border-box;
  } */
  a{
    text-decoration: none;
  }
 
  .profile5-img{
    width:50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .profile5-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .profile5{
    display: flex;
    align-items: center;
  }
  .name-user {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 60px); /* Adjust the max-width as needed */
}
 
  .name-user strong{
    color: #3d3d3d;
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
  .name-user span{
    color: #979797;
    font-size: 0.8rem;
  }
  .reviews {
    color: #f9d71c;
    text-align: right;
}
.reply-input{
  padding: 20px;
}
  .box-top{
   
    margin-bottom: 20px;
  }
  .client-comment p{
    font-size: 0.9rem;
    color: #4b4b4b;
  }
 
  #testimonials {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%; /* Adjust the width as needed */
}
 
.testimonial-heading {
    letter-spacing: 1px;
    margin: 30px 0px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 
.testimonial-heading h1 {
    font-size: 2.2rem;
    font-weight: 500;
    background-color: #202020;
    color: #ffffff;
    padding: 10px 20px;
}
 
.testimonial-heading span {
    font-size: 1.3rem;
    color: #252525;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
 
.testimonial-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;
    border-radius: 100px;
}
 
.testimonial-box {
    width: 550px;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
    margin: 15px;
    border-radius: 10px;
}
.testimonial-box1 {
  width: 550px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 20px;
  margin: 15px;
  border-radius: 10px;
}
 
.reply-input .MuiFormLabel-root {
  padding-top: 8px;
  padding-left: 20px;
}
.reply-input-text{
  box-shadow: 0 0 2px black;
}
 
 
  @media(max-width:790px){
    .testimonial-box{
        width:100%;
        padding: 30px;
        margin-left: 11%;
    margin-right: 5%;
    }
    .testimonial-box1 {
      width:100%;
     
     
      justify-content: center;
      align-items: stretch;
      margin-right: 10%;
    }
   
   
    .testimonial-heading h1{
        font-size: 1.4rem;
    }
    .testimonial-heading
    {
   
        margin-left: 30px;
    }
   
   
  .stars{
    color: rgb(249,215,28);
    font-size: 18px;
    text-align: right;
  }
  }
  @media(max-width:340px){
    .box-top{
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .reviews{
        margin-top: 10px;
    }
  }
 
  /* ::selection{
    color: #ffffff;
    background-color: #252525;
  } */
 
  .empty-star{
    font-size: 25px;
  }
 
 
  .stars{
    color: rgb(249,215,28);
    font-size: 18px;
    text-align: right;
  }
 
  .Helpful{
    margin-top: 7px;
  }
 
  .likeicon{
    margin-top: 5px;
    font-size: 16px;
  }
 
  .adminlogo{
    width:40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
 
  .adminlogo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .adminreplysection{
    margin-left: 25px;
  }
 
   @media (300px <= width <= 600px){
    #testimonials{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width:100vw;
    }
    .stars{
      color: rgb(249,215,28);
      font-size: 18px;
      text-align: right;
    }
  }
 
  .avrrating{
    border-width: 1px;
    border-style: solid;
    height: 80px;
    padding: 10px;
    border-radius: 5px;
   
  }
 
 
  .ratingheading{
    margin-left: 45px;
    margin-bottom: 20px;
  }
 
 
 
 
@media (max-width: 600px) {
  #testimonial-box {
   
   
    padding: 30px;
    margin-left: 10%;
 
}
.testimonial-box-container{
 
  margin-left: 20px;
   width:100%;
 }
 
  .stars {
      text-align: right;
  }
  .Avg{
    font-size: 10px;
  }
}
 
 
@media (min-width: 601px) and (max-width: 1024px) {
  #testimonials {
   
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .testimonial-box-container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ratingheading{
    margin-left: 20%;
  }
  .avrrating{
    margin-left: 20%;
    height: 80px;
   
  }
 
}
 
 