.multi-step-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.form {
  width: 500px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
 
.form-step {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.screenshot {
  width: 75vw;
}

.multi-step-form-container button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 20px;
}

button:hover {
  background-color: #2980b9;
}

.form-step label {
  margin-top: 10px;
}

.form-step input,
.form-step textarea {
  margin-left: 10px;
}

/* input[type="text"]{
    margin: 10px 0px;
    box-shadow: 0 0 10px black;
    border: none;
    padding: 5px;
    margin-left: 5px;
    border-radius: 20px;
  } */

  .form-step input[type="text"],.form-step input[type="tel"],.form-step input[type="date"],.form-step input[type="number"],
  .form-step textarea {
  /* width: 400px; */
  background: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  margin-top: 15px;

  box-shadow: 0 0 3px black;
  border-inline: 2px solid transparent;
}

.form-step img {
  width: 100%; /* Make the image take 100% width of its container */
  max-width: 100%; /* Ensure the image doesn't exceed its natural size */
  height: auto; /* Maintain the image's aspect ratio */
}

/* Add responsive styles for smaller screens */
@media screen and (max-width: 600px) {
  .form {
    width: 100%;
  }

  .form-step input[type="text"],.form-step input[type="tel"],.form-step input[type="date"],.form-step input[type="number"],
  .form-step textarea {
  width: 100%;
}
}


