.pageContainer {
  height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px 20px;
}
 
.page-title {
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 20px;
  }
 
  .tabs-container .nav-tabs {
    border-bottom: none;
  }
 
  .tabs-container .nav-tabs .nav-link {
    border: 1px solid #171717;
    cursor: pointer;
    border-radius: 22px;
    display: inline-flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    height: 40px;
    padding: 0 14px;
    color: #312f2f;
    margin-right: 20px;
    /* background-color: #000; */
  }
 
  .tabs-container .nav-tabs .nav-link.active {
    border: 2px solid #171717;
    /* background-color: #141212; */
    font-weight: 600;
    color: #000;
  }
 
  .tabs-container .tab-content {
    border-top: 1px solid #e3dddd;
    margin-top: 20px;
  }
 
  .flexJustify {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 
  .btnRight {
    margin-right: 20px;
  }
 
  .serviceDivContainer img {
    width: 230px;
  }
 
  .flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }
 
  .sec-title {
    font-weight: 700;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.88);
  }
 
  .iconGap {
    margin-right: 8px;
  }
 
  .iconSmall {
    font-size: 11px;
  }
 
  .serviceDivContainer .btn {
    font-weight: 700;
  }
 
  .advance-checklist-btn {
    background: #6a57e3 !important;
    border: none !important;
    padding: 10px !important;
    color: #fff;
    padding: 11px 35px !important;
    border-radius: 30px;
    font-weight: 700;
  }
 
  .advance-checklist-btn:hover {
    color: #fff;
  }
 
  .advance-checklist-btn .badge {
    position: relative;
    top: -23px;
    left: 10px;
    border-radius: 10px;
    border: 1px solid #fff;
  }
 
  .btnRight {
    text-align: right;
  }
 
  .pageContainer {
    /* padding: 30px 60px; */
    width: 100%;
  }
 
  .checklist-btn {
    position: fixed;
    bottom: 40px;
    right: 60px;
  }
 
  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
    padding-bottom: 3px;
  }
 
  .hrLine {
    margin: 10px 0px
  }
 
  .btnxlWidth {
    min-width: 200px;
    text-align: center;
    font-weight: 600;
  }
 
  .dFlex {
    display: flex;
    align-items: center;
  }
 
  .paymentContainerDiv {
    padding: 20px 40px;
  }
 
  .paymentContainerDiv .dashCardBox img {
    width: 30px;
    margin-bottom: 8px;
  }
 
  .paymentContainerDiv .dashCardBox {
    padding: 20px 25px;
    border-radius: 10px;
    font-weight: 600;
    margin-bottom: 30px;
  }
 
  .paymentContainerDiv .dashCardBox p {
    margin-bottom: 0px;
  }
 
  .paymentContainerDiv .dashCardBox svg {
    color: #000;
  }
 
  .paymentContainerDiv .dashCardBox h4 {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 0px;
  }
 
  .color1 {
    background: #ebf3fd;
  }
 
  .color2 {
    background: #f4dedc;
  }
 
  .color3 {
 
    background: #dbd1fc;
  }
 
  .p-30 {
    padding: 30px 50px;
  }
 
 
  .tabsStyle2 .nav-tabs .nav-link.active {
    border: 2px solid #171717;
    background-color: #000;
    font-weight: 600;
    color: #fff;
  }
 
  .tabsStyle2 .nav-tabs .nav-link {
    border: 0px solid #171717;
    cursor: pointer;
    border-radius: 22px;
    display: inline-flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    height: 40px;
    padding: 0 20px;
    color: #312f2f;
    margin-right: 20px;
    background: #ece8e6;
    font-weight: 600;
  }
 
  .noDataTbl svg {
 
    display: inline-block;
    font-size: 60px !important;
    color: #e1d6d6;
  }
 
  .noDataTbl td {
    padding: 60px 0px;
    color: #e1d6d6
  }
 
  .transacionDetailsTabs .noDataTbl svg {
    margin-right: 10px;
    font-size: 20px;
  }
 
  .transacionDetailsTabs .nav-item svg {
    font-size: 20px;
    margin-right: 10px;
  }
 
  .transacionDetailsTabs .nav-tabs .nav-link.active {
    border: 2px solid #171717;
    background-color: transparent;
    font-weight: 700;
    color: #000;
    border: none;
 
    border-bottom: 2px solid #171717;
    border-radius: 0px;
  }
 
  .transacionDetailsTabs .nav-tabs .nav-link {
    border: 0px solid #171717;
    cursor: pointer;
    border-radius: 0px;
    display: inline-flex;
    align-items: center;
    transition: all 0.4s ease-in-out;
    height: 40px;
    padding: 10px;
    color: #312f2f;
    margin-right: 20px;
    background: transparent;
    font-weight: 600;
  }
 
  .table th {
 
    background: #f7f3f3;
    /* padding-bottom: 10px !important;
    padding-top: 0px !important; */
    vertical-align: middle;
    font-size: 15px;
  }
 
  .table {
    border: 1px solid #f7eeee;
  }
 
  .table td {
    font-size: 15px;
  }
 
  .checlistBottom {
 
    bottom: 10px;
  }
 
  .btnLeftBottom {
    position: relative;
    /* left: 16px; */
    bottom: 100px;
 
  }
 
  .btnLeftBottom button {
    color: #000;
    font-weight: 600;
    /* position: relative;
    left: 16px; */
 
    /* margin-left: 30px; */
  }
 
  .btnLeftBottom button svg {
    margin-left: 10px;
  }
 
  .service-cont{
    display: flex;
    flex-wrap: wrap;
 
 
  }
  .dashCardBox color1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}
 
.dFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
 
  @media screen and (max-width: 595px) {
   
    .page-title {
        font-size: 25px;
        margin-bottom: 15px;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
       
    }
 
   
    .tabs-container .nav-tabs {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
  }
 
  .tabs-container .nav-tabs .nav-link {
      padding: 8px;
      margin-right: 0;
      border-radius: 10px;
      margin-bottom: 10px;
  }
 
 
  .paymentContainerDiv {
      padding: 10px 20px;
  }
 
 
  .service-cont {
      flex-direction: column;
      align-items: center;
  }
  .pageHeader {
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  }
   
}
@media (min-width: 400px) and (max-width: 540px) {
  .btnDiv .btnRight {
   
   
   
    margin-left: 20px;
  }
}
 
 